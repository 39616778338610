import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays, getSelectionKey, getShortDate, getStartOfDay, getQuarterHour } from '@/js/dn-helper.js';
import { RequestItem } from '@/model/dn-request-item.js';
import { Task } from '@/model/dn-task.js';
import { TASK_SYSTEM_KIND } from '@/model/dn-tasktype.js';

/**
* @typedef {{employeeId:number; st:Date; fi:Date; available:boolean; msg:string|null}} AvailabilityRequestPostDto
*/

/**
* @typedef {AvailabilityRequestPostDto & {id:number; available:boolean; created:Date; updated:null|Date; approved:boolean|null; replyMsg:string|null, deleted:boolean|null}} AvailabilityRequestDto
*/

/**
* @typedef {{approved?:boolean|null; replyMsg?:string|null}} AvailabilityRequestPatchDto
*/

/**
 * @param {number} st
 * @param {number} fi
 * @param {number} id employee or callcenter id
 * @param {boolean} idIsEmployee
 * @param {number} [ts]
 * @returns {Promise<AvailabilityRequestDto[]>}
 */
async function getAvailabilityRequests(st, fi, id, idIsEmployee, ts = undefined) {
  let filter = ''
  if (fi) {
    filter += '&fi=' + fi;
  }
  if (ts) {
    filter += '&ts=' + ts;
  }

  return await fetchAndCheckJson(`availability-request?id=${id}&idIsEmployee=${idIsEmployee}&st=${st}${filter}`, 'GET');
}

/**
 * @param {AvailabilityRequestPostDto} data
 */
export async function postAvailabilityRequest(data) {
  /** @type {{id:number; created:string}} */
  const result = await fetchAndCheckJson(`availability-request`, 'POST', data);
  return result;
}

/**
 * @param {number} id
 * @param {AvailabilityRequestPatchDto} data
 */
async function patchAvailabilityRequest(id, data) {
  /** @type {{id:number; updated:string}} */
  const result = await fetchAndCheckJson(`availability-request/${id}`, 'PATCH', data);
  return result;
}

/**
 * @param {number} id
 */
async function deleteAvailabilityRequest(id) {
  /** @type {{id:number; updated:string}} */
  const result = await fetchAndCheckJson(`availability-request/${id}`, 'DELETE');
  return result;
}

export class AvailabilityRequest extends RequestItem {
  /**
   * @param {AvailabilityRequestDto} dto
   */
  constructor(dto) {
    super(dto.id, isApprovedOrAutoApproved(dto), new Date(dto.created));
    /** @readonly @type {number} */
    this.employeeId = dto.employeeId;
    /** @readonly @type {Date} */
    this.st = new Date(dto.st);
    /** @readonly @type {Date} */
    this.fi = new Date(dto.fi);
    /** @readonly @type {boolean} */
    this.available = dto.available;
    /** @type {string} */
    this.msg = dto.msg;
    /** @type {string} */
    this.replyMsg = dto.replyMsg;
    /** @type {Date|null} */
    this.updated = dto.updated ? new Date(dto.updated) : null;
  }

  get employeesToDisplay() {
    return [{ starts: [this.st] }];
  }

  get iconName() {
    return this.available ? 'arrows_expand_vertical' : 'arrows_collapse_vertical';
  }

  get key() {
    return -this.id;
  }

  /** @returns {string[]} */
  get selectionKeys() {
    return [getSelectionKey(this.st, this.employeeId)];
  }

  /** @returns {Date[]} */
  affectedScheduleInterval() {
    if (!this.available) {
      const st = getStartOfDay(this.st);
      const fi = getStartOfDay(this.fi);
      return [st, fi < this.fi ? addDays(fi, 1) : fi];
    }
    return [];
  }

  /**
   * @param {{ id: number; }} employee
   */
  canDelete(employee = null) {
    if (employee) {
      return this.approved === null;
    }
    return this.approved !== null;
  }

  /**
   * @param {{ id: number; }} employee
   */
  canReply(employee = null) {
    return !employee;
  }

  async deleteRequest() {
    await deleteAvailabilityRequest(this.id);
  }

  /**
 * @param {number} index
 */
  getEmployeeIdByIndex(index) { // eslint-disable-line no-unused-vars
    return this.employeeId;
  }

  getMsgToDisplay() {
    if (this.msg) {
      return this.msg;
    }

    return this.available ? 'available' : 'unavailable';
  }

  getReplyMsg() {
    return this.replyMsg;
  }

  /**
   * @param {{ id: number; }} employee
   * @returns {{id:number;msg:string}}
   */
  getReplyObj(employee) {
    return new AvailabilityRequestReply(this, employee !== undefined);
  }

  /**
   * @param {any} srEmp
   * @param {string} language
   * @returns {string[]}
  */
  getTasksInfo(srEmp, language) { // eslint-disable-line no-unused-vars
    const dtFormat = new Intl.DateTimeFormat(language, { hour: 'numeric', minute: '2-digit' });
    return [`${dtFormat.format(this.st)} - ${dtFormat.format(this.fi)}`];
  }

  /**
   * @param {string} key
   */
  hasSelectionKey(key) {
    return getSelectionKey(this.st, this.employeeId) === key;
  }

  /**
   * @param {{showAvailability:boolean;showUnavailability:boolean;showFuture:boolean;showBasedOnSelection:boolean;showApproved:boolean;showRejected:boolean;showHistoric:boolean;tagId:number}} filter
   * @param {Date} future
   * @param {number} selectedEmpId
   * @param {import("@/model/dn-employee.js").Employee[]} empList
   * @param {Date} now
   */
  isFilterOk(filter, future, selectedEmpId, empList, now) {

    let okByOthers = true
    let okByDate = true
    let okByTag= true

    if (this.approved === true) {
      if (!filter.showApproved) { okByOthers= false; }
    } else if (this.approved === false) {
      if (!filter.showRejected) { okByOthers= false; }
    }

    if (this.available) {
      if (!filter.showAvailability) { okByOthers= false; }
    } else {
      if (!filter.showUnavailability) { okByOthers= false; }
    }

    if (filter.showBasedOnSelection) {
      if (this.employeeId !== selectedEmpId) { okByOthers= false; }
    }

    if (!filter.showFuture) {
      if (this.st > future) { okByOthers= false; }
    }


    if(filter.tagId>0){
      okByTag=false
      let tagList= [filter.tagId]
      let emp= empList.find(x => x.id==this.employeeId)
      if (emp&&emp.hasAnyTag(tagList)) {
        okByTag=true
      }
     };
    
    if (addDays( this.st,this.days) < now && !filter.showHistoric) { okByDate = false }

    return okByOthers&&okByTag&&okByDate
  }

  /**
   * @private
   * @param {import("@/model/dn-task.js").ScheduleTasks} scheduleTasks
   * @param {import("@/model/dn-tasktype.js").TaskTypes} taskTypes
   */
  syncWithSchedule(scheduleTasks, taskTypes) {
    const timeOffId = taskTypes.bySystemKind.get(TASK_SYSTEM_KIND.free).id;
    /** @type {Task[]} */
    const timeOffTasks = [];
    let hasOverlappingShift = false;
    for (const task of scheduleTasks.list) {
      if (task.empid === this.employeeId && !task.isToDelete && task.overlaps(this.st, this.fi)) {
        if (task.tasktypeId === timeOffId) {
          timeOffTasks.push(task);
        } else {
          hasOverlappingShift = true;
        }
      }
    }

    if (hasOverlappingShift) {
      let modifed = false;
      if (this.approved) {
        if (timeOffTasks.length > 0) {
          const task = timeOffTasks[0];
          if (task.st > this.st) {
            task.st = this.st;
            modifed = true;
          }
          if (task.fi < this.fi) {
            task.fi = this.fi;
            modifed = true;
          }
        } else {
          const task = new Task({ empid: this.employeeId, st: this.st, fi: this.fi, tasktypeId: timeOffId });
          scheduleTasks.add(task);
          modifed = true;
        }
      } else if (timeOffTasks.length > 0) {
        for (const task of timeOffTasks) {
          if (task.st < this.st) {
            if (task.fi > this.fi) {
              const taskAfter = new Task({ empid: this.employeeId, st: this.fi, fi: task.fi, tasktypeId: timeOffId });
              scheduleTasks.add(taskAfter);
            }
            task.fi = this.st;
          } else if (task.fi > this.fi) {
            task.st = this.fi;
          } else {
            task.toDelete();
          }
        }
        modifed = true;
      }

      if (modifed) {
        /** @type {Set<string>} */
        const affectedDates = new Set();
        let dt = this.st;
        while (dt < this.fi) {
          affectedDates.add(getShortDate(dt));
          dt = addDays(dt, 1);
        }

        return affectedDates;
      }
    }
    return undefined;
  }

  /** @returns {AvailabilityRequestDto} */
  toDto() {
    return {
      id: this.id, employeeId: this.employeeId, st: this.st, fi: this.fi, available: this.available,
      created: this.created, updated: this.updated, msg: this.msg, approved: this.approved, replyMsg: this.replyMsg, deleted: false
    };
  }

  /**
  * @param {import("@/model/dn-task.js").ScheduleTasks} scheduleTasks
  * @param {import("@/model/dn-tasktype.js").TaskTypes} taskTypes
  * @param {import("@/model/dn-employee.js").Employee} employee
  * @param {boolean|null} approve
  * @param {{id:number;msg:string;numberOfStars?:number}} reply
  * @returns {Promise<{ok:boolean; affectedDates?:Set<string>; toastMsg?:string;}>}
  */
  async toggleApproval(scheduleTasks, taskTypes, employee, approve, reply) { // eslint-disable-line no-unused-vars
    if (employee) {
      return { ok: false };
    }
    const approved = this.approved == approve ? null : approve;
    /** @type {AvailabilityRequestPatchDto} */
    const data = { approved: approved, replyMsg: this.replyMsg };
    const result = await patchAvailabilityRequest(this.id, data);
    this.approved = approved;
    this.updated = new Date(result.updated);
    /** @type {Set<string>} */
    let affectedDates;
    if (!this.available) {
      affectedDates = this.syncWithSchedule(scheduleTasks, taskTypes);
    }
    return { ok: true, affectedDates };
  }
}

/**
 * @param {AvailabilityRequestDto} dto
 */
function isApprovedOrAutoApproved(dto) {
  if (dto.approved === null && dto.available) {
    const created = new Date(dto.created);
    if (Date.now() - created.getTime() > 1000 * 60 * 60 * 24) {
      return true;
    }
  }

  return dto.approved;
}

class AvailabilityRequestReply {
  /**
   * @param {AvailabilityRequest} ar
   * @param {boolean} isEmp
   */
  constructor(ar, isEmp) {
    /** @private @type {AvailabilityRequest} */
    this.ar = ar;
    /** @private @type {boolean} */
    this.isEmp = isEmp;
  }

  get id() {
    return this.ar.id;
  }

  get msg() {
    return this.isEmp ? this.ar.msg : this.ar.replyMsg;
  }

  set msg(value) {
    if (this.isEmp) {
      this.ar.msg = value;
    } else {
      this.ar.replyMsg = value;
    }
  }
}

export class AvailabilityRequests {
  /**
   * @param {AvailabilityRequestDto[]} [dtoList]
   */
  constructor(dtoList = undefined) {
    /** @private @readonly @type {Map<number, AvailabilityRequest>} */
    this.byId = new Map();
    /** @private @readonly @type {Map<number, AvailabilityRequestEmployee>} */
    this.byEmp = new Map();
    if (dtoList) {
      this.setData(dtoList.map(x => new AvailabilityRequest(x)), false);
    }
  }

  get isLoaded() {
    return this.id !== undefined;
  }

  /**
  * @param {{showAvailability:boolean;showUnavailability:boolean;showFuture:boolean;showBasedOnSelection:boolean;showApproved:boolean;showRejected:boolean}} filter
  * @param {Date} future
  * @param {number} selectedEmpId
  * @param {import("@/model/dn-employee.js").Employee[]} empList
  * @param {Date} now
  */
  getByFilter(filter, future, selectedEmpId, empList, now) {
    /** @type {AvailabilityRequest[]} */
    const requests = [];
    for (const r of this.byId.values()) {
      if (r.isFilterOk(filter, future, selectedEmpId, empList, now)) {
        requests.push(r);
      }
    }
    return requests;
  }

  /**
   * @param {number} employeeId
   * @param {Date} dt
   * @returns {AvailabilityRequest[]}
   */
  getByEmpDate(employeeId, dt) {
    const empRequests = this.byEmp.get(employeeId);
    if (empRequests) {
      const dtRequests = empRequests.byDate.get(dt.getTime());
      if (dtRequests) {
        return dtRequests.slice();
      }
    }
    return [];
  }

  /** @param {Map<number, Date[]>} byEmp */
  getOkOnSelection(byEmp) {
    /** @type {AvailabilityRequestDto[]} */
    const l = [];
    for (const r of this.byId.values()) {
      if (r.isApproved) {
        const dates = byEmp.get(r.employeeId);
        if (dates) {
          l.push(r.toDto());
        }
      }
    }
    return l;
  }

  /**
   * @param {number} id
   * @param {boolean} idIsEmployee
   */
  init(id, idIsEmployee) {
    /** @private @type {number} */
    this.id = id;
    /** @private @type {boolean} */
    this.idIsEmployee = idIsEmployee;
    /** @private @type {number} */
    this.loadedStTime = null;
    /** @private @type {number} */
    this.updated = 0;
    this.byId.clear();
  }

  /**
   * @param {Date} st
   */
  async load(st) {
    const stTime = st.getTime();
    if (this.loadedStTime === null) {
      await this.getData(stTime);
      this.loadedStTime = stTime;
    } else if (this.loadedStTime > stTime) {
      await this.getData(stTime, this.loadedStTime);
      this.loadedStTime = stTime;
    }
  }

  async refresh() {
    if (this.loadedStTime === null) { return; }
    const ts = this.updated;
    await this.getData(this.loadedStTime, undefined, ts);
  }

  /**
   * @private
   * @param {number} st
   * @param {number} [fi]
   * @param {number} [ts]
   */
  async getData(st, fi = undefined, ts = undefined) {
    const updateUpdated = fi === undefined;
    const rows = await getAvailabilityRequests(st, fi, this.id, this.idIsEmployee, ts);
    /** @type {AvailabilityRequest[]} */
    const requests = [];
    for (const r of rows) {
      if (r.deleted) {
        this.byId.delete(r.id);
        const availEmp = this.byEmp.get(r.employeeId);
        if (availEmp) {
          availEmp.remove(r);
        }
        if (updateUpdated) {
          const updated = (new Date(r.updated)).getTime();
          if (this.updated < updated) {
            this.updated = updated;
          }
        }
      } else {
        requests.push(new AvailabilityRequest(r));
      }
    }

    this.setData(requests, updateUpdated);
  }

  /**
   * @param {number} employeeId
   */
  getByEmp(employeeId) {
    return this.byEmp.get(employeeId);
  }

  /**
   * @private
   * @param {AvailabilityRequest[]} rows
   * @param {boolean} updateUpdated
   */
  setData(rows, updateUpdated) {
    for (const r of rows) {
      this.byId.set(r.id, r);
      let availEmp = this.byEmp.get(r.employeeId);
      if (availEmp === undefined) {
        availEmp = new AvailabilityRequestEmployee();
        this.byEmp.set(r.employeeId, availEmp);
      }
      availEmp.set(r);
      if (updateUpdated) {
        const updated = r.updated !== null ? r.updated.getTime() : r.created.getTime();
        if (this.updated < updated) {
          this.updated = updated;
        }
      }
    }
  }
}

class AvailabilityRequestEmployee {
  constructor() {
    /** @type {Map<number, AvailabilityRequest[]>} */
    this.byDate = new Map();
  }

  /**
   * @param {Date} dt
   * @param {{st:number;fi:number}[]} unavail
   */
  adjustQuarterHoursByDateUnavailability(dt, unavail) {
    const requests = this.byDate.get(getStartOfDay(dt).getTime());
    if (requests) {
      const nextDt = addDays(dt, 1);
      for (const r of requests) {
        if (r.isApproved) {
          const st = r.st > dt ? getQuarterHour(r.st) : 0;
          const fi = r.fi < nextDt ? getQuarterHour(r.fi) : 96;
          addToIntervals(unavail, st, fi, r.available);
        }
      }
    }
  }

  /**
   * @param {Date} startDate
   * @param {import("@/model/dn-employee-schedule").AvailabilityByDay} availabilityByDay
   */
  adjustQuarterHoursByDateAvailability(startDate, availabilityByDay) {
    for (let i = 0; i < availabilityByDay.length; i++) {
      const dayStart = addDays(startDate, i);
      const requests = this.byDate.get(dayStart.getTime());
      if (requests) {
        for (const r of requests) {
          if (r.isApproved) {
            const dayEnd = addDays(dayStart, 1);
            const st = getQuarterHourRelative(dayStart, dayEnd, r.st);
            const fi = getQuarterHourRelative(dayStart, dayEnd, r.fi);
            addToIntervals(availabilityByDay[i], st, fi, !r.available);
          }
        }
      }
    }
  }

  /**
   * @param {AvailabilityRequestDto} r
   */
  remove(r) {
    let dt = getStartOfDay(new Date(r.st));
    const fi = new Date(r.fi);
    while (dt < fi) {
      const dtTime = dt.getTime();
      const avails = this.byDate.get(dtTime);
      if (avails !== undefined) {
        const index = avails.findIndex(x => x.id === r.id);
        if (index >= 0) {
          avails.splice(index, 1);
        }
      }

      dt = addDays(dt, 1);
    }
  }

  /**
   * @param {AvailabilityRequest} ar
   */
  set(ar) {
    let dt = getStartOfDay(ar.st);
    while (dt < ar.fi) {
      const dtTime = dt.getTime();
      const avails = this.byDate.get(dtTime);
      if (avails === undefined) {
        this.byDate.set(dtTime, [ar]);
      } else {
        const index = avails.findIndex(x => x.id === ar.id);
        if (index >= 0) {
          avails[index] = ar;
        } else {
          avails.push(ar);
        }
      }

      dt = addDays(dt, 1);
    }
  }
}

/**
 * @param {{st:number; fi:number}[]} intervals
 * @param {number} st
 * @param {number} fi
 * @param {boolean} inverted
 */
function addToIntervals(intervals, st, fi, inverted) {
  if (inverted) {
    let i = intervals.length - 1;
    while (i >= 0) {
      const interval = intervals[i];
      if (interval.st < fi && interval.fi > st) {
        if (interval.st < st) {
          if (interval.fi > fi) {
            intervals.push({ st: fi, fi: interval.fi });
          }
          interval.fi = st;
        } else {
          if (fi < interval.fi) {
            interval.st = fi;
          } else {
            intervals.splice(i, 1);
          }
        }
      }
      i--;
    }
  } else {
    let i = intervals.length - 1;
    while (i >= 0) {
      const interval = intervals[i];
      if (interval.st <= fi && interval.fi >= st) {
        interval.st = Math.min(interval.st, st);
        interval.fi = Math.max(interval.fi, fi);
        st = fi;
        break;
      }
      i--;
    }

    if (st < fi) {
      intervals.push({ st, fi });
    }
  }
  intervals.sort((a, b) => a.st - b.st);
}

/**
 * @param {Date} dayStart
 * @param {Date} dayEnd
 * @param {Date} dt
 */
function getQuarterHourRelative(dayStart, dayEnd, dt) {
  if (dt < dayStart) {
    return getQuarterHour(dt) - 96;
  } else if (dt >= dayEnd) {
    return getQuarterHour(dt) + 96;
  }
  return getQuarterHour(dt);
}