<template>
  <div :class="[{ 'is-dark': isDark==true }, 'nav-item']">
    <Icon :name="icon" />
    <div class="nav-label">
      {{ label }}
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Base/Icon.vue";

export default {
  name: "MobileNavbarItem",
  components: { Icon},
  props: {
    label: String,
    icon: String,
    isDark: Boolean,
  },
  data() {
    return {};
  },
  computed: {
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/css/variables.module.scss";

.nav-item {
  text-align: center;
  flex-grow: 0;
  min-width: 60px;
  background-color: transparent;
  color:$dark;
  cursor: pointer;
  &.is-dark{
    color:$grey;
  }
  &.is-active{
    color:$black; 
    &.is-dark{
    color:$white;
  }
  }
  
  .nav-label {
    font-size: $fontSmall;
  }
  
}
@media screen and (max-width: 767px) {
}
</style>
