import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays } from '@/js/dn-helper.js';

/**
* @typedef {{employeeId:number; st:string, fi:string; callcenterId:number}} EmployeeCallcenterRow
*/

/**
* @typedef {{employeeId:number; intervals:{st:string;fi:string}[]; callcenterId:number}} EmployeeCallcenterDto
*/

/**
* @typedef {{st:Date;fi:Date;callcenterId:number}} CallcenterInterval
*/

/**
* @typedef {{employeeId:number; ccIntervals:CallcenterInterval[]}} EmployeeCallcenterEmpDto
*/

export class EmployeeCallcenters {
  /** 
   * @param {EmployeeCallcenterEmpDto[]} [empCCToList]
  */
  constructor(empCCToList = undefined) {
    /** @private @type {Map<number, EmployeeCallcenter>} */
    this._byEmployeeId = undefined;
    if (empCCToList !== undefined) {
      this._byEmployeeId = new Map();
      for (const ec of empCCToList) {
        this._byEmployeeId.set(ec.employeeId, new EmployeeCallcenter(ec.ccIntervals));
      }
    }
  }

  get isLoaded() {
    return this._byEmployeeId !== undefined;
  }

  /**
   * @param {number} employeeId
   */
  getByEmp(employeeId) {
    if (!this.isLoaded) { return undefined; }
    return this._byEmployeeId.get(employeeId);
  }

  /**
   * @param {{id:number,ccid:number}} emp
   * @param {Date} dt start of the day
   */
  getCCByDate(emp, dt) {
    const empCC = this.getByEmp(emp.id);
    if (empCC) {
      const midday = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 12);
      return empCC.getCCByDate(emp.ccid, midday);
    }

    return emp.ccid;
  }

  async load() {
    const records = await getEmployeeCallcenter();
    this.init(records);
  }

  /**
   * @param {EmployeeCallcenterRow[]} records
   */
  init(records) {
    /** @private @type {Map<number, EmployeeCallcenter>} */
    const byEmployeeId = new Map();
    for (const r of records) {
      let ec = byEmployeeId.get(r.employeeId);
      if (ec === undefined) {
        ec = new EmployeeCallcenter();
        byEmployeeId.set(r.employeeId, ec);
      }
      ec.add(r);
    }
    this._byEmployeeId = byEmployeeId;
  }

  /**
   * @param {number} employeeId
   * @param {Date[]} dates
   * @param {number} callcenterId
   */
  async save(employeeId, dates, callcenterId) {
    dates.sort((a, b) => a.getTime() - b.getTime());

    /** @type {{st:Date;fi:Date}[]} */
    const intervals = [];
    for (const d of dates) {
      const n = intervals.length;
      if (n === 0 || intervals[n - 1].fi.getTime() < d.getTime()) {
        intervals.push({ st: d, fi: addDays(d, 1) });
      } else {
        intervals[n - 1].fi = addDays(d, 1);
      }
    }

    /** @type {EmployeeCallcenterDto} */
    const data = { employeeId: employeeId, intervals: [], callcenterId };
    for (const i of intervals) {
      data.intervals.push({ st: i.st.toISOString(), fi: i.fi.toISOString() });
    }
    await postEmployeeCallcenter(data);
    await this.load();
  }

  toDto() {
    /** @type {EmployeeCallcenterEmpDto[]} */
    const empCCList = [];
    for (const [employeeId, ec] of this._byEmployeeId) {
      empCCList.push({ employeeId, ccIntervals: ec.getListCopy() });
    }
    return empCCList;
  }
}

class EmployeeCallcenter {
  /**
   * @param {CallcenterInterval[]} [list]
   */
  constructor(list = []) {
    /** @private @readonly @type {CallcenterInterval[]} sorted by st*/
    this._list = list;
  }

  /**
   * @param {EmployeeCallcenterRow} dto
   */
  add(dto) {
    this._list.push({ st: new Date(dto.st), fi: new Date(dto.fi), callcenterId: dto.callcenterId });
  }

  /**
   * @param {number} defaultId
   * @param {Date} dt
   */
  getCCByDate(defaultId, dt) {
    for (const r of this._list) {
      if (r.st <= dt && dt < r.fi) {
        return r.callcenterId;
      }
    }
    return defaultId;
  }

  getListCopy() {
    return this._list.slice();
  }

  /**
   * @param {number} defaultId
   * @param {Date} st
   * @param {Date} fi
   * @param {number} excludeId
   */
  getOnInterval(defaultId, st, fi, excludeId) {
    /** @type {CallcenterInterval[]} */
    const intervals = [];
    let last = st;
    for (const r of this._list) {
      if (r.fi > last) {
        if (r.st < fi) {
          if (last < r.st) {
            addToIntervals(r.st, defaultId);
          }
          if (r.fi < fi) {
            addToIntervals(r.fi, r.callcenterId);
          } else {
            addToIntervals(fi, r.callcenterId);
          }
        } else {
          break;
        }
      }
    }
    if (last < fi) {
      addToIntervals(fi, defaultId);
    }
    return intervals;
    /**
     * @param {Date} fiInterval
     * @param {number} callcenterId
     */
    function addToIntervals(fiInterval, callcenterId) {
      if (excludeId !== callcenterId) {
        intervals.push({ st: last, fi: fiInterval, callcenterId });
      }
      last = fiInterval;
    }
  }
}

/**
 * @returns {Promise<EmployeeCallcenterRow[]>}
 */
async function getEmployeeCallcenter() {
  return await fetchAndCheckJson(`employee-callcenter`, 'GET');
}

/**
 * @param {EmployeeCallcenterDto} data
 */
async function postEmployeeCallcenter(data) {
  return await fetchAndCheckJson(`employee-callcenter`, 'POST', data);
}
