import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays, getStartOfWeek, getShortDate } from '@/js/dn-helper.js';
import { getCallStatistics } from '@/js/dn-call-statistics.js';
import { getDaytype } from '@/js/dn-forecast.js';

class ForecastData {
  constructor() {
    /** @private @type {Map<string, { dt: Date; acgr: number; ahtData: number[]; nocData: number[]; dayType:number}>} */
    this._historicDataMap = new Map();
    /** @private @type {Map<string, number>} */
    this._specialDaysMap = new Map();
  }

  get historicDataMap() {
    return this._historicDataMap;
  }

  get specialDaysMap() {
    return this._specialDaysMap;
  }

  /**
   * @param {Date} focusDt
   * @param {{ historicWeeks: number; historicFocusDate: Date; percentOfAbandonsToInclude: number; }} forecastOptions
   * @param {{id:number}[]} dayTypes
   */
  async loadHistoricData(focusDt, forecastOptions, dayTypes) {
    if (forecastOptions.historicFocusDate !== null) {
      focusDt = forecastOptions.historicFocusDate;
    }
    const historicDataMap = new Map();
    const specialDaysMap = this.specialDaysMap;
    const startDate = getStartOfWeek(addDays(focusDt, -forecastOptions.historicWeeks * 7)); /* mon or sun of the youngset week */
    const percentOfAbandonsToInclude = forecastOptions.percentOfAbandonsToInclude
    const result = await getCallStatistics((forecastOptions.historicWeeks + 1) * 7, startDate, percentOfAbandonsToInclude);
    if (result.isCalculating)
      return false;

    const msPerQuarterHour = 1000 * 60 * 15;
    for (const histData of result.data) {
      for (let d = 0; d < (forecastOptions.historicWeeks + 1) * 7; d++) {
        const dt = addDays(startDate, d)
        //supporting daylight saving transition
        const nextDt = addDays(startDate, d + 1);
        const quarterAtDayEnd = Math.round((nextDt.getTime() - startDate.getTime()) / msPerQuarterHour);
        const quarterAtDaystart = Math.round((dt.getTime() - startDate.getTime()) / msPerQuarterHour);
        const key = histData.cgId + '_' + getShortDate(dt);
        const noc = histData.noc.slice(quarterAtDaystart, quarterAtDayEnd)
        const aht = histData.ht.slice(quarterAtDaystart, quarterAtDayEnd)

        let xVal = {
          dt: dt,
          acgr: histData.cgId,
          dayType: getDaytype(dt, specialDaysMap),
          nocData: noc,
          ahtData: aht
        };

        historicDataMap.set(key, DaysavingTransitionCorrection(xVal))
      }
    }

    let counterMap = new Map();
    for (let i = 0; i < dayTypes.length; i++) { counterMap.set(dayTypes[i].id, 0) }

    for (let d2 = 0; d2 < 365 * 6; d2++) {    //earlier then the normal x weeks of data
      let dt2 = addDays(startDate, -d2)
      let dayTypeId = getDaytype(dt2, specialDaysMap)
      if (dayTypeId > 0 && counterMap.get(dayTypeId) < 10) {  //dates with specialDay and only the 10 latest

        const dayResult = await getCallStatistics(1, dt2, percentOfAbandonsToInclude);
        if (dayResult.isCalculating)
          return false;

        for (const histData of dayResult.data) {
          let key2 = histData.cgId + '_' + getShortDate(dt2);
          let xVal = {
            dt: dt2,
            acgr: histData.cgId,
            dayType: dayTypeId,
            nocData: histData.noc,
            ahtData: histData.ht
          };

          historicDataMap.set(key2, DaysavingTransitionCorrection(xVal))
        }
        let count = counterMap.get(dayTypeId)
        counterMap.set(dayTypeId, count + 1)
      }
    }

    this._historicDataMap = historicDataMap;
    return true;

    function DaysavingTransitionCorrection(xVal) {
      if (xVal.nocData.length > 96) {
        xVal.nocData = xVal.nocData.slice(0, 96)
        xVal.ahtData = xVal.ahtData.slice(0, 96)
      }
      if (xVal.nocData.length == 92) {
        for (let i = 0; i < 4; i++) {
          xVal.nocData.push(0)
          xVal.ahtData.push(0)
        }
      }
      return xVal
    }
  }

  async loadSpecialDays() {
    /** @type {{dt:string;dtid:number}[]} */
    const rows = await fetchAndCheckJson(`specialday`, 'GET');
    /** @type {Map<string, number>} */
    const daytypeMap = new Map();
    for (const r of rows) {
      daytypeMap.set(r.dt, r.dtid);
    }
    this._specialDaysMap = daytypeMap;
  }

  /**
   * @param {Date} dt
   * @param {number} dtid
   * @param {boolean} system
   */
  async saveSpecialDay(dt, dtid, system) {
    const dtString = getShortDate(dt);
    if (system && this._specialDaysMap.has(dtString)) {
      this._specialDaysMap.delete(dtString);
    } else {
      this._specialDaysMap.set(dtString, dtid);
    }
    const id = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    if (!system) {
      await fetchAndCheckJson(`specialday/${id}`, 'PUT', { dtid });
    }
    else {
      await fetchAndCheckJson(`specialday/${id}`, 'DELETE');
    }
  }

  reset() {
    this._historicDataMap = new Map();
    this._specialDaysMap = new Map();
  }
}

export const forecastData = new ForecastData();