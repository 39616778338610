class SimulationData {
  constructor() {
    /** @private @type {Map<string, import("@/js/dn-simulation-model.js").SimData>} */
    this._simulationDataMap = new Map();
    /** @private @type {Map<string, Map<number, number[]>>} */
    this._targetDataMap = new Map();
    /** @private @type {Map<number, import("@/model/dn-employee.js").Employee[]>} */
    this._emplyeesByAffinity = null
    /** @private @type {Map<number, number>} */
    this._callGroupPrioInfoMap = null
    /**  @type {number} */
    this.callGroupPrioMax = 0
  }

  get simulationDataMap() {
    return this._simulationDataMap;
  }

  get targetDataMap() {
    return this._targetDataMap;
  }

  get emplyeesByAffinity(){
    return this._emplyeesByAffinity
  }
  set emplyeesByAffinity(value){
    this._emplyeesByAffinity=value
  }

  get callGroupPrioInfoMap(){
    return this._callGroupPrioInfoMap
  }
  set callGroupPrioInfoMap(value){
    this._callGroupPrioInfoMap=value
  }



  reset() {
    this._simulationDataMap = new Map();
    this._targetDataMap = new Map();
    this._emplyeesByAffinity = null
    this._callGroupPrioInfoMap=null
    this.callGroupPrioMax=0
  }
}

export const simulationData = new SimulationData();