
import { createI18n } from 'vue-i18n';
import messages from '@/locales/en.json';
import { getLanguage } from "@/js/dn-localStorage";

const SUPORTED_LANGUAGES = Object.freeze(['en', 'sv', 'de']);
const loadedLanguages = ['en'];

export const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { 'en': messages }, 
})

loadSelectedLang();

function getBrowserDefaultLanguage() {
  const languages = navigator.languages;
  if (languages) {
    for (const lang of languages) {
      const shortCode = getISO639Code(lang);
      if (SUPORTED_LANGUAGES.includes(shortCode)) {
        return lang;
      }
    }
  }
  return 'en-US';
}

/**
 * @param {string} code
 */
function getISO639Code(code) {
  if(code){
  const splitted = code.split('-');
  return splitted[0];
}else{return undefined}
}

export function getLanguageCode() {
  //get selected lang from local storage
  const lang = getLanguage();
  if (lang) { return lang; }

  //if not get default from browser
  const browserLang=getBrowserDefaultLanguage();
  //check if browser lang is supported 
  //else return undefined
  if(loadedLanguages.includes(browserLang)){
    return browserLang
  }else{return undefined}
}

export function getSupportedCultures() {
  return ['en-US', 'en-GB', 'sv-SE', 'de-DE'];
}

function loadSelectedLang() {
  //if no getLanguageCode load en language
  //languageCode remains undefined and forces time/date settings from browser
  const languageCode=getLanguageCode()
  let lang ='en'
  if(languageCode){lang = getISO639Code(languageCode)}
  if (lang !== 'en') {
    loadLanguageAsync(lang)
  }
}

/**
 * @param {string} languague
 */
export async function loadLanguageAsync(languague) {
  const lang = getISO639Code(languague);
  if (i18n.global.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  return import(`@/locales/${lang}.json`).then(
    messages => {
      i18n.global.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}

/**
 * @param {string} lang
 */
function setI18nLanguage(lang) {
  // @ts-ignore
  i18n.global.locale = lang
  // document.querySelector('html').setAttribute('lang', lang)
  return lang
}