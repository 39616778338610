import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { fromISODate, isSubset, getDayDiff, toISODate } from '@/js/dn-helper.js';
import { getLocalOffset } from '@/js/dn-timezone.js';

/**
* @typedef {{ id:number|null, stFi: number[]; st: string; weeks: number; employeeIdList:number[] }} AvailabilityDto
*/

export class Availabilities {
  /**
   * @param {AvailabilityDto[]} dto
   */
  constructor(dto = undefined) {
    /** @private @type {AvailabilityDto[]} */
    this._dto = dto;
  }

  get dto() {
    return this._dto;
  }

  get byEmp() {
    if (this._byEmp === undefined) {
      /** @private @type {Map<number, Availability>} */
      this._byEmp = new Map();
      for (const availability of this.items) {
        for (const employeeId of availability.employeeIdList) {
          this._byEmp.set(employeeId, availability);
        }
      }
    }

    return this._byEmp;
  }

  get isLoaded() {
    return this._dto !== undefined;
  }

  get items() {
    if (this._items === undefined) {
      /** @private @type {Availability[]} */
      this._items = this.dto.map(r => new Availability(fromISODate(r.st), r.weeks, r.stFi, r.employeeIdList, r.id));
    }
    return this._items;
  }

  clear() {
    this._dto = undefined;
    this._byEmp = undefined;
    this._items = undefined;
  }

  /**
   * @param {number} ccId
   */
  async load(ccId) {
    this.clear();
    /** @type {AvailabilityDto[]} */
    const rows = await fetchAndCheckJson('availability?ccId=' + ccId, 'GET');
    this._dto = rows;
  }
}

export function clearAvailability(a) {
  a.weeks = 0
  a.intervals = []
  a.employeeIdList = []
  a.id = null
}

export class Availability {
  /**
   * @param {number} id
   * @param {Date} st
   * @param {number} weeks
   * @param {number[]} stFi st and fi in quarter hours
   * @param {number[]} employeeIdList
   */
  constructor(st, weeks, stFi, employeeIdList, id = null) {
    /** @private @type {number|null} */
    this._id = id;
    /** @type {number} */
    this.weeks = weeks;
    /** @type {Date} */
    this.st = st;
    /** @type {number[]} quarter hours from start of period */
    this.stFi = stFi;
    /** @readonly @type {number[]}  */
    this.employeeIdList = employeeIdList;
  }

  get id() {
    return this._id;
  }

  get availabilityUI() {
    let intervals = []

    for (let i = 0; i < this.stFi.length; i++) {
      intervals.push({ st: this.stFi[i], fi: this.stFi[i + 1] })
      i++
    }
    return { weeks: this.weeks, intervals: intervals, employeeIdList: this.employeeIdList, id: this.id }
  }

  /**
   * @param {Date} startDt
   * @param {string} timezone
   */
  getUnavailability(startDt, timezone) {
    const localOffsetQ = getLocalOffset(startDt, timezone) / 15;
    const unavailability = [0];
    for (let i = 0; i < this.stFi.length; i += 1) {
      const q = this.stFi[i] - localOffsetQ;
      unavailability.push(q);
    }
    const endQ = this.weeks * 7 * 96;
    const lastQ = this.stFi[this.stFi.length - 1] - localOffsetQ;
    unavailability.push(endQ)
    if (lastQ > endQ) { 
      unavailability[0] = lastQ - endQ;
    }
    const firstQ = this.stFi[0] - localOffsetQ;
    if (firstQ < 0) {
      unavailability[unavailability.length-1] = endQ + firstQ;
    } 
    return unavailability;
  }
  /**
   * @param {Date} dt
   */
  getDayIndex(dt) {
    const dayDiff = getDayDiff(dt, this.st);
    const days = this.weeks * 7;
    return dayDiff % days;
  }

  /**
   * @param {number} dayIndex
   */
  getByDayIndex(dayIndex) {
    if (this._byDayIndex === undefined) {
      const dayCount = this.weeks * 7;
      /** @private @type {import("@/model/dn-employee-schedule").AvailabilityByDay} */
      this._byDayIndex = [];
      let j = 0;
      let daySt = 0;
      for (let i = 0; i < dayCount; i++) {
        let intervals = [];
        const dayFi = daySt + 96;
        while (j < this.stFi.length && this.stFi[j] < dayFi) {
          const fi = this.stFi[j + 1];
          if (fi > daySt) {
            intervals.push({ st: this.stFi[j] - daySt, fi: this.stFi[j + 1] - daySt });
          }
          if (fi > dayFi) { break; }
          j += 2;
        }

        this._byDayIndex.push(intervals);
        daySt = dayFi;
      }

      if (j < this.stFi.length) {
        this._byDayIndex[0].splice(0, 0, { st: this.stFi[j] - daySt, fi: this.stFi[j + 1] - daySt });
      }
    }
    return this._byDayIndex[dayIndex];
  }
}

/**
 * @param {number[]} checkedEmps
 */
export async function deleteAvailability(checkedEmps) {
  /** @type {AvailabilityDto} */
  const data = { id: null, st: '', weeks: 0, stFi: [], employeeIdList: checkedEmps };
  await fetchAndCheckJson('availability', 'POST', data);
}

/**
 * @param {number|null} id
 * @param {Date} st
 * @param {number} weeks
 * @param {number[]} stFi
 * @param {number[]} employeeIdList
 * @param {number[]} checkedEmps
 */
export async function saveAvailability(id, st, weeks, stFi, employeeIdList, checkedEmps) {
  checkedEmps.sort();
  employeeIdList.sort();
  if (!isSubset(checkedEmps, employeeIdList)) {
    id = null;
  }

  /** @type {AvailabilityDto} */
  const data = { id: id, st: toISODate(st), weeks: weeks, stFi: stFi, employeeIdList: checkedEmps };
  await fetchAndCheckJson('availability', 'POST', data);
}
