import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { Skill } from '@/model/dn-skill.js';
import { addDays, fromISODate, toISODate, getUniqueNegativeId } from '@/js/dn-helper.js';

/**
* @typedef {{ id?: number; interactions:number; aht:number, daysOfWeek:number[]|null; openHours:number[]|null}} SkillBODetailDto
*/

/**
* @typedef {{ id?: number; schemecolor:string|null; minLength?:number|null;}} SkillBOTaskTypeDto
*/

/**
* @typedef {import("@/model/dn-skill.js").SkillDto & { taskType: SkillBOTaskTypeDto; st?:string|null; fi?:string|null; details:SkillBODetailDto[]}} SkillBODto
*/

/**
* @typedef {{ target:number, openHours:number[]|null }} BOTargetDetail
*/

/**
* @typedef {{ details:BOTargetDetail[] }} BOTargetDay
*/

/**
* @typedef { {id:number; taskTypeId: number; minLength:number; affinity:number; optimizationPriority:number; targetByDay:BOTargetDay[] }} BOTarget
*/

export class BackOfficeSkill extends Skill {
  /**
   * @param {SkillBODto} dto
   */
  constructor(dto) {
    super(dto);
    /** @private @type {SkillBOTaskTypeDto} */
    this.taskType = { id: dto.taskType.id, schemecolor: dto.taskType.schemecolor, minLength: dto.taskType.minLength };
    /** @readonly @type {BackOfficeSkillDetail[]} */
    this.details = dto.details.map(x => new BackOfficeSkillDetail(this, x));
    /** @private @type {Date} */
    this._stDate = fromISODate(dto.st);
    /** @private @type {Date} */
    this._fiDate = fromISODate(dto.fi);
  }

  get stDate() {
    return this._stDate;
  }

  set stDate(newValue) {
    this._stDate = newValue;
    this.markAsHasChanges();
  }

  get fiDate() {
    return this._fiDate;
  }

  set fiDate(newValue) {
    this._fiDate = newValue;
    this.markAsHasChanges();
  }

  get minLength() {
    return this.taskType.minLength;
  }

  set minLength(newValue) {
    const v = Number(newValue);
    if (Number.isNaN(v) || v < 0) { return; }
    this.taskType.minLength = v;
    this.markAsHasChanges();
  }

  get schemecolor() {
    return this.taskType.schemecolor;
  }

  set schemecolor(newValue) {
    this.taskType.schemecolor = newValue;
    this.markAsHasChanges();
  }

  addDetail() {
    const id = getUniqueNegativeId(this.details);
    this.details.push(new BackOfficeSkillDetail(this, { id, aht: 0, interactions: 1, daysOfWeek: null, openHours: null }));
  }

  toDto() {
    /** @type {SkillBODto} */ // @ts-ignore
    const dto = super.toDto();
    dto.st = toISODate(this.stDate);
    dto.fi = toISODate(this.fiDate);
    dto.taskType = this.taskType;
    dto.details = this.details.map(x => x.toDto());
    return dto;
  }
}

export class BackOfficeSkillDetail {
  /**
   * @param {BackOfficeSkill} parent
   * @param {SkillBODetailDto} dto
   */
  constructor(parent, dto) {
    /** @private @type {BackOfficeSkill} */
    this.parent = parent;
    /** @readonly @type {number} */
    this.id = dto.id;
    /** @private @type {number} */
    this._aht = dto.aht;
    /** @private @type {number} */
    this._interactions = dto.interactions;
    /** @private @type {number[]} */
    this._daysOfWeek = dto.daysOfWeek ? dto.daysOfWeek : [0, 1, 2, 3, 4, 5, 6];
    /** @private @type {number[]} */
    this._openHours = dto.openHours ? dto.openHours : [0, 96];
  }

  get aht() {
    return this._aht;
  }

  set aht(newValue) {
    this._aht = newValue;
    this.parent.markAsHasChanges();
  }

  get interactions() {
    return this._interactions;
  }

  set interactions(newValue) {
    this._interactions = newValue;
    this.parent.markAsHasChanges();
  }

  get daysOfWeek() {
    return this._daysOfWeek;
  }

  set daysOfWeek(newValue) {
    this._daysOfWeek = newValue;
    this.parent.markAsHasChanges();
  }

  get openHours() {
    return this._openHours;
  }

  set openHours(newValue) {
    if (newValue[0] > newValue[1]) {
      const st = newValue[1];
      newValue[1] = newValue[0];
      newValue[0] = st;
    }
    this._openHours = newValue;
    this.parent.markAsHasChanges();
  }

  /**
   * @param {Intl.LocalesArgument} lang
   */
  openHoursInfo(lang) {
    const st = this.openHours[0];
    const fi = this.openHours[1];
    if (st > 0 || fi < 96) {
      const dtFormat = new Intl.DateTimeFormat(lang, { hour: 'numeric', minute: '2-digit' });
      const zero = new Date(2000, 0, 1);
      const ticksPerQ = 1000 * 60 * 15;
      const stDate = new Date(zero.getTime() + ticksPerQ * st);
      const fiDate = new Date(zero.getTime() + ticksPerQ * fi);
      return `${dtFormat.format(stDate)} - ${dtFormat.format(fiDate)}`;
    }
    return '';
  }

  /** @returns {SkillBODetailDto} */
  toDto() {
    const id = this.id >= 0 ? this.id : undefined;
    const daysOfWeek = this._daysOfWeek.length !== 7 ? this._daysOfWeek : null;
    return { id, aht: this._aht, interactions: this._interactions, daysOfWeek, openHours: this._openHours };
  }
}

export class BackOfficeSkills {
  /**
 * @param {SkillBODto[]} [dtoList]
 */
  constructor(dtoList = undefined) {
    /** @private @type {SkillBODto[]} */
    this.dtoList = dtoList;
  }

  get hasBO() {
    return this.isLoaded && this.dtoList.length > 0;
  }

  get isLoaded() {
    return this.dtoList !== undefined;
  }

  /**
   * @param {number[]} [ccList]
   */
  createList(ccList = undefined) {
    return this.dtoList.filter(x => !ccList || ccList.length === 0 || ccList.includes(x.ccid)).map(x => new BackOfficeSkill(x));
  }

  /**
   * @param {number} ccId
   * @param {Date} st
   * @param {number} numberOfDays
   */
  getTargetByTaskType(ccId, st, numberOfDays) {
    /** @type {Map<number, BOTarget>} */
    const byTaskTypeId = new Map();
    const fi = addDays(st, numberOfDays);
    for (const skillBO of this.dtoList) {
      if (skillBO.ccid === ccId && skillBO.details.length > 0) {
        const boSt = fromISODate(skillBO.st);
        const boFi = fromISODate(skillBO.fi);
        if ((!boSt || boSt < fi) && (!boFi || boFi > st)) {
          /** @type {BOTargetDay[]} */
          const targetByDay = Array(numberOfDays);
          for (let i = 0; i < numberOfDays; i++) {
            /** @type {BOTargetDay} */
            let boTargetDay = null;
            const dt = addDays(st, i);
            if ((!boSt || boSt <= dt) && (!boFi || boFi >= dt)) {
              const day = dt.getDay();
              /** @type {BOTargetDetail[]} */
              const details = [];
              for (const detail of skillBO.details) {
                if (!detail.daysOfWeek || detail.daysOfWeek.includes(day)) {
                  /** @type {BOTargetDetail} */
                  const targetDetail = { target: detail.interactions * detail.aht, openHours: detail.openHours };
                  details.push(targetDetail);
                }
              }

              if (details.length > 0) {
                boTargetDay = { details };
              }
            }

            targetByDay[i] = boTargetDay;
          }
          /** @type {BOTarget} */
          const boTarget = {
            id: skillBO.id, taskTypeId: skillBO.taskType.id, minLength: skillBO.taskType.minLength,
            affinity: skillBO.affinityId, optimizationPriority: skillBO.optimizationPriority, targetByDay
          };
          byTaskTypeId.set(boTarget.taskTypeId, boTarget);
        }
      }
    }

    return byTaskTypeId;
  }

  async load() {
    this.dtoList = await fetchAndCheckJson(`bo`, 'GET');
  }
}

/**
 * @param {number} id
 * @param {number|null} ccId
 */
export function createBackOfficeSkill(id, ccId) {
  /** @type {SkillBOTaskTypeDto} */
  const taskType = { schemecolor: 'stroke4' };
  return new BackOfficeSkill({ id, name: '', ccid: ccId, taskType, details: [] });
}

/**
 * @param {{ toDto: () => SkillBODto; readonly id: number; readonly hasChanges: boolean; readonly isToDelete: boolean; }[]} skills
 */
export async function saveBackOfficeSkills(skills) {
  /** @type {SkillBODto[]} */
  const changed = [];
  /** @type {number[]} */
  const deleted = [];
  for (const skill of skills) {
    if (skill.isToDelete) {
      deleted.push(skill.id);
    } else if (skill.hasChanges) {
      changed.push(skill.toDto());
    }
  }

  if (deleted.length > 0 || changed.length > 0) {
    await fetchAndCheckJson(`bo`, 'POST', { deleted, changed });
  }
}
