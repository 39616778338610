<template>
  <section>
      <PvAccordion v-if="show" aria-id="rawData"
                   :selectOnFocus="false"
                   headerClass="card"
                   expandIcon="pi pi-arrow-up"
                   collapseIcon="pi pi-arrow-down">
          <PvAccordionTab>
              <template #header>
                  <p class="card-header-title is-size-7" v-if="title">Data: {{ title }}</p>
                  <p class="card-header-title is-size-7" v-else>Data: {{ data.name }}</p>
              </template>
              <pre class="is-size-7">{{ data }}</pre>
          </PvAccordionTab>
      </PvAccordion>
  </section>
</template>

<script>
import Icon from "./Icon";
export default {
  components: { Icon },
  props: {
    data: {},
    title: { type: String },
    show: { type: Boolean, default: true },
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/variables.module.scss";
:deep(.p-accordion){
  .p-accordion-content{
    padding: 0;
  }
  .p-accordion-header-action{
    padding: 0.5rem;
    background: $white;
    display: flex;
    flex-direction: row-reverse;
  }

}
</style>
