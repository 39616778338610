<template>
    <PvButton class="button simtree-button" rounded
              :class="[roundClass,buttonClass,size,buttonPaddingClass,{fitdark: type == 'fitdark' }]">
        <div class="icon-wrapper left" v-if="iconLeft">
            <Icon :name="iconLeft" :size="iconSize"/>
        </div>
        <span><slot/></span>
        <div class="icon-wrapper right" v-if="iconRight">
            <Icon :name="iconRight" :size="iconSize"/>
        </div>
    </PvButton>
</template>

<script>
import Icon from "./Icon";
export default {
    components: { Icon },
    props: {
        type: { type: String, default: "black-button" },
        iconLeft: { type: String, default: null },
        iconRight: { type: String, default: null },
        expanded: { type: Boolean, default: false },
        size: { type: String },
        round: { type: String },
        loading: { type: Boolean, default: false },
    },
    data() {
        return {};
    },
    computed: {
        buttonClass(){
            if(this.type == 'black-button')return 'black-button'
            if(this.type == 'primary')return 'primary'
            if(this.type == 'blueSpecial')return 'blue-special'
            if(this.type == 'is-warning')return 'warning'
            if(this.type == 'is-success')return 'success'
            if(this.type == 'is-free')return 'free'
        },
        buttonPaddingClass(){
            if(this.size == 'is-medium')return 'is-medium-padding'
            if(this.size == 'is-small')return 'is-small-padding'
            if(this.size == 'is-x-small')return 'is-x-small-padding'
        },
        iconSize(){
            if(this.size == 'is-medium')return '24px'
            else if(this.size == 'is-small')return '20px'
            else if(this.size == 'is-x-small')return '16px'
            else return '32px'
        },
        roundClass(){
            if(this.round=='sightly') return 'sightly-round'
            else if(this.round=='none') return 'none-round'
            return '';
        }
    },
    methods: {},
    created() {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/variables.module.scss";
.simtree-button {
  span {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  :deep(span) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &:focus {
    border-color: lighten($black, 5%);
    box-shadow: 0 0 5px 2px rgba($black, 0.1);
  }
  &:active {
    border-color: lighten($black, 5%);
  }
  &.sightly-round{
    border-radius: 4px;
  }
  &.none-round{
    border-radius: 0!important;
  }

  &.is-medium-padding {
    padding-left:12px ;
    padding-right:12px ;
    min-width: 50px;
  }

  &.is-small-padding {
    padding-left:8px ;
    padding-right:6px ;
    min-width: 40px;
    height:40px;
    border-radius: 4px;
  }
  &.is-x-small-padding {
    padding-left:8px ;
    padding-right:6px ;
    min-width: 30px;
    height:30px;
    border-radius: 4px;
  }

  &.primary {
    color: $white;
    background-color: $primary;
    width: auto;
    border-color:$stroke7;
    &:hover {
      background-color: lighten( $primary, 4%);
      border-color: $primary-dark;
    }

    :deep(.svg) {
      path {
        fill: $white;
      }
      g {
        stroke: $white;
      }
    }
  }

  &.black-button {
    color: $white;
    background-color: $black;
    width: auto;
    //border-color:$dark;
    &:hover {
      background-color: lighten($black, 25%);
    }

    :deep(.svg) {
      path {
        fill: $white;
      }
      g {
        stroke: $white;
      }
    }
  }

  &.success {
    color: $white;
    background-color: $success;
    width: auto;
    //border-color:$dark;
    &:hover {
      background-color: lighten($success, 15%);
    }

    :deep(.svg) {
      path {
        fill: $white;
      }
      g {
        stroke: $white;
      }
    }
  }

  &.free {
    color: $white;
    background-color: $free;
    width: auto;
    //border-color:$dark;
    &:hover {
      background-color: lighten($free, 15%);
    }

    :deep(.svg) {
      path {
        fill: $white;
      }
      g {
        stroke: $white;
      }
    }
  }


  &.warning {
    color: $black;
    background-color: $warning;
    width: auto;
    //border-color:$dark;
    &:hover {
      background-color: lighten($warning, 15%);
    }

    :deep(.svg) {
      path {
        fill: $black;
      }
      g {
        stroke: $black;
      }
    }
  }
  &.blue-special {
    color: $white;
    background-color: $stroke1;
    width: 400px;
    min-width: 400px;
    &:hover {
      background-color: lighten( $stroke1, 15%);
    }

    :deep(.svg) {
      path {
        fill: $white;
      }
      g {
        stroke: $white;
      }
    }
  }
  &.fitdark {
    color: $white;
    background-color: $primary;
    //border-color:$black;
    width: auto;
    &:hover {
      background-color: lighten($dark, 15%);
    }

    :deep(.svg) {
      path {
        fill: $white;
      }
      g {
        stroke: $white;
      }
    }
  }
  .icon-wrapper {
    display: inline-block;
    margin-top:1px;
    &.left {
      // float: left;
      margin-right: -5px;
    }
    &.right {
      // float: right;
      margin-left: 0px;
    }
    display: flex;

    height: 24px;
    align-items: center;
  }
  .anser-icon:first-child:last-child {
    margin-left: calc(-0.5em - 1px);
    margin-right: calc(-0.5em - 1px);
  }
}

</style>
