import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/**
* @typedef {{id:number; name:string; paid:number; schemecolor:string|null; work:number; kind:number; systemKind?:number|null; affinityId?:number|null; requiresLog?:boolean|null; bo?:boolean }} TaskTypeDto
*/

export const TASK_SYSTEM_KIND = createTaskSystemKind();
function createTaskSystemKind() {
  const enumObject = {
    operator: 0,
    shortBreak: 1,
    longBreak: 2,
    vacation: 3,
    sick: 4,
    free: 5,
    overtime: 6,
    log: 7,
    inRotation: 8,
    correction: 9,
  };

  return Object.freeze(enumObject);
}

export const TASK_KIND = createTaskKind();
function createTaskKind() {
  const enumObject = {
    task: 0,
    break: 1,
    absence: 2,
    payment: 3,
    log: 4,
    inRotation: 5,
    correction: 6,
  };

  return Object.freeze(enumObject);
}

/**
 * @param {TaskType} taskType
 */
export async function deleteTaskType(taskType) {
  return await fetchAndCheckJson(`tasktypes/${taskType.id}`, 'DELETE');
}

/**
 * @param {TaskType} taskType
 */
export async function patchTaskType(taskType) {
  return await fetchAndCheckJson(`tasktypes/${taskType.id}`, 'PATCH', taskType.toDto());
}

/**
 * @param {TaskType} taskType
 */
export async function postTaskType(taskType) {
  return await fetchAndCheckJson(`tasktypes`, 'POST', taskType.toDto());
}

/**
 * @param {number} kind
 */
function getKindAsType(kind) {
  switch (kind) {
    case TASK_KIND.task:
      return 'task';
    case TASK_KIND.break:
      return 'break';
    case TASK_KIND.absence:
      return 'absence';
    case TASK_KIND.payment:
      return 'payment';
  }

  return '';
}

/**
 * @param {number|null} systemKind
 */
function getSystemKindAsSubtype(systemKind) {
  if (systemKind === null)
    return '';
  switch (systemKind) {
    case TASK_SYSTEM_KIND.operator:
      return 'op';
    case TASK_SYSTEM_KIND.shortBreak:
      return 'sb';
    case TASK_SYSTEM_KIND.longBreak:
      return 'lb';
    case TASK_SYSTEM_KIND.vacation:
      return 'v';
    case TASK_SYSTEM_KIND.sick:
      return 's';
    case TASK_SYSTEM_KIND.free:
      return 'f';
    case TASK_SYSTEM_KIND.overtime:
      return 'ot';
  }

  return '';
}

export class TaskTypes {
  constructor() {
    /** @type {TaskTypeDto[]} */
    this.dtoList = [];
  }

  get isLoaded() {
    return this.dtoList.length !== 0;
  }

  get list() {
    if (this._list === undefined) {
      /** @private @type {TaskType[]} */
      this._list = this.dtoList.map(x => new TaskType(x));
    }
    return this._list;
  }

  get byId() {
    if (this._byId === undefined) {
      /** @type {Map<number, TaskType>} */
      const map = new Map();
      for (const tt of this.list) {
        map.set(tt.id, tt);
      }
      /** @private @type {Map<number, TaskType>} */
      this._byId = map;
    }
    return this._byId;
  }

  get bySystemKind() {
    if (this._bySystemKind === undefined) {
      /** @type {Map<number, TaskType>} */
      const map = new Map();
      for (const tt of this.list) {
        if (tt.systemKind !== null) {
          map.set(tt.systemKind, tt);
        }
      }
      /** @private @type {Map<number, TaskType>} */
      this._bySystemKind = map;
    }
    return this._bySystemKind;
  }

  async load() {
    /** @type {TaskTypeDto[]} */
    const dtoList = await fetchAndCheckJson(`tasktypes`, 'GET');
    this.init(dtoList);
  }

  /**
   * @param {TaskTypeDto[]} dtoList
   */
  init(dtoList) {
    this.dtoList = dtoList;
    this._list = undefined;
    this._byId = undefined;
    this._bySystemKind = undefined;
  }
}

export class TaskType {
  /**
   * @param {TaskTypeDto} dto
   */
  constructor(dto) {
    /** @private @type {number} */
    this._id = dto.id
    /** @private @type {string} */
    this._name = dto.name
    /** @private @type {number} */
    this._work = dto.work
    /** @private @type {string} */
    this._schemecolor = getSchemeColor(dto);
    /** @private @readonly @type {string} */
    this._type = getKindAsType(dto.kind);
    /** @private @readonly @type {string} */
    this._subtype = getSystemKindAsSubtype(dto.systemKind)
    /** @private @type {number} */
    this._paid = dto.paid
    /** @readonly @type {number} */
    this.kind = dto.kind;
    /** @readonly @type {number| null} */
    this.systemKind = dto.systemKind !== undefined ? dto.systemKind : null;
    /** @private @type {number|null} */
    this._affinity = dto.affinityId !== undefined ? dto.affinityId : null;
    /** @private @type {boolean} */
    this._hasChanges = this._id === -1;
    /** @private @type {boolean} */
    this._isToDelete = false;
    /** @private @type {boolean} */
    this._requiresLog = dto.requiresLog !== undefined && dto.requiresLog !== null ? dto.requiresLog : true;
    /** @readonly @type {boolean} */
    this.bo = dto.bo;
  }

  get id() {
    return this._id
  }

  set id(newValue) {
    this._id = newValue
  }

  get affinity() {
    return this._affinity;
  }

  set affinity(newValue) {
    this._affinity = newValue;
    this._hasChanges = true;
  }

  // should only be called if the affinity has been removed on the server.
  clearAffinity() {
    this._affinity = null;
  }

  get system() {
    return this.systemKind !== null;
  }

  get name() {
    return this._name
  }

  set name(newValue) {
    this._name = newValue
    this._hasChanges = true
  }

  get work() {
    return this._work
  }

  set work(newValue) {
    this._work = newValue
    this._hasChanges = true
  }

  //string with name of color
  get schemecolor() {
    return this._schemecolor
  }
  set schemecolor(newValue) {
    this._schemecolor = newValue
    /** @private @type {undefined|string} */
    this._color = undefined;
    this._hasChanges = true
  }

  /**
   * real color
   * @param {(schemecolor: string) => string} getColorFromName
   */
  getColor(getColorFromName) {
    if (this._color === undefined) {
      this._color = getColorFromName(this._schemecolor);
    }
    return this._color;
  }

  get type() {
    return this._type
  }

  get subtype() {
    return this._subtype
  }

  get paidBreak() {
    return this.paid !== 0;
  }

  set paidBreak(newValue) {
    if (newValue) {
      this.paid = 100;
    }
    else {
      this.paid = 0;
    }
  }

  get paid() {
    return this._paid
  }

  set paid(newValue) {
    this._paid = newValue;
    this._hasChanges = true;
  }

  get requiresLog() {
    return this._requiresLog
  }

  set requiresLog(newValue) {
    this._requiresLog = newValue;
    this._hasChanges = true;
  }

  get hasChanges() {
    return this._hasChanges
  }
  confirmChanges() {
    this._hasChanges = false
  }

  get isToDelete() {
    return this._isToDelete
  }
  toDelete() {
    this._isToDelete = true;
    this._hasChanges = true;
  }

  /** @returns {TaskTypeDto} */
  toDto() {
    return { id: this.id, name: this.name, work: this.work, schemecolor: this.schemecolor, paid: this.paid,
      kind: this.kind, systemKind: this.systemKind, affinityId: this.affinity, requiresLog:this.requiresLog };
  }
}

/**
 * @param {TaskTypeDto} dto
 */
function getSchemeColor(dto) {
  if (dto.schemecolor !== null)
    return dto.schemecolor;
  if (dto.systemKind === TASK_SYSTEM_KIND.free) {
    return 'free'
  }
  return 'lightinfo';
}
