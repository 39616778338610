export class LoginInfo {

  /**
   * @param {{ timestamp?: number;accessToken: string; refreshToken?: string; customerSchema?: string; loginKind?:number; isAgent?: boolean; }} data
   */
  constructor(data) {
    /** @type {string} */
    this._accessToken = data.accessToken;
    /** @type {string} */
    this._refreshToken = data.refreshToken;
    /** @private @type {number} */
    this.timestamp = data.timestamp !== undefined ? data.timestamp : Date.now();
    /** @type {string} */
    this.customerSchema = data.customerSchema !== undefined ? data.customerSchema : '';
    /** @private @type {number} */
    this._loginKind = data.loginKind !== undefined ? data.loginKind : LOGIN_KIND.normal;
    if (data.isAgent)
      this._loginKind = LOGIN_KIND.agent;
  }

  get accessToken() {
    return this._accessToken;
  }

  get refreshToken() {
    return this._refreshToken;
  }

  get isAgent() {
    return this._loginKind === LOGIN_KIND.agent;
  }

  get isSuperAdmin() {
    return this._loginKind === LOGIN_KIND.superAdmin;
  }

  /**
   * @param {{ isSuperAdmin: boolean; isAgent: boolean; }} currentUser
   */
  setLoginKind(currentUser) {
    if (currentUser.isSuperAdmin) {
      this._loginKind = LOGIN_KIND.superAdmin;
    } else if (currentUser.isAgent) {
      this._loginKind = LOGIN_KIND.agent;
    } else {
      this._loginKind = LOGIN_KIND.normal;
    }
  }

  needRefresh() {
    if (!this._refreshToken) { return false; }
    return Date.now() - this.timestamp > 1000 * 60 * 60 * 22;
  }

  /**
   * @param {{ accessToken: string; refreshToken: string; }} authResult
   */
  setAuthRefreshResult(authResult) {
    this.timestamp = Date.now();
    this._accessToken = authResult.accessToken;
    this._refreshToken = authResult.refreshToken;
  }

  toStore() {
    return { accessToken: this.accessToken, refreshToken: this.refreshToken,
      customerSchema: this.customerSchema, loginKind: this._loginKind, timestamp: this.timestamp };
  }
}

const LOGIN_KIND = createLoginKindEnum();

function createLoginKindEnum() {
  const enumObject = {
    normal: 0,
    agent: 1,
    superAdmin: 2,
  };

  return Object.freeze(enumObject);
}