import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/**
* @typedef {{
*  employeeCallcenters:import("@/model/dn-employee-cc").EmployeeCallcenterRow[];
*  tasktypes:import("@/model/dn-tasktype.js").TaskTypeDto[];
*  tags:import("@/model/dn-tag.js").TagDto[];
* }} InitialData
*/

/** @returns {Promise<InitialData>} */
export async function getInitialData() {
  return await fetchAndCheckJson(`initial-data`, 'GET');
}