import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/**
* @typedef {{ id: number; name: string; description: string; tagIds: number[]; }} CallCenterDto
*/

/**
 * @param {CallCenter} c
 */
async function deleteCallCenter(c) {
  await fetchAndCheckJson(`callcenters/${c.id}`, 'DELETE');
}

export async function getCallCenters() {
  /** @type {CallCenterDto[]} */
  const rows = await fetchAndCheckJson(`callcenters`, 'GET');
  return rows.map(r => new CallCenter(r.id, r.name, r.description, r.tagIds));
}

/**
 * @param {CallCenter} c
 */
async function saveCallCenter(c) {
  const id = await fetchAndCheckJson(`callcenters`, 'POST', c.toApi());
  c.id = id;
}

/**
 * @param {CallCenter[]} callCenters
 */
export async function saveCallCenterList(callCenters) {
  for (const c of callCenters) {
    if (c.isToDelete) {
      await deleteCallCenter(c);
    } else if (c.hasChanges) {
      await saveCallCenter(c);
      c.confirmChanges();
    }
  }
}

export class CallCenter {
  /**
   * @param {number} id
   * @param {string} name
   * @param {string} description
   * @param {number[]} tagIds
   */
  constructor(id, name, description = '', tagIds = []) {
    /** @private @type {number} */
    this._id = id;
    /** @private @type {string} */
    this._name = name;
    /** @type {number[]} */
    this.tagIds = tagIds;
    /** @private @type {string} */
    this._description = description;
    this._hasChanges = id === -1;
    this._isToDelete = false;
    this._imgUrl = "building_primarydark.svg"
  }
  get id() {
    return this._id
  }

  set id(newValue) {
    this._id = newValue
  }

  get name() {
    return this._name
  }
  set name(newValue) {
    this._name = newValue
    this._hasChanges = true
  }

  get description() {
    return this._description
  }
  set description(newValue) {
    this._description = newValue
    this._hasChanges = true
  }

  get imgUrl() {
    return this._imgUrl
  }
  set imgUrl(newValue) {
    this._imgUrl = newValue
    this._hasChanges = true
  }
  get hasChanges() {
    return this._hasChanges
  }
  confirmChanges() { this._hasChanges = false }
  markAsHasChanges() {
    this._hasChanges = true;
  }

  get isToDelete() {
    return this._isToDelete
  }
  toDelete() { this._isToDelete = true }

  toApi() {
    return { id: this.id, name: this.name, description: this.description, tagIds: this.tagIds };
  }

  tostring() {
    return 'id: ' + this._id + ' name: ' + this._name + ' description: ' + this._description
  }
}
