/**
* @typedef {{ id: number; ccid:number; name:string; description?:string; affinityId?:number|null; optimizationPriority?:number|null;}} SkillDto
*/

export class Skill {
  /**
   * @param {SkillDto} dto
   */
  constructor(dto) {
    /** @private @type {number} */
    this._id = dto.id;
    /** @private @type {string} */
    this._name = dto.name;
    /** @private @type {string} */
    this._description = dto.description ? dto.description : null;
    /** @private @type {number} */
    this._optimizationPriority = dto.optimizationPriority ? dto.optimizationPriority : null;
    /** @private @type {number} */
    this._contactCenterId = dto.ccid;
    /** @private @type {number|null} */
    this._affinity = dto.affinityId ? dto.affinityId : null;
    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @private @type {boolean} */
    this._isToDelete = false;
  }

  get id() {
    return this._id;
  }

  get numericSort() {
    let x = parseInt(this._name)
    return '00000000'.slice(0, 8 - x.toString().length) + x.toString() + this._name.slice(x.toString().length)
  }

  get affinity() {
    return this._affinity;
  }

  set affinity(newValue) {
    this._affinity = newValue;
    this.markAsHasChanges();
  }

  // should only be called if the affinity has been removed on the server.
  clearAffinity() {
    this._affinity = null;
  }

  get name() {
    return this._name;
  }

  set name(newValue) {
    this._name = newValue;
    this.markAsHasChanges();
  }

  get description() {
    return this._description;
  }

  set description(newValue) {
    this._description = newValue;
    this.markAsHasChanges();
  }

  get optimizationPriority() {
    return this._optimizationPriority ? this._optimizationPriority : 1;
  }

  set optimizationPriority(newValue) {
    const v = Number(newValue);
    if (Number.isNaN(v) || v < 1) { return; }
    this._optimizationPriority = Math.round(v);
    this.markAsHasChanges();
  }

  get contactCenterId() {
    return this._contactCenterId
  }

  set contactCenterId(newValue) {
    this._contactCenterId = newValue;
    this.markAsHasChanges();
  }

  get hasChanges() {
    return this._hasChanges;
  }

  /**
   * @param {{ id: number; }} result
   */
  confirmChanges(result) {
    this._id = result.id;
    this._hasChanges = false;
  }

  markAsHasChanges() {
    this._hasChanges = true;
  }

  get isToDelete() {
    return this._isToDelete;
  }

  toDelete() {
    this._isToDelete = true;
    this._hasChanges = this.id >= 0;
  }

  /** @returns {SkillDto} */
  toDto() {
    const id = this.id >= 0 ? this.id : undefined;
    return {
      id,
      ccid: this.contactCenterId,
      description: this.description,
      name: this.name,
      affinityId: this.affinity,
      optimizationPriority: this._optimizationPriority,
    };
  }
}