import { defineStore } from 'pinia';

export const useReportStore = defineStore('report', {
  state: () => ({
    reportOptions: {
      reportDatePicker: new Date(0),
      reportTimeRangePicker: [],
      selectedEmp: null,
      reportKind: 0,
      reportCalendarDays: false,
      lastSortOptionKey: null,
      selectedTasktype:null,
      reportFilter:[],
      numHoursWeeklyOvertime: null,
      weekendAsHoliday: false
    },
  })
});
