<template>

<div>
    <Avatar :colorId="colorId" :initials="initials"></Avatar>
    <div class="flex" style="margin-top:20px">
        <div class="flex-column">
            <label>{{$t('settings.color')}}</label>
            <PvDropdown v-model="colorName"
                      :scrollHeight="'100%'"
                      :options="colors"
                      panelClass="color-selector">
                <template #value="slotProps">
                    <ColorIndicator v-bind:schemecolor="colorName"></ColorIndicator>
                </template>
                <template #option="slotProps">
                    <ColorIndicator v-bind:schemecolor="slotProps.option" class="color-options"></ColorIndicator>
                </template>
            </PvDropdown>
        </div>
        <div class="flex-column">
            <label>{{$t('settings.initials')}}</label>
            <InputText type="text" v-model="initials"></InputText>
        </div>
    </div>
</div>
</template>

<script>
import * as dn_uiHelper from "@/js/dn-uihelper.js";
import RawData from "@/components/Base/RawData.vue";
import Avatar from "@/components/Base/Avatar.vue";
import ColorIndicator from "@/components/Settings/ColorIndicator.vue";

import { COLORS,getSelectableColors } from '@/js/dn-colors.js';



export default {
// @ts-ignore
components: { RawData,Avatar,ColorIndicator },
props: {
user: { type: Object },
},
data() {
return {
 initials:"NN",
 colorName:null,
 colors:getSelectableColors(),
};
},
computed: {
colorId(){return COLORS.idBySchemecolor(this.colorName)}

},
methods: {

},
created() {
    if (!this.user.initials||this.user.initials==undefined) {this.initials=dn_uiHelper.suggestUserInitials(this.user)}else{this.initials=this.user.initials}
    if (!this.user.colorId) {this.colorName=COLORS.byId(1)} else {this.colorName=COLORS.byId(this.user.colorId)}
},
mounted(){},
watch: {
}
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.module.scss";
:deep(.p-dropdown){
    border: none;
    box-shadow: none;
}


</style>