
import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/**
 * @typedef {{employeeSwitchId:string, kind:number, timestamp:string, reasonCode?:string }} AgentEventDto
 */

/**
 * @param {Date} st
 * @param {Date} fi
 */
export async function deleteAgentEvents(st, fi) {
  const agentEvents = await fetchAndCheckJson(`agent-event?st=${st.toISOString()}&fi=${fi.toISOString()}`, 'DELETE');
  return agentEvents;
}

/**
 * @param {number} limit
 */
export async function getAgentEvents(limit) {
  /** @type {AgentEventDto[]} */
  const agentEvents = await fetchAndCheckJson(`agent-event?limit=${limit}`, 'GET');
  return agentEvents;
}

/**
 * @param {AgentEventDto[]} data
 */
async function postAgentEvents(data) {
  return await fetchAndCheckJson('agent-event', 'POST', data);
}

/**
 * @param {string[]} lines
 * @param {{ (progress: any): void; (arg0: number): void; }} updateProgress
 */
export async function importAgentEventsCsv(lines, updateProgress) {
  let startIndex = 0;
  if (lines[0].toLowerCase().startsWith('timestamp;kind;employeeswitchid'))
    startIndex = 1;

  let data = [];
  for (let i = startIndex; i < lines.length; i++) {
    const rowData = lines[i].split(';');
    if (rowData.length === 1)
      continue;

    if (rowData.length < 3 || rowData.length > 4) {
      throw new Error(`Number of fields error on line ${i}`);
    }

    /** @type {AgentEventDto} */
    const dto = { timestamp: rowData[0].trim(), kind: parseInt(rowData[1].trim()), employeeSwitchId: rowData[2].trim() };
    if (rowData.length > 3 && rowData[3]) {
      const reasonCode = rowData[3].trim();
      if (reasonCode)  {
        dto.reasonCode = reasonCode;
      }
    }

    if (isNaN(Date.parse(dto.timestamp))) {
      throw new Error(`Bad formatting of timestamp on line ${i}`);
    }

    if (dto.kind < 0 || dto.kind > 3) {
      throw new Error(`kind expects 0,1,2 or 3 error on line ${i}`);
    }

    data.push(dto)
  }

  if (data.length === 0)
    throw new Error('No agent events to import found');

  const batchSize = 10000;
  let result;
  const total = data.length;
  let left = data.length;
  do {
    let batch;
    if (left <= batchSize) {
      batch = data;
      left = 0;
    } else {
      batch = data.splice(0, batchSize);
      left = data.length;
    }

    result = await postAgentEvents(batch);
    updateProgress(1 - left / total);
  } while (left > 0);

  return result;
}