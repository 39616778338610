import { fetchAndCheckJson } from '@/js/dn-fetch.js';


export async function getDayTypes() {
  /** @type {{id:number;name:string;schemecolor:string;system:boolean}[]} */
  const result = await fetchAndCheckJson('daytypes', 'GET');
  return result.map(x => new DayType(null, x.id, x.name, x.schemecolor, x.system)).sort(function (a, b) {
    return b.system - a.system;
  });
}

/**
 * @param {DayType[]} daytypes
 */
export async function postDayTypes(daytypes) {
  return new Promise((resolve) => {
    let itemsToDelete = []
    let promesis = []
    for (let i = 0; i < daytypes.length; i++) {
      const dayType = daytypes[i];
      if (dayType.isToDelete) {
        promesis.push(fetchAndCheckJson(`daytypes/${dayType.id}`, 'DELETE'))
        itemsToDelete.push(dayType)
      } else if (daytypes[i].hasChanges) {
        if (daytypes[i].id == -1) {
          promesis.push(fetchAndCheckJson(`daytypes`, 'POST', { name: dayType.name, schemecolor: dayType.schemecolor }).then(x => dayType.id = x));
        } else {
          promesis.push(fetchAndCheckJson(`daytypes/${dayType.id}`, 'PUT', { name: dayType.name, schemecolor: dayType.schemecolor }));
        }

        dayType.confirmChanges();
      }
    }
    for (let i = 0; i < itemsToDelete.length; i++) {
      var index = daytypes.indexOf(itemsToDelete[i]);
      daytypes.splice(index, 1);
    }
    Promise.all(promesis).then(() => {
      resolve(daytypes);
    }).catch(error => {
      console.error(error.message)
    });
  });
}

export class DayType {
  constructor(daytypes,id,name,schemecolor,system){
    if (daytypes){
      this._id = daytypes.id
      this._name = daytypes.name
      this._schemecolor = daytypes.schemecolor
      this._system = daytypes.system
    }
    else{
      this._id = id
      this._name = name
      this._schemecolor = schemecolor
      this._system = system
    }
    this._hasChanges = false
    this._isToDelete = false
  }

  get id() {
    return this._id
  }

  set id(newValue) {
    this._id = newValue
  }

  get system() {
    return this._system
  }

  set system(newValue) {
    this._system = newValue
    this._hasChanges = true
  }

  get name(){
    return this._name
  }

  set name(newValue){
    this._name = newValue
    this._hasChanges = true
  }

  get schemecolor(){
    return this._schemecolor
  }

  set schemecolor(newValue){
    this._schemecolor = newValue
    this._hasChanges = true
  }

  get hasChanges() {
    return this._hasChanges
    }
    confirmChanges(){this._hasChanges=false}

  get isToDelete() {
  return this._isToDelete
  }
  toDelete(){this._isToDelete=true}

  tostring(){
    return 'name:' + this._name + ' schemecolor: '+ this._schemecolor + 'system:' + this._system
    }
}