<template>
  <span
    class=""
    :class="{ buttonRole: role === 'button' }"
    style="{ width: sizePx, height: sizePx }"
  >
    <!-- <img v-if="iconSrc" :src="iconSrc" svg-inline /> -->
    <InlineSvg
      :src="iconSrc"
      :width="size"
      :height="size"
      class="svg"
    ></InlineSvg>
  </span>
</template>
<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "Icon",
  components: { InlineSvg },
  data() {
    return {
      icons: {
        add: require("@/assets/images/icons/add.svg"),
        arrow_back: require("@/assets/images/icons/arrow_back.svg"),
        arrows_collapse_vertical: require("@/assets/images/icons/arrows-collapse-vertical.svg"),
        arrows_expand_vertical: require("@/assets/images/icons/arrows-expand-vertical.svg"),
        arrow_down: require("@/assets/images/icons/arrow_down.svg"),
        arrow_forward: require("@/assets/images/icons/arrow_forward.svg"),
        arrow_up: require("@/assets/images/icons/arrow_up.svg"),
        arrow_left_right: require("@/assets/images/icons/arrow-left-right.svg"),
        arrow_right_short: require("@/assets/images/icons/arrow-right-short.svg"),
        arrow_right: require("@/assets/images/icons/arrow-right.svg"),
        arrow_left: require("@/assets/images/icons/arrow-left.svg"),
        box_arrow_in_down: require("@/assets/images/icons/box-arrow-in-down.svg"),
        box_arrow_up: require("@/assets/images/icons/box-arrow-up.svg"),
        building: require("@/assets/images/icons/building.svg"),
        calendar4_range: require("@/assets/images/icons/calendar4-range.svg"),
        chat_left: require("@/assets/images/icons/chat_left.svg"),
        chat_check2_square: require("@/assets/images/icons/check2-square.svg"),
        chevron_up: require("@/assets/images/icons/chevron_up.svg"),
        chevron_down: require("@/assets/images/icons/chevron_down.svg"),
        chevron_left: require("@/assets/images/icons/chevron-left.svg"),
        chevron_right: require("@/assets/images/icons/chevron-right.svg"),
        clock:require("@/assets/images/icons/clock.svg"),
        clock30:require("@/assets/images/icons/clock_grey30.svg"),
        close: require("@/assets/images/icons/close.svg"),
        cup: require("@/assets/images/icons/cup.svg"),
        delete: require("@/assets/images/icons/delete.svg"),
        deletex: require("@/assets/images/icons/x-circle.svg"),
        columns: require("@/assets/images/icons/columns.svg"),
        select: require("@/assets/images/icons/select.svg"),
        gear:require("@/assets/images/icons/gear.svg"),
        grid:require("@/assets/images/icons/grid-3x3-gap.svg"),        
        checkmark: require("@/assets/images/icons/checkmark.svg"),
        filter: require("@/assets/images/icons/filter.svg"),
        free: require("@/assets/images/icons/calendar-x.svg"),
        friendlyGhost:require("@/assets/images/icons/friendly-ghost.svg"),
        list:require("@/assets/images/icons/list.svg"),
        list30:require("@/assets/images/icons/list_grey30.svg"),
        meter: require("@/assets/images/icons/meter.svg"),
        meter_temp: require("@/assets/images/icons/meter_temp.svg"),
        more: require("@/assets/images/icons/more.svg"),
        edit: require("@/assets/images/icons/edit.svg"),
        pin: require("@/assets/images/icons/pin.svg"),
        pin_angel: require("@/assets/images/icons/pin-angle.svg"),
        phone: require("@/assets/images/icons/phone.svg"),
        plus: require("@/assets/images/icons/plus.svg"),
        search: require("@/assets/images/icons/search.svg"),
        sick: require("@/assets/images/icons/emoji-dizzy-color.svg"),
        sick_white: require("@/assets/images/icons/emoji-dizzy.svg"),
        sick_thin: require("@/assets/images/icons/emoji_dizzy_color_thin.svg"),
        star: require("@/assets/images/icons/star_color.svg"),
        //swap: require("@/assets/images/icons/emoji-laughing-swap.svg"),
        swap: require("@/assets/images/icons/arrow-left-right.svg"),
        compare_filled: require("@/assets/images/icons/bottom_menu_bar/compare_filled.svg"),
        compare_outline: require("@/assets/images/icons/bottom_menu_bar/compare_outline.svg"),
        trade: require("@/assets/images/icons/bottom_menu_bar/arrow-repeat.svg"),
        clock: require("@/assets/images/icons/bottom_menu_bar/clock.svg"),
        portfolio_filled: require("@/assets/images/icons/bottom_menu_bar/portfolio_filled.svg"),
        portfolio_outline: require("@/assets/images/icons/bottom_menu_bar/portfolio_outline.svg"),
        user: require("@/assets/images/icons/bottom_menu_bar/person.svg"),
        user_outline: require("@/assets/images/icons/bottom_menu_bar/user_outline.svg"),
        upload: require("@/assets/images/icons/upload.svg"),
        update: require("@/assets/images/icons/update.svg"),
        vacation_thin: require("@/assets/images/icons/emoji_sunglasses_color2_thin.svg"),
        vacation: require("@/assets/images/icons/emoji-sunglasses-color2.svg"),
        vacation_white: require("@/assets/images/icons/emoji-sunglasses.svg"),
        work: require("@/assets/images/icons/emoji-laughing.svg"),
        work_sad: require("@/assets/images/icons/emoji-frown.svg"),
        work_glad: require("@/assets/images/icons/emoji-wink.svg"),
        hand_thumbs_down: require("@/assets/images/icons/hand-thumbs-down.svg"),
        hand_thumbs_up: require("@/assets/images/icons/hand-thumbs-up.svg"),
        patch_question: require("@/assets/images/icons/patch-question.svg"),
        schedule_edit: require("@/assets/images/icons/bar-chart-steps.svg"),
        log_edit: require("@/assets/images/icons/stopwatch.svg"),
        
      },
    };
  },
  props: {
    name: { type: String, required: true },
    size: { type: String, required: false, default: "30" },
    role: { type: String, required: false, default: "icon" },
  },
  computed: {
    iconSrc() {
      return this.icons[this.name];
    },
    sizePx() {
      return `${this.size}px`;
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/css/variables.module.scss";

.anser-icon {
  width: 32px;
  height: 32px;

  &.buttonRole {
    cursor: pointer;
    &:hover {
      :deep(.svg) {
        path {
          fill: $primary;
        }
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  :deep(.svg) {
    path {
      fill: $dark;
    }
    g {
      stroke: none !important;
    }
  }
}
</style>
