import variables from "@/assets/css/variables.module.scss";
// @ts-check
import { COLORS } from "./dn-colors";

/**
 * @param {string} schemecolor
 */
export function getColorFromName(schemecolor) {
  /** @type {string} */
  let returnColor = variables.stroke1
  switch (schemecolor) {
    case COLORS.stroke1:
      returnColor = variables.stroke1;
      break;
    case COLORS.stroke2:
      returnColor = variables.stroke2;
      break;
    case COLORS.stroke3:
      returnColor = variables.stroke3;
      break;
    case COLORS.stroke4:
      returnColor = variables.stroke4;
      break;
    case COLORS.stroke5:
      returnColor = variables.stroke5;
      break;
    case COLORS.stroke6:
      returnColor = variables.stroke6;
      break;
    case COLORS.stroke7:
      returnColor = variables.stroke7;
      break;
    case COLORS.stroke8:
      returnColor = variables.stroke8;
      break;
    case COLORS.stroke9:
      returnColor = variables.stroke9;
      break;
    case COLORS.primary:
      returnColor = variables.primary;
      break;
    case COLORS.lightinfo:
      returnColor = variables.lightinfo;
      break;
    case COLORS.lightdanger:
      returnColor = variables.lightdanger;
      break;
    case 'light':
      returnColor = variables.light;
      break;
    case 'grey':
      returnColor = variables.grey;
      break;
    case 'dark':
      returnColor = variables.dark;
      break;
    case 'warning':
      returnColor = variables.warning;
      break;
    case 'success':
      returnColor = variables.success;
      break;
    case 'danger':
      returnColor = variables.danger;
      break;
    case 'free':
      returnColor = variables.free;
      break;
    default:
      returnColor = variables.primary;
      break;
  }

  return returnColor
}