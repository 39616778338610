<template>
    <PvDialog v-bind:visible="active" aria-role="dialog" modal
              v-on:close="$emit('close')"
              :header="title"
              @update:visible="$emit('close')">
        <div class="modal-card" style="width: auto">
            <section class="modal-card-body">
                <slot name="body"></slot>
            </section>
            <footer class="modal-card-foot" v-if="$slots.footer">
                <slot name="footer"></slot>
            </footer>
        </div>
    </PvDialog>
</template>

<script>
import Icon from "./Icon.vue";
export default {
  components: { Icon },
  props: {
    title: { type: String, required: false },
    active: { type: Boolean, required: true },
  },
  data() {
    return {};
  },
  computed: {
  },
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/variables.module.scss";
.modal-card-head {
  border-bottom: none;
  background: #fff;
  justify-content: space-between;
  font-size: $fontMid;
}
.modal-card-body {
  min-width: 630px;
  min-height: 250px;
}
.modal-card-foot {
  border-top: none;
  background: #fff;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: $mobileBreakPoint) {
  .modal-card-body {
    min-width: auto;
  }
}
</style>
