import { fetchAndCheckJson } from '@/js/dn-fetch.js';

const secondsPer5Min = 60 * 5;

/**
 * @typedef {{
 * id: number;
 * minShiftDuration: number;
 * maxShiftDuration: number;
 * minWorkDuration: number;
 * tagId: number;
 * tasks: {tasktypeId: number, duration:number}[]
 * }} BreakSettingsDto
 */

/**
 * @param {number} id
 */
export async function deleteBreakSettings(id) {
  return await fetchAndCheckJson(`break-settings/${id}`, 'DELETE');
}

/**
 * @return {Promise<BreakSettings[]>}
 */
export async function getBreakSettings() {
  return await fetchAndCheckJson('break-settings', 'GET').then(x => x.map(breakSettingsFromDto));
}

/**
 * @param {BreakSettings} bs
 */
export async function saveBreakSettings(bs) {
  let path = `break-settings`;
  if (bs.id < 0)
    return await fetchAndCheckJson(path, 'POST', bs.toDto()).then(x => {
      bs.id = x.id;
      bs.confirmChanges();
    });
  else
    return await fetchAndCheckJson(path + '/' + bs.id, 'PATCH', bs.toDto()).
      then(() => bs.confirmChanges());
}

export class BreakSettings {
  /**
   * @param {number} id
   * @param {number} minShiftDuration
   * @param {number} maxShiftDuration
   * @param {number} minWorkDuration
   * @param {Array<BreakSettingsTask>} tasks
   * @param {number|null} tagId
   */
  constructor(id, minShiftDuration, maxShiftDuration, minWorkDuration, tasks, tagId = null) {
    /** @type {number} values below 0 is interpreted as new break settings */
    this.id = id;
    /** @private @type {number} inclusive min shift length in 5 minute intervals */
    this._minShiftDuration = minShiftDuration;
    /** @private @type {number} inclusive max shift length in 5 minute intervals */
    this._maxShiftDuration = maxShiftDuration;
    /** @private @type {number} min work duration in 5 minute intervals */
    this._minWorkDuration = minWorkDuration;
    /** @private @type {Array<BreakSettingsTask>} */
    this._tasks = tasks;
    /** @private @type {number|null} */
    this._tagId = tagId;
    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @private @type {boolean} */
    this._isToDelete = false;
    /** @private @type {number|null} */
    this._unpaidDurationMinutes = null;
  }

  get minShiftDuration() {
    return this._minShiftDuration;
  }

  get minShiftDurationMinutes() {
    return 5 * this._minShiftDuration;
  }

  get minShiftDurationDate() {
    return unitToDate(this.minShiftDuration);
  }

  /**
  * @param {Date} newValue
  */
  set minShiftDurationDate(newValue) {
    this._minShiftDuration = dateToUnit(newValue);
    this.markAsHasChanges();
  }

  get maxShiftDuration() {
    return this._maxShiftDuration;
  }

  get maxShiftDurationMinutes() {
    return 5 * this._maxShiftDuration;
  }

  get maxShiftDurationDate() {
    return unitToDate(this.maxShiftDuration);
  }

  /**
   * @param {Date} newValue
   */
  set maxShiftDurationDate(newValue) {
    this._maxShiftDuration = dateToUnit(newValue)
    this.markAsHasChanges();
  }

  get minWorkDuration() {
    return this._minWorkDuration;
  }

  get minWorkDurationQ() {
    return Math.ceil(this._minWorkDuration / 3);
  }

  get minWorkDurationDate() {
    return unitToDate(this._minWorkDuration);
  }

  set minWorkDurationDate(newValue) {
    this._minWorkDuration = dateToUnit(newValue);
    this.markAsHasChanges();
  }

  get tagId() {
    return this._tagId;
  }
  set tagId(newValue) {
    this.markAsHasChanges();
    this._tagId = newValue
  }

  get tagIdSingle() {
    if (this.tagId === null)
      return [];
    return [this.tagId];
  }
  set tagIdSingle(newValue) {
    if (newValue.length === 0)
      this.tagId = null;
    else
      this.tagId = newValue[0];
  }

  get tasks() {
    return this._tasks;
  }

  set tasks(newValue) {
    this.markAsHasChanges();
    this._tasks = newValue
  }

  get hasChanges() {
    return this._hasChanges;
  }

  markAsHasChanges() {
    this._hasChanges = true;
  }

  confirmChanges() {
    this._hasChanges = false;
  }

  /**
   * Get average length in minutes betwwen breaks
   * @param {number} shiftLength shift length in minutes
   * @return {number}
   */
  avgWorkDuration(shiftLength) {
    const workIntervalCount = this.tasks.length + 1;
    let totalBreakDuration = 0;
    for (const bsTask of this.tasks) {
      totalBreakDuration += bsTask.duration;
    }

    return (shiftLength - 5 * totalBreakDuration) / workIntervalCount;
  }

  /**
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
   */
  calculateUnpaidDuration(taskTypeMap) {
    let unpaidDuration = 0;
    for (const t of this.tasks) {
      const tt = taskTypeMap.get(t.ttId);
      if (!tt.paidBreak) {
        unpaidDuration += t.duration;
      }
    }
    this._unpaidDurationMinutes = 5 * unpaidDuration;
  }

  get unpaidDurationMinutes() {
    return this._unpaidDurationMinutes;
  }

  get isToDelete() {
    return this._isToDelete;
  }

  get isMinMaxDurationOk() {
    return this.maxShiftDuration >= this.minShiftDuration;
  }

  get isValid() {
    if (this.isToDelete)
      return true;
    return this.isMinMaxDurationOk;
  }

  minPossibleDuration() {
    let sum = this.minWorkDuration;
    for (const t of this.tasks) {
      sum += this.minWorkDuration + t.duration;
    }

    return sum;
  }

  toDelete() {
    this._isToDelete = true
  }

  /**
   * @return {BreakSettingsDto}
   */
  toDto() {
    return {
      id: this.id,
      minShiftDuration: this.minShiftDuration * secondsPer5Min,
      maxShiftDuration: this.maxShiftDuration * secondsPer5Min,
      minWorkDuration: this.minWorkDuration * secondsPer5Min,
      tagId: this.tagId,
      tasks: this.tasks.map(x => x.toDto())
    };
  }
}

export class BreakSettingsTask {
  /**
   * @param {number} ttId
   * @param {number} duration
   */
  constructor(ttId, duration) {
    /** @private @type {number} */
    this._ttId = ttId;
    /** @private @type {number} duration in 5 minute intervals*/
    this._duration = duration;
  }

  get duration() {
    return this._duration;
  }

  get durationDate() {
    return unitToDate(this._duration);
  }

  set durationDate(newValue) {
    this._duration = dateToUnit(newValue)
  }

  get ttId() {
    return this._ttId;
  }

  set ttId(newValue) {
    this._ttId = newValue
  }

  toDto() {
    return { tasktypeId: this.ttId, duration: this.duration * secondsPer5Min };
  }
}


/**
 * @param {BreakSettingsDto} bs
 */
export function breakSettingsFromDto(bs) {
  const bsTasks = [];
  for (const bsTask of bs.tasks) {
    bsTasks.push(new BreakSettingsTask(bsTask.tasktypeId, bsTask.duration / secondsPer5Min));
  }

  return new BreakSettings(bs.id, bs.minShiftDuration / secondsPer5Min,
    bs.maxShiftDuration / secondsPer5Min, bs.minWorkDuration / secondsPer5Min, bsTasks, bs.tagId);
}

/**
 * @param {BreakSettings[]} settings
 * @param {import("@/model/dn-employee").Employee} employee
 * @param {number[]} ccTagIdList
 */
export function getBreakSettingsByTag(settings, employee, ccTagIdList) {
  let filtered = filterByTag(employee.tagIds);
  if (filtered.length !== 0)
    return filtered;
  filtered = filterByTag(ccTagIdList);
  if (filtered.length !== 0)
    return filtered;

  return settings.filter(x => x.tagId === null);

  /**
   * @param { number[] } tagIds
   */
  function filterByTag(tagIds) {
    if (tagIds.length === 0)
      return [];

    return settings.filter(x => x.tagId !== null && tagIds.includes(x.tagId))
  }
}

/**
 * @param {number} unit
 */
function unitToDate(unit) {
  const minutes = (unit % 12) * 5;
  const hours = Math.floor(unit / 12);
  return new Date(2030, 1, 1, hours, minutes);
}

/**
 * @param {Date} time
 */
function dateToUnit(time) {
  return time.getHours() * 12 + time.getMinutes() / 5;
}
