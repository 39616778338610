<template>
  <div class="footer-wrapper">
    <MobileNavbarFooter />
  </div>
</template>

<script>
import MobileNavbarFooter from "../Navigation/MobileNavbarFooter.vue";

export default {
  name: "Footer",
  components: { MobileNavbarFooter },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() { },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.module.scss";

.footer-wrapper {
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 10;

  footer {
    text-align: center;
  }
}

@media screen and (max-width: $mobileBreakPoint) {
  .footer-wrapper {
    position: fixed;
    bottom: 0;
  }
}
</style>
