<template>

        <div v-if="userGuide.display" class="tile is-vertical guideLineTile" id="main-tile">
            <article class="tile is-child notification guide-area">
                <div class="content">
                    <section>
                        <span class="header">User guide</span>
                        <IconField iconPosition="left"  class="search-bar">
                            <InputIcon class="pi pi-search"></InputIcon>
                            <InputText v-model="searchText" placeholder="Search" style="width:100%" />
                            <InputIcon class="pi pi-times clear-search" @click="searchText=''"></InputIcon>
                        </IconField>
                        <PvButton class="button" icon="pi pi-times" severity="danger" @click="userGuide.display=false"/>
                    </section>

                    <section>
                        <ScrollPanel style="height:calc(100vh - 12.5rem)   ">
                            <Accordion
                            v-model:activeIndex="activeIndex"
                            expandIcon="null"
                            collapseIcon="null"
                            @tab-click="setTab"
                          >
                            <AccordionTab
                                v-for="(item, index) of filteredGuideItems"
                                :pt="{
                                root:{
                                    style: {
                                        marginBottom: '1px',
                                        fontFamily: 'proxima-nova, sans-serif'
                                    }
                                },
                                header: ({ props, parent }) => ({
                                    class: hideAccordionTab(props, parent, index)
                                }),
                                toggleableContent: ({ props, parent }) => ({
                                    class: hideAccordionTab(props, parent, index)
                                })
                            }">
                                <template #header>
                                    <div class="header-item">
                                        <span class="card-header-title">{{ item.title }}</span>
                                        <div class="header-right">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                                <path v-if="activeIndex===index" d="m212-372 268-267 268 267H212Z"/>
                                                <path v-else d="M480-322 212-589h536L480-322Z"/>
                                            </svg>
                                            <!--                                        <i class="pi" :class="activeIndex===index ? 'pi-caret-down' : 'pi-caret-up'"/>-->
                                            <i class="pi pi-external-link" @click="openDialog(index, item)"/>
                                        </div>
                                    </div>
                                </template>
                                <template #default>
                                    <div class="card-content">
                                        <div class="content">
                                            <div v-for="(step, index) of item.steps" v-bind:key="index">
                                                <span class="step-number">{{ index + 1 }}:</span> <span>{{
                                                    step.text
                                                }}</span>
                                                <span v-if="step.ref.length" class="step-ref" v-tooltip.left="{ value: step.ref}">i</span>
                                                <span v-if="step.refMenu" class="menu-ref">{{ step.refMenu }}</span>
                                                <span v-if="step.link">
                                <InlineSvg :src="videoSrc" :width="32" :height="32" class="inline-svg"
                                           @click="followLink(step.link)"></InlineSvg>
                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </AccordionTab>
                        </Accordion>
                        </ScrollPanel>
                    </section>
                </div>
            </article>
        </div>
    <PvDialog v-model:visible="dialogVisible"
            :header="popupData.title"
            :style="{ width: '25%'}"
            :pt="{
                header: {
                    style: {
                        cursor: 'pointer'
                    }
                }
            }">
        <div class="card-content">
            <div class="content">
                <div v-for="(step, index) of popupData.steps" v-bind:key="index">
                    <span class="step-number">{{ index + 1 }}:</span> <span>{{ step.text }}</span>
                    <span v-if="step.ref.length" class="step-ref" v-tooltip.left="{ value: step.ref,
                                               pt: {
                                                  arrow: {
                                                      style: {
                                                          borderLeftColor: 'var(--primary-color)'
                                                      }
                                                  },
                                                  text: {
                                                    style: {
                                                          backgroundColor: 'var(--primary-color)!important',
                                                          color: 'var(--primary-color-text)!important',
                                                          fontSize: '0.85rem',
                                                          padding: '0.35rem 0.75rem',
                                                          borderRadius: '6px',
                                                          fontWeight: '400',
                                                          // whiteSpace: 'nowrap'
                                                      }
                                                  }
                                              }
                                              }">i</span>
                    <span v-if="step.refMenu" class="menu-ref">{{ step.refMenu }}</span>
                    <span v-if="step.link">
                                <InlineSvg :src="videoSrc" :width="32" :height="32" class="inline-svg"
                                           @click="followLink(step.link)"></InlineSvg>
                            </span>
                </div>
            </div>
        </div>
    </PvDialog>
</template>
<script>
import {useDataStore} from "@/stores/dataStore.js";
import dnUserguides from "@/jso/dn-user-guides.json";
import InlineSvg from "vue-inline-svg";

export default {
    // @ts-ignore
    data() {
        return {
            searchText: '',
            videoSrc: require("@/assets/images/icons/youtube.svg"),
            activeIndex: null,
            activePopupTab: null,
            dialogVisible: false,
            currentTab: null,
            popupData: {},
        }
    },
    components: {InlineSvg},
    computed: {
        filteredGuideItems() {
            return dnUserguides.filter(this.guideFilter)
        },
        userGuide() {
            return useDataStore().userGuide;
        },
    },
    methods: {

        guideFilter(item) {
            let searchOK = this.searchText.length <= 2
            if (!searchOK) {
                if (item.title.toLowerCase().includes(this.searchText.toLowerCase())) {
                    searchOK = true
                }
            }
            if (!searchOK) {
                for (let i = 0; i < item.steps.length; i++) {
                    if (item.steps[i].text.toLowerCase().includes(this.searchText.toLowerCase())) {
                        searchOK = true
                        break
                    }
                }
            }
            return searchOK
        },
        followLink(link) {
            window.open(link, '_blank');
        },
        openDialog(index, popupData) {
            this.activePopupTab = index;
            this.dialogVisible = true;
            this.popupData = popupData;
        },
        setTab(data) {
            this.currentTab = data.index;
        },
        hideAccordionTab(props, parent, index) {
            if (this.dialogVisible && this.activePopupTab === index) {
                if (this.currentTab === this.activePopupTab) {
                    this.activeIndex = null;
                    parent.state.d_activeIndex = null;
                }
                return [
                    {
                        'hide-tab': true
                    }
                ];
            }

        }

    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/variables.module.scss";


.guide-area {
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 4px !important;
    background-color: $cold !important;
    border-radius: 3px;
    position: relative;
    height: 100%;
}

.tooltip-guide {
    width: auto;
    padding: 0.35rem 0.75rem;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 400;
    -webkit-box-shadow: 0px 1px 2px 1px rgba(0, 1, 0, 0.2);
    box-shadow: 0px 1px 2px 1px rgba(0, 1, 0, 0.2);
    z-index: 38;
    white-space: nowrap;
    position: absolute;
}

:deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
    display: flex;
    padding: 0;
    border-bottom: none;
    text-decoration: none;
}


:deep(.p-accordion .hide-tab) {
    display: none;
}

.card-header-title {
    margin: 0px 0px 0px 4px !important;
    padding: 0px 0px 0px 12px;
}

.card-content {
    margin: 0px -8px 6px 4px !important;
    padding: 6px 6px 6px 4px;
}

.card-header-icon {
    padding: 6px 6px 6px 4px;
}

.button {
    background-color: #fff;
    color: #e73542;
    border-radius: 2px;
    position: absolute;
    margin: 4px;
    right: 2px;
    top: 4px;
    height: 2rem;
    width: 2rem;
    border-width: 1px;
    cursor: pointer;

}

.button:hover {
    background-color: #f2f2f2;
}

.button > :deep(.pi) {
    font-size: 0.75em;
}

.search-bar {
    margin-bottom: 0.75rem;
    width: 100%;
    display: flex;
}
.clear-search {
    display: block;
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
    padding: 10px;
    background: $white;
    color: rgba(54, 54, 54, 0.3);
    border-radius: 50%;
    cursor: pointer;
}

.clear-search:hover {
    background: rgba(54, 54, 54, 0.3);
    color: #fff;
}

.content-search {
    padding-bottom: 10px;
    font-size: 1rem;
    height: 2.5rem;
    width: 100%;
}

.content-search:focus {
    outline: none;
    border-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

.content-search:hover {
    border-color: hsl(0, 0%, 71%);
}

.content-search::placeholder, .pi-search {
    color: rgba(54, 54, 54, 0.3);
}

.header-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 6px 6px 6px 0;
    align-items: center;
}

.header-right {
    display: flex;
    align-items: center;
    padding-right: 12px;
}

.header-right .pi-external-link{
    margin-left: 12px;
}


.header-right .pi-external-link:hover {
    color: rgba(54, 54, 54, 0.5);
}

.content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header {
    color: $light;
    margin-bottom: 3px !important;
    font-size: 1.75rem;
}

.closebutton {
    background-color: #fff;
    color: red;
    height: 30px;
    text-align: center;
    line-height: 50%;
}

.step-ref {
    background: $stroke1;
    color: $light;
    border-radius: 16px;
    padding-left: 6px;
    padding-right: 6px;
    font-style: italic;
    font-weight: 600;
    font-size: 12px;
    margin-left: 2px !important;
}

.menu-ref {
    background: $info;
    border-radius: 2px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 12px;
    margin-left: 2px !important;
}

.step-number {
    font-weight: 600;
    padding-left: 2px;
    padding-right: 2px;
}

.inline-svg {
    margin-left: 8px;
    margin-top: -4px;
    margin-bottom: -8px;
}

.floating-guide {
    position: fixed;
    width: calc(calc(100% / 6) - 8px);
    z-index: 1111;
    border: solid #555 1px;
    border-radius: 5px;
    padding: 0 10px;
    background-color: #fff
}

</style>