<template>
  <span :title="isShowTitle?$t('settings.selected-color'):''">
    <div v-bind:style="{ backgroundColor: setColor}" class="dot"></div>
  </span>
</template>

<script>
import { getColorFromName } from '@/js/dn-variables.js';

export default {
  props: {
    schemecolor: { type: String },
    isShowTitle: {type: Boolean, default: false}
  },
  data() {
    return {};
  },
  computed: {
    setColor: function() {
      return getColorFromName(this.schemecolor);
    }
}
}

</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.module.scss";
.dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 4px;

}
</style>