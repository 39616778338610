import { fetchAndCheckJson } from '@/js/dn-fetch.js';

export const IMPORT_CONFIG_KIND = createImportConfigEnum();

/**
 * @param {SwitchConfig[]} switchConfigs
 */
export function addNewSwitchConfig(switchConfigs) {
  let minId = 0;
  for (const sc of switchConfigs) {
    minId = Math.min(sc.id, minId);
  }

  switchConfigs.push(new SwitchConfig({ id: minId - 1, active: true, timezone: 'UTC', importConfigs: [] }));
}

function createImportConfigEnum() {
  const enumObject = {
    startel: 1,
    peoplePraise: 2,
  };

  return Object.freeze(enumObject);
}

export function getImportConfigKinds() {
  /** @type {{id:number, name:string}[]} */
  const switchKinds = [];
  switchKinds.push({ id: IMPORT_CONFIG_KIND.startel, name: 'Calls - startel cmc api' });
  switchKinds.push({ id: IMPORT_CONFIG_KIND.peoplePraise, name: 'Agent Events - People Praise' });
  return switchKinds;
}

/**
 * @typedef {{switchId:number, kind:number, url:string|null, username:string|null, pwd:string|null, lastTime:Date|null, errorMsg?:string|null, calcWrap:boolean|null}} ImportConfigtDto
 */

/**
 * @typedef {{id:number, active:boolean, timezone:string, importConfigs:ImportConfigtDto[]}} SwitchConfigDto
 */

export async function getSwitches() {
  /** @type {SwitchConfigDto[]} */
  const switches = await fetchAndCheckJson(`switch-config`, 'GET');
  return switches.map(x => new SwitchConfig(x));
}

/**
 * @param {SwitchConfigDto} data
 */
async function postSwitch(data) {
  await fetchAndCheckJson(`switch-config`, 'POST', data);
}

/**
 * @param {SwitchConfigDto} data
 */
async function patchSwitch(data) {
  await fetchAndCheckJson(`switch-config/${data.id}`, 'PATCH', data);
}

export class SwitchConfig {
  /**
   * @param {SwitchConfigDto} dto
   */
  constructor(dto) {
    /** @readonly @type {number} */
    this.id = dto.id;
    /** @private @type {string} */
    this._timezone = dto.timezone;
    /** @private @type {boolean} */
    this._active = dto.active;
    /** @type {ImportConfigtDto[]} */
    this.importConfigs = dto.importConfigs;
    /** last time for call data @type {Date|null} */
    let lastTime = null;
    for (const ic of dto.importConfigs) {
      if (ic.lastTime) {
        ic.lastTime = new Date(ic.lastTime);
        if (ic.kind !== IMPORT_CONFIG_KIND.peoplePraise) {
          if (lastTime === null || ic.lastTime < lastTime) {
            lastTime = ic.lastTime;
          }
        }
      }
    }

    /** @readonly @type {Date|null} */
    this.lastTime = lastTime;
    if (dto.id === null) {
      /** @private @type {boolean} */
      this._hasChanges = true;
    }
  }

  get active() {
    return this._active;
  }
  set active(value) {
    if (this._active !== value) {
      this._hasChanges = true;
      this._active = value;
    }
  }

  get hasChanges() {
    return this._hasChanges === true;
  }

  get timezone() {
    return this._timezone;
  }
  set timezone(value) {
    if (this._timezone !== value) {
      this._hasChanges = true;
      this._timezone = value;
    }
  }

  copy() {
    return new SwitchConfig(this.toDto());
  }

  markAsHasChanges() {
    this._hasChanges = true;
  }

  orderById() {
    return this.importConfigs.some(x => x.kind === IMPORT_CONFIG_KIND.startel);
  }

  /** @returns {SwitchConfigDto} */
  toDto() {
    const importConfigs = this.importConfigs.map(x => ({ ...x }));
    return { id: this.id, timezone: this.timezone, active: this.active, importConfigs }
  }

  async save() {
    const dto = this.toDto();
    if (this.id <= 0) {
      await postSwitch(dto);
    } else {
      await patchSwitch(dto);
    }
    this._hasChanges = false;
  }
}
