import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { fromISODate, toISODate } from '@/js/dn-helper.js';


/**
 * @param {{ email: string, password: string, userName: string, schema: string, customerName: string, 
 * st: Date, tz: string, areaId: number, vat: string|null, address:string, person: string, useMode:number, initialLicenses: number,
 * paymentMethod:number , notify: boolean;countDays?:boolean;initialOverride?:boolean;authExternal?:boolean;
 * refreshTokenDays?:number}} data
 */

export async function addCustomer(data) {
  return await fetchAndCheckJson('customer', 'POST', data);
}

/**
 * @param {number} id
 * @param {boolean} connectUsers
 */
export async function copyCustomer(id, connectUsers) {
  return await fetchAndCheckJson('customer?copy=1', 'POST', { id, connectUsers });
}

/**
 * @param {string} schema
 */
export async function deleteCustomer(schema) {
  return await fetchAndCheckJson('customer/' + schema, 'DELETE');
}

/**
 * @return {Promise<Customer[]>}
 */
export async function getCustomers() {
  return await fetchAndCheckJson('customer', 'GET').then(list => list.map(x => new Customer(x)));
}

/**
 * @param {{id: number; email?: string; schema: string; name?: string; timezone?: string; endTime?:Date?, 
 * features?:{featureId:number, fee:number, st:string, fi:string}[], extraStorage?:number, areaId?:number, 
 * initialLicenses?:number, vat?:string, address?:string, person?:string, useMode?:number, paymentMethod?:number, 
 * update?:boolean;countDays?:boolean;initialOverride?:boolean;authExternal?:boolean;refreshTokenDays?:number}} data
 */

export async function patchCustomer(data) {
  console.log(data);
  return await fetchAndCheckJson(`customer/${data.schema}`, 'PATCH', data);
}

export class Customer {

  constructor(data) {
    /** @readonly @type {number} */
    this.id = data.id;
    /** @private  @type {string} */
    this._name = data.name;
    /** @type {string} */
    this.schema = data.schema;
    /** @type {string} */
    this.timezone = data.timezone;
    /** @type {string} */
    this.version = data.version;
    /** @type {string} */
    this.email = data.email;
    /** @private @type {number} */
    this._areaId = data.areaId;
    /** @private @type {number} */
    this._initialLicenses = data.initialLicenses;
    /** @private @type {number} */
    this._extraStorage = data.extraStorage;
    /** @private @type {number} */
    this._paymentMethod= data.paymentMethod;
    /** @private @type {string} */
    this._vat = data.vat;
    /** @private @type {string} */
    this._address = data.address;
    /** @private @type {string} */
    this._person = data.person;
    /** @readonly @type {boolean} */
    this.tzOk = data.tzOk;
    /** @private @type {boolean|null} */
    this._countDays = data.countDays;
    /** @private @type {boolean|null} */
    this._initialOverride = data.initialOverride;
    /** @private @type {boolean|null} */
    this._authExternal = data.authExternal;
    /** @private @type {number|null} */
    this._refreshTokenDays = data.refreshTokenDays;
    /** @private @type {number|null} */
    this._useMode = data.useMode;

    /** @type {CustomerFeature[]} */
    this.features = CUSTOMER_FEATURE_KEYS.map((x, id) => new CustomerFeature(this, id));
    for (const apiRow of data.features) {
      this.features[apiRow.featureId].init(apiRow);
    }

    /** @type {boolean} */
    this.hasChanges = false;
  }

  get address() {
    return this._address;
  }

  set address(value) {
    this.setValue('_address', value);
  }

  get authExternal() {
    return this._authExternal;
  }

  set authExternal(value) {
    this.setValue('_authExternal', value);
  }

  get countDays() {
    return this._countDays;
  }

  set countDays(value) {
    this.setValue('_countDays', value);
  }

  get areaId() {
    return this._areaId;
  }

  set areaId(value) {
    this.setValue('_areaId', value);
  }

  get endTime() {
    return this.features[0].fi;
  }

  get hasSms() {
    return this.features[2].hasFeat();
  }

  get initialLicenses() {
    return this._initialLicenses;
  }

  set initialLicenses(value) {
    this.setValue('_initialLicenses', value);
  }

  get initialOverride() {
    return this._initialOverride;
  }

  set initialOverride(value) {
    this.setValue('_initialOverride', value);
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this.setValue('_name', value);
  }

  get person() {
    return this._person;
  }

  set person(value) {
    this.setValue('_person', value);
  }

  get extraStorage() {
    return this._extraStorage;
  }

  set extraStorage(value) {
    this.setValue('_extraStorage', value);
  }

  get paymentMethod() {
    return this._paymentMethod;
  }

  set paymentMethod(value) {
    this.setValue('_paymentMethod', value);
  }

  get refreshTokenDays() {
    return this._refreshTokenDays;
  }

  set refreshTokenDays(value) {
    this.setValue('_refreshTokenDays', value);
  }

  get vat() {
    return this._vat;
  }

  set vat(value) {
    this.setValue('_vat', value);
  }

  get isInvoicesCreated() {
    return this.initialLicenses > 0 && this.features.some(x => x.fee > 0);
  }

  get useMode() {
    return this._useMode;
  }

  set useMode(value) {
    this.setValue('_useMode', value);
  }

  async save() {
    const data = {
      address: this.address,
      areaId: this.areaId,
      authExternal: this.authExternal,
      countDays: this.countDays,
      id: this.id,
      initialLicenses: this.initialLicenses,
      initialOverride: this.initialOverride,
      features: this.features.filter(x => x.st).map(x => x.toApi()),
      name: this.name,
      person: this.person,
      schema: this.schema,
      extraStorage: this.extraStorage,
      paymentMethod:this.paymentMethod,
      refreshTokenDays: this.refreshTokenDays,
      vat: this.vat,
      useMode: this.useMode,
    };
    await patchCustomer(data);
    this.hasChanges = false;
  }

  /**
   * @private
   * @param {string} prop
   * @param {any} value
   */
  setValue(prop, value) {
    if (this[prop] !== value) {
      this[prop] = value;
      this.hasChanges = true;
    }
  }
}

class CustomerFeature {
  /**
   * @param {Customer} parent
   * @param {number} featureId
   */
  constructor(parent, featureId) {
    /** @private @readonly @type {Customer} */
    this.parent = parent;
    /** @readonly @type {number} */
    this.featureId = featureId;
    /** @private @type {number} */
    this._fee = 0;
    /** @private @type {Date|null} */
    this._st = null;
    /** @private @type {Date|null} */
    this._fi = null;
    /** @private @type {boolean} */
    this._isInDB = false;
  }

  /**
   * @param {{ fee: number; st: string; fi: string |null; }} apiRow
   */
  init(apiRow) {
    this._isInDB = true;
    this._fee = apiRow.fee;
    this._st = fromISODate(apiRow.st);
    if (apiRow.fi !== null)
      this._fi = fromISODate(apiRow.fi);
  }

  canStartBeSetToNull(prop) {
    if (prop === 'st')
      return !this._isInDB;
    return true;
  }

  get fee() {
    return this._fee;
  }

  set fee(value) {
    this.setValue('_fee', value);
  }

  get fi() {
    return this._fi;
  }

  set fi(value) {
    this.setValue('_fi', value);
  }

  get st() {
    return this._st;
  }

  set st(value) {
    this.setValue('_st', value);
  }

  get name() {
    return CUSTOMER_FEATURE_KEYS[this.featureId];
  }

  hasFeat() {
    if (this.st === null)
      return false;

    const now = new Date();
    return this.st <= now && (this.fi === null || now <= this.fi)
  }

  toApi() {
    return {
      featureId: this.featureId,
      fee: this.fee,
      st: toISODate(this.st),
      fi: toISODate(this.fi),
    };
  }

  /**
   * @private
   * @param {string} prop
   * @param {any} value
   */
  setValue(prop, value) {
    if (this[prop] !== value) {
      this[prop] = value;
      this.parent.hasChanges = true;
    }
  }
}

const CUSTOMER_FEATURE_KEYS = Object.freeze(['main', 'storage', 'sms', 'adherence']);

export const CUSTOMER_FEATURE = Object.freeze({main:0, storage:1, sms:2, adherence:3 });