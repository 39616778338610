import { COLORS } from '@/js/dn-colors.js';
import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/**
* @typedef {{id: number; name: string; colorId: number; abbr: string; }} TagDto
*/

export class Tag {
  /**
   * @param {number|null} id
   * @param {string} name
   * @param {number} colorId
   * @param {string} abbr
   */
  constructor(id, name, colorId, abbr) {
    /** @readonly @type {number|null} */
    this.id = id;
    /** @private @type {string} */
    this._name = name;
    /** @type {number} */
    this._colorId = colorId;
    /** @private @type {string} */
    this._abbr = abbr;

    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @private @type {boolean} */
    this._isToDelete = false;
  }

  get name() {
    return this._name;
  }

  set name(newValue) {
    this._name = newValue;
    this._hasChanges = true;
  }

  get colorId() {
    return this._colorId;
  }

  get abbr() {
    return this._abbr;
  }

  set abbr(newValue) {
    this._abbr = newValue;
    this._hasChanges = true;
  }

  clone() {
    return new Tag(this.id, this.name, this.colorId, this.abbr);
  }

  get schemecolor() {
    return COLORS.byId(this.colorId);
  }
  set schemecolor(value) {
    const colorId = COLORS.idBySchemecolor(value);
    if (colorId !== this.colorId) {
      this._colorId = colorId;
      this._hasChanges = true;
    }
  }

  get colorType() {
    return 'is-' + this.schemecolor;
  }

  get hasChanges() {
    return this._hasChanges;
  }
  get isToDelete() {
    return this._isToDelete;
  }
  toDelete() {
    this._isToDelete = true;
    this._hasChanges = true;
  }
}

/** @returns {Promise<TagDto[]>} */
export async function getTags() {
  return await fetchAndCheckJson('tag', 'GET');
}

/** @returns {Promise<number[][]>} */
export async function getTagUsage() {
  return await fetchAndCheckJson('tag-usage', 'GET');
}

/**
 * @param {Tag[]} tags
 */
export async function postTags(tags) {
  /** @type {{ delete: number[]; rows: {id: null|number, name:string, colorId:number, abbr:string}[]}} */
  let data = {
    delete: [],
    rows: []
  }
  for (const tag of tags) {
    if (tag.isToDelete) {
      if (tag.id !== null)
        data.delete.push(tag.id);
    } else if (tag.hasChanges) {
      data.rows.push({ id: tag.id, name: tag.name, colorId: tag.colorId, abbr: tag.abbr });
    }
  }

  await fetchAndCheckJson('tag', 'POST', data);
}

/**
 * @param {Map<number, Tag>} tagById
 * @param {string} text
 * @param {number[]} disallowedTagIds
 */
export function getFilteredTags(tagById, text, disallowedTagIds = undefined) {
  /** @type {Tag[]} */
  const tags = [];
  const textToSeach = text.toLocaleLowerCase();
  for (const tag of tagById.values()) {
    if (disallowedTagIds !== undefined && disallowedTagIds.includes(tag.id))
      continue;
    if (textToSeach.length === 0 || tag.name.toLocaleLowerCase().indexOf(textToSeach) >= 0) {
      tags.push(tag);
    }
  }

  return tags;
}

/**
 * @param {TagDto[]} tags
 */
export function convertTagsFromAPI(tags) {
  return new Map(tags.map(convertTagFromAPI).map(x => [x.id, x]));
}

/**
 * @param {TagDto} tag
 */
function convertTagFromAPI(tag) {
  return new Tag(tag.id, tag.name, tag.colorId, tag.abbr);
}

const TAG_USAGE_DESCRIPTION = createTagUsageDescription();

function createTagUsageDescription() {
  const tagUsageDescription = ['break settings'];
  return Object.freeze(tagUsageDescription);
}

/**
 * @param {number[]} tagIds
 * @param {number[][]} tagUsage
 */
export function getTagUsageWarnings(tagIds, tagUsage) {
  /** @type {{description: string, tagIds: number[]}[]} */
  const warnings = [];
  for (let i = 0; i < TAG_USAGE_DESCRIPTION.length; i++) {
    const used = tagUsage[i];
    const ids = tagIds.filter(id => used.includes(id));
    if (ids.length > 1) {
      warnings.push({ description: TAG_USAGE_DESCRIPTION[i], tagIds: ids })
    }
  }

  return warnings;
}

/**
 * @param {Map<number, Tag>} tagById
 * @param {{description: string, tagIds: number[]}[]} tagUsageWarnings
 */
export function getTagsUsageTooltip(tagById, tagUsageWarnings) {
  if (tagUsageWarnings.length === 0)
    return '';

  return tagUsageWarnings.map(w => 'Conflicting ' + w.description + ': ' + w.tagIds.map(tagId => tagById.get(tagId).name).join(', ')).join(', ')
}