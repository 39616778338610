import moment from 'moment-timezone';

/**
 * Gets the closest start of the day in the given time zone.
 * @param {Date} dt
 * @param {string} timezone
 */
export function getClosestStartOfDayTZ(dt, timezone) {
  if (!timezone) { return dt; }
  return moment.tz(dt, timezone).add(12, 'hours').startOf('day').toDate();
}

/**
 * @param {Date} dt
 * @param {string} timezone
 */
export function getLocalOffset(dt, timezone) {
  if (!timezone) { return 0; }
  const localUTCOffset = moment(dt).utcOffset();
  const tzUTCOffset = moment.tz(dt, timezone).utcOffset()
  return tzUTCOffset - localUTCOffset;
}
