import { addDays, getDayDiff, getShortDate, getStartOfDay, getQuarterHour, sumDateIntervals } from '@/js/dn-helper';
import { Task } from "@/model/dn-task";
import { TASK_KIND } from '@/model/dn-tasktype.js';

/**
 * @typedef {{days:{dt:Date; endOfDay:Date; day:number; isWeekend:boolean; weekIndex:number}[]; weeks:Date[]}} CalcInfo
 */

/**
 * @typedef {{st:number; fi:number}[][]} AvailabilityByDay
 */

/**
 * @typedef { { workInMinutes:number; workIntervals:{st:Date; fi:Date; tasktypeId:number}[]}} BOEmpDayWork
 */

/**
 * @typedef {{st:Date; fi:Date; isToDelete?:boolean}} DeletableDateInterval
 */

export class EmployeeSchedule {
  /**
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeById
   * @param {import("@/model/dn-employee.js").Employee} emp
   * @param {import("@/model/dn-task.js").Task[]} tasks
   * @param {Date} focusedDate
   * @param {number} numberOfDays
   * @param {number} index
   * @param {CalcInfo|undefined} [calcInfo]
   * @param {AvailabilityByDay} [availabilityByDay]
   * @param {{boData:import("@/js/dn-bo-data.js").BOData;plannedAndTarget:import("@/js/dn-planned-and-target.js").PlannedAndTarget;}} [extra]
   */
  constructor(taskTypeById, emp, tasks, focusedDate, numberOfDays, index, calcInfo = undefined, availabilityByDay = undefined, extra = undefined) {
    /** @readonly @private @type {Map<number, import("@/model/dn-tasktype.js").TaskType>} */
    this.taskTypeById = taskTypeById;
    /** @readonly @type {import("@/model/dn-employee.js").Employee} */
    this.emp = emp;
    /** @readonly @type {import("@/model/dn-task.js").Task[][]} */
    this.tasksByKind = [];
    for (let i = 0; i < 7; i++) {
      this.tasksByKind[i] = [];
    }
    /** @private @type {number|undefined} */
    this._paidwork = undefined;
    /** @type {number} */
    this.index = index;
    /** @type {Date} */
    this.focusedDate = focusedDate;
    /** @readonly @type {number} */
    this.numberOfDays = numberOfDays;
    if (calcInfo !== undefined) {
      /** @private @readonly @type {CalcInfo} */
      this.calcInfo = calcInfo;
      /** @private @readonly @type {{hasWork: boolean; shiftBounds: {st:Date; fi:Date}; work:number; workIntervals:import("@/js/dn-planned-and-target.js").WorkInterval[]; boWork:BOEmpDayWork}[]} */
      this.byDay = Array(this.numberOfDays);
      /** @private @readonly @type {(undefined|number)[]} */
      this._paidWorkWeek = Array(calcInfo.weeks.length);
      /** @type {Map<number, import("@/js/dn-bo-data.js").BOSkillData>} */
      let boByTaskType = undefined;
      if (extra) {
        if (extra.boData.hasAny) {
          boByTaskType = extra.boData.filteredBySkills(this.emp.skills);
          if (boByTaskType.size === 0) {
            boByTaskType = undefined;
          }
        }
        if (extra.plannedAndTarget.hasAny) {
          /** @private @readonly @type {import("@/js/dn-planned-and-target.js").PlannedAndTarget} */
          this.plannedAndTarget = extra.plannedAndTarget;
        }
      }
      if (boByTaskType) {
        /** @private @readonly @type {(undefined|number)[]} */
        this._boWorkWeek = Array(calcInfo.weeks.length);
      }

      /** @private @readonly @type {import("@/model/dn-task.js").Task[]} */
      this.initialTasks = tasks;

      /** @private @readonly @type {AvailabilityByDay} */
      this.availabilityByDay = availabilityByDay;

      /** @private @readonly @type {Map<number, import("@/js/dn-bo-data").BOSkillData>} */
      this.boByTaskType = boByTaskType;
    }

    for (const task of tasks) {
      this.addTask(task);
    }

    if (this.boByTaskType) {
      for (let i = 0; i < numberOfDays; i++) {
        this.getDayByIndex(i);
      }
    }
  }

  get tasks() {
    return this.tasksByKind[TASK_KIND.task];
  }

  get breaks() {
    return this.tasksByKind[TASK_KIND.break];
  }

  get absences() {
    return this.tasksByKind[TASK_KIND.absence];
  }

  get payments() {
    return this.tasksByKind[TASK_KIND.payment];
  }

  get log() {
    return this.tasksByKind[TASK_KIND.log];
  }

  get inRotation() {
    return this.tasksByKind[TASK_KIND.inRotation];
  }

  get correction() {
    return this.tasksByKind[TASK_KIND.correction];
  }

  /**
   * @param {Date} dt
   * @param {(value: Task, index: number, array: Task[]) => value is Task} isInShift
   */
  copyForReplacement(dt, isInShift) {
    return new EmployeeSchedule(this.taskTypeById, this.emp, this.tasks.concat(this.breaks).filter(isInShift), dt, 1, -1);
  }

  /**
   * In calcMode use only this function for adding tasks
   * @private
   * @param {import("@/model/dn-task.js").Task} task
   */
  addTask(task) {
    const tt = this.taskTypeById.get(task.tasktypeId);
    if (tt.kind >= 0 && tt.kind < this.tasksByKind.length) {
      const taskList = this.tasksByKind[tt.kind];
      taskList.push(task);
    }
    return null;
  }

  /**
   * @param {number} dayIndex
   * @param {{st:number, dur:number; taskTypeId:number}[]} tasks
   * @param {number} minutesPerUnit
   */
  addTasks(dayIndex, tasks, minutesPerUnit = 1) {
    const dayInfo = this.getDayInfo(dayIndex);
    let affectsnextday = false;
    for (const t of tasks) {
      const st = new Date(dayInfo.dt);
      st.setMinutes(t.st * minutesPerUnit);
      const fi = new Date(dayInfo.dt)
      fi.setMinutes((t.st + t.dur) * minutesPerUnit);
      if (st < fi) {
        const task = new Task({ empid: this.emp.id, st, fi, tasktypeId: t.taskTypeId });
        this.addTask(task);
      }
      if (fi > dayInfo.endOfDay) { affectsnextday = true; }
    }
    this.clearDayComputations(dayIndex);
    if (affectsnextday) { this.clearDayComputations(dayIndex + 1); }
    if (this.boByTaskType || this.plannedAndTarget) {
      this.getDayByIndex(dayIndex);
      if (affectsnextday) { this.getDayByIndex(dayIndex + 1); }
    }
  }

  get calcMode() {
    return this.calcInfo !== undefined;
  }

  /**
   * @private
   * @param {number} numberOfDays
   * @param {Date} startDt
   * @param {boolean} calcPayment
   * @param {boolean} calendarDays
   */
  calculateWork(numberOfDays, startDt, calcPayment = false, calendarDays = false) {
    let work = 0.0;
    if (this.calcMode && !calcPayment) {
      for (let d = 0; d < numberOfDays; d += 1) {
        const day = this.getDay(addDays(startDt, d));
        work += day.work;
      }
    } else {
      for (let d = 0; d < numberOfDays; d += 1) {
        const daySt = addDays(startDt, d);
        const shift = calendarDays ? { st: daySt, fi: addDays(daySt, 1) } : this.getShiftBoundsByDate(daySt);
        work += this.calculateShiftPaidTime(shift, calcPayment);
      }
    }

    return Math.round(work * 100) / 100;
  }

  /**
   * @private
   * @param {{st:Date; fi:Date}} shift
   * @param {boolean} [calcPayment]
   */
  calculateShiftPaidTime(shift, calcPayment = false) {
    if (shift.st < shift.fi) {
      const tasks = calcPayment ? this.payments : this.tasks;
      const shiftTasks = cutToBounds(tasks.filter(x => !x.isToDelete), shift);
      /** @type {{st:Date; fi:Date; tasktypeId:number}[]} */
      const toRemove = [];
      for (const t of this.breaks) {
        if (t.overlaps(shift.st, shift.fi)) {
          toRemove.push(t);
        }
      }
      for (const t of this.absences) {
        if (t.overlaps(shift.st, shift.fi)) {
          toRemove.push(t);
        }
      }
      return getWorkImpl(this.taskTypeById, shiftTasks, toRemove, calcPayment);
    }
    return 0;
  }

  /**
   * @private
   * @param {number} dayIndex
   * @returns {BOEmpDayWork} 
   * 
  */
  calculateBOWork(dayIndex) {
    const boByTaskType = this.boByTaskType;
    if (boByTaskType && boByTaskType.size > 0) {
      const dayCalcInfo = this.getDayInfo(dayIndex);
      const st = dayCalcInfo.dt;
      const fi = dayCalcInfo.endOfDay;
      const boTasks = this.tasks.filter(x => !x.isToDelete && x.overlaps(st, fi) && boByTaskType.has(x.tasktypeId));

      if (boTasks.length > 0) {
        const intervals = cutToBounds(boTasks, { st, fi });
        const workIntervals = subtractTasks(intervals, this.breaks);
        if (workIntervals) {
          let workInMinutes = 0;
          const msPerMinute = 1000 * 60;
          for (const wi of workIntervals) {
            workInMinutes += (wi.fi.getTime() - wi.st.getTime()) / msPerMinute;
            const boSkillData = this.boByTaskType.get(wi.tasktypeId);
            boSkillData.addPlanned(dayIndex, wi, 1);
          }
          return { workInMinutes, workIntervals };
        }
      }
    }

    return null;
  }

  /**
   * @private
   * @param {number} dayIndex
   * 
  */
  calculateWorkIntervals(dayIndex) {
    if (!this.plannedAndTarget) { return undefined; }
    const dayCalcInfo = this.getDayInfo(dayIndex);
    const st = dayCalcInfo.dt;
    const fi = dayCalcInfo.endOfDay;
    /** @type {{st:Date; fi:Date; tasktypeId:number}[]} */
    let taskIntervals = [];
    for (const t of this.tasks) {
      if (!t.isToDelete && t.overlaps(st, fi)) {
        const tt = this.taskTypeById.get(t.tasktypeId);
        if (tt.work > 0) {
          taskIntervals.push({ st: t.st, fi: t.fi, tasktypeId: t.tasktypeId });
        }
      }
    }

    /** @type {import("@/js/dn-planned-and-target.js").WorkInterval[]} */
    const workIntervals = [];
    if (taskIntervals.length > 0) {
      taskIntervals = cutToBounds(taskIntervals, { st, fi });
      const breaksOnDay = this.breaks.filter(t => !t.isToDelete && t.overlaps(st, fi));
      taskIntervals = subtractTasks(taskIntervals, breaksOnDay);
      for (const t of taskIntervals) {
        const tt = this.taskTypeById.get(t.tasktypeId);
        const stQ = getQuarterHour(t.st);
        const fiQ = t.fi >= dayCalcInfo.endOfDay ? 96 : getQuarterHour(t.fi);
        workIntervals.push({ stQ, fiQ, work: tt.work / 100 });
      }
      this.plannedAndTarget.addPlanned(dayIndex, this.emp.skills, workIntervals, 1);
    }
    return workIntervals;
  }

  /**
   * @private
   * @param {import("@/model/dn-task.js").Task[]} attendanceTasks
   * @param {{st:Date; fi:Date}} interval
   * @param {Map<number,import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
   */
  calculateAttendance(attendanceTasks, interval, taskTypeMap) {
    const attendanceBreaksTolerance = 1000 * 60 * 60 * 6;
    const attendanceTasksMapped = attendanceTasks.map(x => ({ st: x.stCorrect, fi: x.fiCorrect, tasktypeId: x.tasktypeId }));
    const log = cutToBounds(attendanceTasksMapped, interval);
    const breaks = cutToBounds(this.breaks, interval);
    const tasks = cutToBounds(this.tasks, interval);
    /** @type {{ st: Date; fi: Date; tasktypeId: number;}[]} */
    const paidBreaksOnLog = [];
    /** @type {{ st: Date; fi: Date; tasktypeId: number;}[]} */
    const unpaidBreaks = [];
    for (const br of breaks) {
      if (taskTypeMap.get(br.tasktypeId).paidBreak) {
        let hasBefore = false;
        let hasAfter = false;
        for (const t of attendanceTasksMapped) {
          if (t.st < br.st && br.st.getTime() - t.fi.getTime() < attendanceBreaksTolerance) {
            hasBefore = true;
          }
          if (t.fi > br.fi && t.st.getTime() - br.fi.getTime() < attendanceBreaksTolerance) {
            hasAfter = true;
          }
          if (hasBefore && hasAfter) {
            paidBreaksOnLog.push(br);
            break;
          }
        }
      } else {
        unpaidBreaks.push(br);
      }
    }
    const noLogTasks = subtractTasks(tasks.filter(x => !taskTypeMap.get(x.tasktypeId).requiresLog), unpaidBreaks);
    return Math.round(100 * sumDateIntervals(log.concat(paidBreaksOnLog).concat(noLogTasks)) / (60 * 60 * 1000)) / 100;
  }

  /**
   * @param {number} dayIndex
   */
  getDayInfo(dayIndex) {
    return this.calcInfo.days[dayIndex];
  }

  get paidwork() {
    if (this._paidwork === undefined) {
      this.calculatePaidWork(this.numberOfDays, this.focusedDate);
    }

    return this._paidwork;
  }

  /**
   * @param {number} weekIndex
   */
  paidWorkWeek(weekIndex) {
    let weekWork = this._paidWorkWeek[weekIndex];
    if (weekWork === undefined) {
      weekWork = this.calculateWork(7, this.calcInfo.weeks[weekIndex]);
      this._paidWorkWeek[weekIndex] = weekWork;
    }
    return weekWork;
  }

  /**
   * @param {number} dayIndex
   * @param {{ st: Date; fi: Date; }} interval
   */
  removeBreaks(dayIndex, interval) {
    if (deleteTasksOnInterval(this.breaks, interval)) {
      const dayInfo = this.getDayInfo(dayIndex);
      this.clearDayComputations(dayIndex);
      const endsAtNextDay = interval.fi > dayInfo.endOfDay
      if (endsAtNextDay) {
        this.clearDayComputations(dayIndex + 1);
      }
      if (this.boByTaskType || this.plannedAndTarget) {
        this.getDayByIndex(dayIndex);
        if (endsAtNextDay) { this.getDayByIndex(dayIndex + 1); }
      }
    }
  }

  /**
   * @param {number} dayIndex
   */
  removeShift(dayIndex) {
    const interval = this.getDayByIndex(dayIndex).shiftBounds;
    if (interval.st.getTime() >= interval.fi.getTime())
      return;

    let changed = deleteTasksOnInterval(this.tasks, interval);
    changed = deleteTasksOnInterval(this.breaks, interval) || changed;
    changed = deleteTasksOnInterval(this.payments, interval) || changed;
    changed = deleteTasksOnInterval(this.absences, interval) || changed;
    if (changed) {
      const dayInfo = this.getDayInfo(dayIndex);
      this.clearDayComputations(dayIndex);
      if (interval.fi > dayInfo.endOfDay) {
        this.clearDayComputations(dayIndex + 1);
      }
    }
  }

  /**
   * @private
   * @param {number} dayIndex
   */
  clearDayComputations(dayIndex) {
    this._paidwork = undefined;
    if (!this.calcMode)
      return;

    if (dayIndex >= 0 && dayIndex < this.numberOfDays) {
      const dayData = this.byDay[dayIndex];
      if (dayData) {
        if (dayData.boWork) {
          for (const wi of dayData.boWork.workIntervals) {
            const boSkillData = this.boByTaskType.get(wi.tasktypeId);
            boSkillData.addPlanned(dayIndex, wi, -1);
          }
        }
        if (dayData.workIntervals && dayData.workIntervals.length > 0) {
          this.plannedAndTarget.addPlanned(dayIndex, this.emp.skills, dayData.workIntervals, -1);
        }
      }
      this.byDay[dayIndex] = undefined;
      const weekIndex = this.getDayInfo(dayIndex).weekIndex;
      this._paidWorkWeek[weekIndex] = undefined;
      if (this._boWorkWeek) {
        this._boWorkWeek[weekIndex] = undefined;
      }
    }
  }

  /**
   * @private
   * @param {Date} dt
   */
  getDay(dt) {
    const dayIndex = this.getDayIndex(dt);
    return this.getDayByIndex(dayIndex);
  }

  /**
   * @param {number} dayIndex
   */
  getDayByIndex(dayIndex) {
    if (dayIndex < 0 || dayIndex >= this.numberOfDays)
      return undefined;
    let dayData = this.byDay[dayIndex];
    if (dayData === undefined) {
      const dt = this.getDayInfo(dayIndex).dt;
      const shiftBounds = getShiftBounds(dt, this.tasks);
      const work = this.calculateShiftPaidTime(shiftBounds);
      const boWork = this.calculateBOWork(dayIndex);
      const workIntervals = this.calculateWorkIntervals(dayIndex);
      dayData = {
        hasWork: work > 0,
        shiftBounds,
        work,
        workIntervals,
        boWork
      };
      this.byDay[dayIndex] = dayData;
    }
    return dayData;
  }

  /**
   * @private
   * @param {Date} dt
   */
  getDayIndex(dt) {
    return getDayDiff(dt, this.focusedDate);
  }

  /**
   * @private {number}
   */
  getAgreedWorkMinutesDiff() {
    if (this.emp.agreedWorkWeek === null) { return null; }
    const maxTotalWorkHours = this.emp.agreedWorkWeek * this.numberOfDays / 7;
    return maxTotalWorkHours - this.paidwork * 60;
  }

  /**
   * @param {number} dayIndex
   * @param {import("@/model/dn-break-settings.js").BreakSettings[]} breakSettings
   * @returns {import("@/model/dn-shift.js").DayBounds}
   */
  getDayBounds(dayIndex, breakSettings) {
    const areedWorkMinutesLeft = this.getAgreedWorkMinutesDiff();
    let maxWorkMinutes = areedWorkMinutesLeft !== null ? areedWorkMinutesLeft : 1440;
    const weekIndex = this.getDayInfo(dayIndex).weekIndex;
    maxWorkMinutes = Math.min(maxWorkMinutes, this.getMaxWorkWeekLeft(weekIndex));
    let maxDurQ = Math.floor(getMaxDurForWork(breakSettings, maxWorkMinutes) / 15);

    let minStMinute = 0;
    const dt = this.getDayInfo(dayIndex).dt;
    if (dayIndex > 0) {
      const dayBefore = this.getDayByIndex(dayIndex - 1);
      if (dayBefore.hasWork) {
        const afterNightRest = (dayBefore.shiftBounds.fi.getTime() - dt.getTime()) / 60000 + this.emp.nightRest;
        if (afterNightRest > minStMinute) {
          minStMinute = afterNightRest;
        }
      }
    }
    let maxFiMinute = 2880;
    if (dayIndex + 1 < this.numberOfDays) {
      const dayAfter = this.getDayByIndex(dayIndex + 1);
      if (dayAfter.hasWork) {
        const beforeNightRest = (dayAfter.shiftBounds.st.getTime() - dt.getTime()) / 60000 - this.emp.nightRest;
        if (beforeNightRest < maxFiMinute) {
          maxFiMinute = beforeNightRest;
        }
      }
    }

    let minStQ = Math.ceil(minStMinute / 15);
    let maxFiQ = Math.floor(maxFiMinute / 15);
    /** @type {{stQ:number;fiQ:number}[]} */
    const unavailabilites = [];
    if (this.availabilityByDay !== undefined) {
      const availabilites = this.availabilityByDay[dayIndex];
      if (availabilites.length > 0) {
        if (minStQ < availabilites[0].st) {
          minStQ = availabilites[0].st;
          minStMinute = minStQ * 15;
        }
        if (maxFiQ > availabilites[availabilites.length - 1].fi) {
          maxFiQ = availabilites[availabilites.length - 1].fi;
          maxFiMinute = maxFiQ * 15;
        }

        if (availabilites.length > 1) {
          for (let i = 1; i < availabilites.length; i++) {
            const stQ = availabilites[i - 1].fi;
            const fiQ = availabilites[i].st;
            if (stQ > minStQ && fiQ < maxFiQ) {
              unavailabilites.push({ stQ, fiQ });
            }
          }
        }
      } else {
        maxDurQ = 0;
        maxWorkMinutes = 0;
      }
    }

    return { maxDurQ, maxWorkMinutes, minStMinute, maxFiMinute, minStQ, maxFiQ, unavailabilites };
  }

  /**
   * @param {number} dayIndex
   * @param {number} st
   * @param {number} fi
   */
  isAvailable(dayIndex, st, fi) {
    if (this.availabilityByDay === undefined) { return true; }
    const availabilityByDay = this.availabilityByDay[dayIndex];
    for (const interval of availabilityByDay) {
      if (interval.st <= st && fi <= interval.fi) {
        return true;
      }
    }
    return false;
  }

  /**
   * @param {number} dayIndex
   */
  getMinWorkLeft(dayIndex) {
    const workLeft = this.getAgreedWorkMinutesDiff();
    const workLeftWeek = this.getMinWorkWeekLeft(this.getDayInfo(dayIndex).weekIndex);
    if (workLeft !== null) {
      return Math.max(workLeft, workLeftWeek);
    }

    return workLeftWeek;
  }

  /**
   * @private
   * @param {number} weekIndex
   */
  getMinWorkWeekLeft(weekIndex) {
    if (this.emp.minWorkWeek !== null) {
      return this.emp.minWorkWeek - this.paidWorkWeek(weekIndex) * 60;
    }
    return 0;
  }

  /**
   * @private
   * @param {number} weekIndex
   */
  getMaxWorkWeekLeft(weekIndex) {
    if (this.emp.maxWorkWeek !== null) {
      return this.emp.maxWorkWeek - this.paidWorkWeek(weekIndex) * 60;
    }
    return Number.POSITIVE_INFINITY;
  }

  /**
   * @param {number} dayIndex
   */
  isOkToWork(dayIndex) {
    return this.isEmpty(this.getDayInfo(dayIndex).dt) && this.isOkDaysInRow(dayIndex) && this.isOkWeekendsInRow(dayIndex);
  }

  /**
   * @private If days in row is ok if the given day is work.
   * @param {number} dayIndex
   */
  isOkDaysInRow(dayIndex) {
    if (this.emp.ghost) { return true; }
    const max = this.emp.maxDaysInRow;
    let count = 1;
    let d = dayIndex - 1;
    while (d >= 0) {
      if (this.getDayByIndex(d).hasWork) {
        count += 1;
        if (count > max)
          return false;
      } else {
        break;
      }
      d -= 1;
    }

    d = dayIndex + 1;
    while (d < this.numberOfDays) {
      if (this.getDayByIndex(d).hasWork) {
        count += 1;
        if (count > max)
          return false;
      } else {
        break;
      }
      d += 1;
    }

    return true;
  }

  /**
   * @private If weekend in row is ok if the given day is work.
   * @param {number} dayIndex
   */
  isOkWeekendsInRow(dayIndex) {
    if (this.emp.ghost) { return true; }
    const max = this.emp.maxWeekendsInRow;
    if (max === null || !this.getDayInfo(dayIndex).isWeekend) {
      return true;
    }

    let count = 1;
    let saturdayIndex = dayIndex;
    if (this.getDayInfo(dayIndex).day === 0) {
      saturdayIndex -= 1;
    }
    let d = saturdayIndex - 7;
    while (d >= -1) {
      if (this.getDayByIndex(d + 1).hasWork || (d >= 0 && this.getDayByIndex(d).hasWork)) {
        count += 1;
        if (count > max)
          return false;
      } else {
        break;
      }
      d -= 7;
    }

    d = saturdayIndex + 7;
    while (d < this.numberOfDays) {
      if (this.getDayByIndex(d).hasWork || (d + 1 < this.numberOfDays && this.getDayByIndex(d + 1).hasWork)) {
        count += 1;
        if (count > max)
          return false;
      } else {
        break;
      }
      d += 7;
    }

    return count <= max;
  }

  /**
   * @private
   * @param {number} dayIndex
   */
  isOkMinNightRest(dayIndex) {
    const minNightRest = this.emp.nightRest;
    if (minNightRest < 0) { return true; }
    const dayInfo = this.getDayByIndex(dayIndex);
    if (!dayInfo.hasWork) { return true; }
    const dayBefore = this.getDayByIndex(dayIndex - 1);
    if (dayBefore !== undefined && dayBefore.hasWork) {
      const nightRest = (dayInfo.shiftBounds.st.getTime() - dayBefore.shiftBounds.fi.getTime()) / 60000;
      if (nightRest < minNightRest) {
        return false;
      }
    }
    const dayAfter = this.getDayByIndex(dayIndex + 1);
    if (dayAfter !== undefined && dayAfter.hasWork) {
      const nightRest = (dayAfter.shiftBounds.st.getTime() - dayInfo.shiftBounds.fi.getTime()) / 60000;
      if (nightRest < minNightRest) {
        return false;
      }
    }

    return true;
  }

  checkRuleCompliance() {
    const agreedWorkDiff = this.getAgreedWorkMinutesDiff();
    let ok = true;
    if (agreedWorkDiff !== null) {
      if (agreedWorkDiff !== 0) { ok = false; }
    }

    const weeks = [];
    for (let weekIndex = 0; weekIndex < this.calcInfo.weeks.length; weekIndex++) {
      const minWorkWeekLeft = Math.max(0, this.getMinWorkWeekLeft(weekIndex));
      const maxWorkWeekViolation = Math.max(0, -this.getMaxWorkWeekLeft(weekIndex));
      if (minWorkWeekLeft > 0 || maxWorkWeekViolation > 0) {
        const weekStart = this.calcInfo.weeks[weekIndex];
        weeks.push({ weekStart, minWorkWeekLeft, maxWorkWeekViolation });
        ok = false;
      }
    }

    const days = [];
    for (let dayIndex = 0; dayIndex < this.numberOfDays; dayIndex++) {
      if (this.getDayByIndex(dayIndex).hasWork) {
        const dayInfo = this.getDayInfo(dayIndex);
        const isOkDaysInRow = this.isOkDaysInRow(dayIndex);
        const isOkWeekendsInRow = !dayInfo.isWeekend || this.isOkWeekendsInRow(dayIndex);
        const isOkMinNightRest = this.isOkMinNightRest(dayIndex);
        if (!isOkDaysInRow || !isOkWeekendsInRow || !isOkMinNightRest) {
          days.push({ dt: dayInfo.dt, isOkDaysInRow, isOkWeekendsInRow, isOkMinNightRest });
          ok = false;
        }
      }

    }

    return { employeeId: this.emp.id, ok, agreedWorkDiff, weeks, days };
  }

  /**
   * @private
   * @param {number} dayIndex
   */
  getBOWork(dayIndex) {
    const weekIndex = this.getDayInfo(dayIndex).weekIndex;
    let boWork = this._boWorkWeek[weekIndex];
    if (boWork === undefined) {
      boWork = 0;
      for (let i = 0; i < 7; i++) {
        const dayIndex = i + weekIndex * 7;
        const dayData = this.getDayByIndex(dayIndex);
        if (dayData.boWork) {
          boWork += dayData.boWork.workInMinutes;
        }
      }
      this._boWorkWeek[weekIndex] = boWork;
    }

    return boWork;
  }

  /**
   * @param {number} dayIndex
   */
  getBOWorkLeft(dayIndex) {
    if (this.emp.maxBOWeek) {
      return this.emp.maxBOWeek - this.getBOWork(dayIndex);
    }
    return undefined;
  }

  /**
   * @param {number} dayIndex
   * @param {{st:number, fi:number, ttId: number}} t
   */
  getBOScore(dayIndex, t) {
    if (this.boByTaskType) {
      const boSkillData = this.boByTaskType.get(t.ttId);
      if (boSkillData) {
        return boSkillData.getScore(dayIndex, t.st, t.fi);
      }
    }
    return -1;
  }

  /**
   * @param {number} dayIndex
   * @param {number} numberOfAgents
   */
  getBOTaskScore(dayIndex, numberOfAgents) {
    if (!this.boByTaskType) { return null; }
    const boWorkLeft = this.getBOWorkLeft(dayIndex);
    if (boWorkLeft <= 0) { return null; }
    /** @type {import("@/js/dn-optimize-shift").SecondTaskScore[]} */
    const scores = [];
    for (const boSkillData of this.boByTaskType.values()) {
      const taskScore = boSkillData.getTaskScore(dayIndex, numberOfAgents, boWorkLeft);
      if (taskScore) {
        scores.push(taskScore);
      }
    }
    return scores;
  }

  /**
   * @param {number} dayIndex
   * @param {{id:number; optimizationPriority:number}} skill
   * @param {number} stQ
   * @param {number[]} arrayQ
   */
  getMinTargetScore(dayIndex, skill, stQ, arrayQ) {
    if (!this.plannedAndTarget) { return; }
    if (!this.emp.skills.includes(skill.id)) { return; }
    this.plannedAndTarget.setScores(dayIndex, skill, stQ, arrayQ)
  }

  /**
   * @private
   * @param {Date} dt
   */
  getShiftBoundsByDate(dt) {
    if (this.calcMode)
      return this.getDay(dt).shiftBounds;
    return getShiftBounds(dt, this.tasks);
  }

  /**
   * @param {number} numberOfDays
   * @param {Date} startDt
   */
  calculatePaidWork(numberOfDays, startDt) {
    this._paidwork = this.calculateWork(numberOfDays, startDt);
  }

  /**
   * @param {{ st: Date; fi: Date; }} interval
   * @param {number} adherenceBase
   */
  getAttendance(interval, adherenceBase) {
    const attendanceTasks = adherenceBase === 1 ? this.inRotation : this.log;
    return this.calculateAttendance(attendanceTasks, interval, this.taskTypeById);
  }

  /**
   * @param {{ st: Date; fi: Date; }} interval
   */
  getLogTime(interval) {
    return calculateTimeInInterval(this.log, interval);
  }

  /**
   * @param {{ st: Date; fi: Date; }} interval
   */
  getRotationTime(interval) {
    return calculateTimeInInterval(this.inRotation, interval);
  }

  /**
   * @param {boolean} calendarDays
   */
  getPaidWork(calendarDays) {
    return this.calculateWork(this.numberOfDays, this.focusedDate, false, calendarDays);
  }

  /**
   * @param {boolean} calendarDays
   */
  getOvertime(calendarDays) {
    return this.calculateWork(this.numberOfDays, this.focusedDate, true, calendarDays);
  }

  /**
   * @param {Date} dt
   */
  hasWork(dt) {
    return this.getDay(dt).work > 0;
  }

  /**
   * @param {Date} dt
   */
  isEmpty(dt) {
    const fi = addDays(dt, 1);
    for (const t of this.tasks) {
      if (t.st >= dt && t.st < fi) {
        return false;
      }
    }

    for (const t of this.absences) {
      if (t.st >= dt && t.st < fi) {
        return false;
      }
    }

    return true;
  }

  getAddedOrDeletedTasks() {
    /** @type {Map<string, import("@/model/dn-task.js").Task>} */
    const initialByKey = new Map();
    for (const t of this.initialTasks) {
      initialByKey.set(getKey(t), t);
    }

    /** @type {import("@/model/dn-task.js").Task[]} */
    const added = [];
    compareWithInital(this.tasks);
    compareWithInital(this.breaks);
    compareWithInital(this.absences);

    /** @type {import("@/model/dn-task.js").Task[]} */
    const deleted = [];
    for (const t of initialByKey.values()) {
      deleted.push(t);
    }

    return { added, deleted };

    /** @param {import("@/model/dn-task.js").Task} t */
    function getKey(t) {
      return t.st.getTime() + '_' + t.tasktypeId;
    }

    /** @param {import("@/model/dn-task.js").Task[]} tasks */
    function compareWithInital(tasks) {
      for (const t of tasks) {
        const key = getKey(t);
        const t2 = initialByKey.get(key);
        if (t2 !== undefined && t.fi.getTime() == t2.fi.getTime()) {
          initialByKey.delete(key);
        } else {
          added.push(t);
        }
      }
    }
  }
}

export class EmployeeScheduleList {
  constructor() {
    /** @private @type {EmployeeSchedule[]} */
    this._list = [];
  }

  get list() {
    return this._list;
  }

  clear() {
    this._list = [];
  }

  /**
   * @param {number} numberOfDays
   * @param {Date} stDate
   * @param {Task[]} scheduleTasks
   * @param {import("@/model/dn-tasktype.js").TaskTypes} taskTypes
   * @param {import("@/model/dn-employee.js").Employee[]} employees
   * @param {boolean} includeInactive
   */
  load(numberOfDays, stDate, scheduleTasks, taskTypes, employees, includeInactive) {
    this._list = createSchedule(numberOfDays, stDate, scheduleTasks, taskTypes, employees, includeInactive);
  }
}

/**
 * @private
 * @param {import("@/model/dn-task.js").Task[]} tasks
 * @param {{st:Date; fi:Date}} interval
 */
function calculateTimeInInterval(tasks, interval) {
  const log = cutToBounds(tasks.map(x => ({ st: x.stCorrect, fi: x.fiCorrect, tasktypeId: x.tasktypeId })), interval);
  return Math.round(100 * sumDateIntervals(log) / (60 * 60 * 1000)) / 100;
}

/**
* @param {import("@/model/dn-task.js").Task[]} tasks
* @param {{st:Date, fi:Date}} interval
*/
function deleteTasksOnInterval(tasks, interval) {
  let changed = false;
  for (let i = tasks.length - 1; i >= 0; i--) {
    const t = tasks[i];
    if (t.isContainedIn(interval)) {
      t.toDelete();
      tasks.splice(i, 1);
      changed = true;
    }
  }

  return changed;
}

/**
 * @typedef {{
* availabilites:import("@/model/dn-availability").Availabilities; 
* availabilityRequests:import("@/model/dn-availability-request").AvailabilityRequests;
* boData:import("@/js/dn-bo-data.js").BOData;
* callcenterId:number;
* employeeCallcenters:import("@/model/dn-employee-cc").EmployeeCallcenters;
* plannedAndTarget: import("@/js/dn-planned-and-target.js").PlannedAndTarget;
* timezone: string;
* }} CreateScheduleExtraParameter
*/

/**
 * @param {number} numberOfDays
 * @param {Date} stDate Must be a start of a week if availabilites is not undefined
 * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
 * @param {import("@/model/dn-tasktype.js").TaskTypes} taskTypes
 * @param {import("@/model/dn-employee.js").Employee[]} employees
 * @param {boolean} includeInactive
 * @param {CreateScheduleExtraParameter} [extra] Don't make any changes from the outside to the task arrays in the returned objects if this is set.
 */
export function createSchedule(numberOfDays, stDate, scheduleTasks, taskTypes, employees, includeInactive,
  extra = undefined) {
  const tasksByEmpId = getTasksByEmpId(numberOfDays, stDate, scheduleTasks, employees, includeInactive);
  /** @type {CalcInfo} */
  let calcInfo = undefined;
  /** @type {Map<number, AvailabilityByDay>} */
  let availByEmp = undefined;
  /** @type {Date} */
  let fiDate = undefined;
  if (extra !== undefined) {
    availByEmp = new Map();
    const numberOfWeeks = Math.ceil(numberOfDays / 7);
    const days = [];
    let weekIndex = 0;
    for (let i = 0; i < numberOfDays; i++) {
      const dt = addDays(stDate, i);
      const endOfDay = addDays(dt, 1);
      const day = dt.getDay();
      const isWeekend = day === 0 || day === 6;
      days.push({ dt, endOfDay, day, isWeekend, weekIndex });
      if (i % 7 === 6) {
        weekIndex += 1;
      }
    }
    const weeks = []
    for (let i = 0; i < numberOfWeeks; i++) {
      weeks.push(addDays(stDate, 7 * i));
    }
    fiDate = addDays(stDate, numberOfWeeks * 7);
    calcInfo = { days, weeks };
    for (const availability of extra.availabilites.items) {
      /** @type {AvailabilityByDay} */
      const availByDay = []
      const dayCount = availability.weeks * 7;
      if (dayCount === 0) {
        continue;
      }

      let dayIndex = availability.getDayIndex(stDate);
      for (let i = 0; i < numberOfDays; i++) {
        availByDay.push(availability.getByDayIndex(dayIndex));
        dayIndex = (dayIndex + 1) % dayCount;
      }

      for (const empId of availability.employeeIdList) {
        availByEmp.set(empId, availByDay);
      }
    }
  }

  /** @type {EmployeeSchedule[]} */
  const schedule = [];
  for (const empTasks of tasksByEmpId.values()) {

    /** @type {AvailabilityByDay} */
    let availabilityByDay = undefined;
    if (availByEmp) {
      availabilityByDay = availByEmp.get(empTasks.employee.id);
      const arEmp = extra.availabilityRequests.getByEmp(empTasks.employee.id);
      if (arEmp) {
        availabilityByDay = createAvailabilityByDay(availabilityByDay, numberOfDays);
        arEmp.adjustQuarterHoursByDateAvailability(stDate, availabilityByDay);
      }

      const ec = extra.employeeCallcenters.getByEmp(empTasks.employee.id);
      if (ec) {
        const ccIntervals = ec.getOnInterval(empTasks.employee.ccid, stDate, fiDate, extra.callcenterId);
        if (ccIntervals.length > 0) {
          availabilityByDay = createAvailabilityByDay(availabilityByDay, numberOfDays);
          for (const ccInterval of ccIntervals) {
            let dt = getStartOfDay(ccInterval.st);
            let qSt = getQuarterHour(ccInterval.st);
            let nextDt = addDays(dt, 1);
            let dayIndex = getDayDiff(stDate, dt);
            while (dt < ccInterval.fi) {
              let nextNextDt = addDays(nextDt, 1);
              let qFi = 0;
              if (nextNextDt <= ccInterval.fi) {
                qFi = 96 * 2;
              } else if (nextDt <= ccInterval.fi) {
                qFi = 96 + getQuarterHour(ccInterval.fi);
              } else {
                qFi = getQuarterHour(ccInterval.fi);
              }

              const avails = availabilityByDay[dayIndex];
              let i = avails.length - 1;
              while (i >= 0) {
                const avail = avails[i];
                if (avail.st >= qSt && avail.fi <= qFi) {
                  avails.splice(i, 1);
                } else if (avail.st < qSt && qFi < avail.fi) {
                  avails.splice(i, 0, { st: qFi, fi: avail.fi });
                  avail.fi = qSt;
                } else if (avail.st < qFi && avail.fi > qSt) {
                  if (avail.st < qSt) {
                    avail.fi = qSt;
                  } else {
                    avail.st = qFi;
                  }
                }
                i--;
              }
              qSt = 0;
              dt = nextDt;
              nextDt = nextNextDt;
              dayIndex += 1;
            }
          }
        }
      }
    }

    schedule.push(new EmployeeSchedule(taskTypes.byId, empTasks.employee, empTasks.tasks, stDate, numberOfDays, empTasks.index, calcInfo, availabilityByDay, extra))
  }
  return schedule;
}

/**
 * @param {AvailabilityByDay} availabilityByDay
 * @param {number} numberOfDays
 */
function createAvailabilityByDay(availabilityByDay, numberOfDays) {
  if (availabilityByDay) {
    availabilityByDay = availabilityByDay.map(x => x.slice());
  } else {
    availabilityByDay = [];
    for (let i = 0; i < numberOfDays; i++) {
      availabilityByDay.push([{ st: 0, fi: 96 * 2 }]);
    }
  }
  return availabilityByDay;
}

/**
 * @param {{st:Date; fi:Date; tasktypeId:number}[]} tasks
 * @param {{st:Date; fi:Date}} shift
 */
export function cutToBounds(tasks, shift) {
  /** @type {{st:Date; fi:Date; tasktypeId:number}[]} */
  const shiftTasks = [];
  for (const t of tasks) {
    if (shift.st < t.fi && shift.fi > t.st) {
      if (t.st >= shift.st && t.fi <= shift.fi) {
        shiftTasks.push(t);
      } else {
        const taskCopy = { st: t.st, fi: t.fi, tasktypeId: t.tasktypeId };
        if (shift.st > taskCopy.st)
          taskCopy.st = shift.st;
        if (shift.fi < taskCopy.fi)
          taskCopy.fi = shift.fi;
        shiftTasks.push(taskCopy);
      }
    }
  }
  return shiftTasks;
}

/**
 * @param {Date} endDt
 * @param {DeletableDateInterval[]} tasks tasks should to be sorted
 */
function getConnectedEnd(endDt, tasks) {
  for (const task of tasks) {
    if (!task.isToDelete && task.st <= endDt && task.fi > endDt) {
      endDt = task.fi;
    }
  }

  return endDt;
}

/**
 * @param {{ emp: import("@/model/dn-employee.js").Employee; tasks: import("@/model/dn-task.js").Task[]; breaks: import("@/model/dn-task.js").Task[]; absences: import("@/model/dn-task.js").Task[]; }} dayEmpSchedule
 * @param {Date} daySt
 * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap only task kinds used
 * @param {number} length
 * @param {import("@/model/dn-employee-cc.js").EmployeeCallcenters} employeeCallcenters
 * @param {number} ccId
 * @param {number[]} [taskTypeIds]
 */
export function getEmpWorkArray(dayEmpSchedule, daySt, taskTypeMap, length, employeeCallcenters, ccId, taskTypeIds = null) {
  /** @type {number[]} */
  const workArray = Array(length).fill(0);
  let tasks = []
  if (taskTypeIds !== null) { tasks = dayEmpSchedule.tasks.filter(t => taskTypeIds.includes(t.tasktypeId)) } else { tasks = dayEmpSchedule.tasks }

  if (tasks.length > 0) {
    const msPerIndex = 1000 * 60 * 15;
    for (const t of tasks) {
      if (!t.isToDelete) {
        const stQ = Math.max(0, Math.round((t.st.getTime() - daySt.getTime()) / msPerIndex));
        const fiQ = Math.min(length, (t.fi.getTime() - daySt.getTime()) / msPerIndex);
        if (taskTypeIds !== null) {
          for (let i = stQ; i < fiQ; i++) { workArray[i] = 1 }
        } else {
          const tt = taskTypeMap.get(t.tasktypeId);
          if (tt !== undefined) {
            const work = tt.work / 100;
            for (let i = stQ; i < fiQ; i++) { workArray[i] = work; }
          }
        }
      }
    }
    for (const t of dayEmpSchedule.breaks) {
      if (!t.isToDelete) {
        const stQ = Math.max(0, Math.round((t.st.getTime() - daySt.getTime()) / msPerIndex));
        const fiQ = Math.min(length, (t.fi.getTime() - daySt.getTime()) / msPerIndex);
        for (let i = stQ; i < fiQ; i++) { workArray[i] = 0 }
      }
    }
    for (const t of dayEmpSchedule.absences) {
      if (!t.isToDelete) {
        const stQ = Math.max(0, Math.round((t.st.getTime() - daySt.getTime()) / msPerIndex));
        const fiQ = Math.min(length, (t.fi.getTime() - daySt.getTime()) / msPerIndex);
        for (let i = stQ; i < fiQ; i++) { workArray[i] = 0 }
      }
    }

    const empCC = employeeCallcenters.getByEmp(dayEmpSchedule.emp.id);
    if (empCC) {
      const dayFi = addDays(daySt, 1);
      const ccIntervals = empCC.getOnInterval(dayEmpSchedule.emp.ccid, daySt, dayFi, ccId);
      for (const ccInterval of ccIntervals) {
        const stQ = ccInterval.st > daySt ? getQuarterHour(ccInterval.st) : 0;
        const fiQ = ccInterval.fi < dayFi ? getQuarterHour(ccInterval.fi) : 96;
        for (let i = stQ; i < fiQ; i++) { workArray[i] = 0; }
      }
    }
  }
  return workArray;
}

/**
 * @param {{ emp: import("@/model/dn-employee.js").Employee; tasks: import("@/model/dn-task.js").Task[]; breaks: import("@/model/dn-task.js").Task[]; absences: import("@/model/dn-task.js").Task[]; }} dayEmpSchedule
 * @param {Date} daySt
 * @param {number} length
 * @param {import("@/model/dn-employee-cc.js").EmployeeCallcenters} employeeCallcenters
 * @param {number} ccId
 */
function getEmpBreakArray(dayEmpSchedule, daySt, length, employeeCallcenters, ccId) {

  /** @type {number[]} */
  const breakArray = Array(length).fill(0);
  const msPerIndex = 1000 * 60 * 5;

  const breakArrayDetailed = Array(length * 3).fill(0);
  for (const t of dayEmpSchedule.breaks) {
    if (!t.isToDelete) {
      const stQ = Math.max(0, Math.round((t.st.getTime() - daySt.getTime()) / msPerIndex));
      const fiQ = Math.min(length * 3, (t.fi.getTime() - daySt.getTime()) / msPerIndex);
      for (let i = stQ; i < fiQ; i++) { breakArrayDetailed[i] = 1; }
    }
  }
  for (const t of dayEmpSchedule.absences) {
    if (!t.isToDelete) {
      const stQ = Math.max(0, Math.round((t.st.getTime() - daySt.getTime()) / msPerIndex));
      const fiQ = Math.min(length * 3, (t.fi.getTime() - daySt.getTime()) / msPerIndex);
      for (let i = stQ; i < fiQ; i++) { breakArrayDetailed[i] = 0; }
    }
  }
  for (let i = 0; i < length; i++) {
    breakArray[i] = (breakArrayDetailed[i * 3] + breakArrayDetailed[i * 3 + 1] + breakArrayDetailed[i * 3 + 2]) / 3
  }

  const empCC = employeeCallcenters.getByEmp(dayEmpSchedule.emp.id);
  if (empCC) {
    const dayFi = addDays(daySt, 1);
    const ccIntervals = empCC.getOnInterval(dayEmpSchedule.emp.ccid, daySt, dayFi, ccId);
    for (const ccInterval of ccIntervals) {
      const stQ = ccInterval.st > daySt ? getQuarterHour(ccInterval.st) : 0;
      const fiQ = ccInterval.fi < dayFi ? getQuarterHour(ccInterval.fi) : 96;
      for (let i = stQ; i < fiQ; i++) { breakArray[i] = 0; }
    }
  }

  return breakArray;
}

/**
 * @param {EmployeeSchedule[]} schedule
 * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
 * @param {Date} dtStart
 * @param {number} numberOfDays
 * @param {number} length
 * @param {import("@/model/dn-employee-cc.js").EmployeeCallcenters} employeeCallcenters
 * @param {number} ccId
 * @param {number[]} [taskTypeIds]
 */
export function getEmpWorkMap(schedule, taskTypeMap, dtStart, numberOfDays, length, employeeCallcenters, ccId, taskTypeIds = null) {
  const emps = schedule.map(function (x) { return x.emp });
  /** @type {Map<number, Map<string, number[]>>} */
  const empWorkMap = new Map
  for (let e = 0; e < emps.length; e++) {
    empWorkMap.set(emps[e].id, new Map)
  }

  let dt = dtStart;
  let dtEnd = dtStart
  for (let d = 0; d < numberOfDays; d++) {
    dtEnd = addDays(dt, 1);
    const dtKey = getShortDate(dt);
    const daySchedule = schedule.map(function (x) { return { emp: x.emp, tasks: x.tasks.filter(isAtDt), breaks: x.breaks.filter(isAtDt), absences: x.absences.filter(isAtDt) } });
    for (const dayEmpSchedule of daySchedule) {
      const workArray = getEmpWorkArray(dayEmpSchedule, dt, taskTypeMap, length, employeeCallcenters, ccId, taskTypeIds);
      empWorkMap.get(dayEmpSchedule.emp.id).set(dtKey, workArray);
    }

    dt = dtEnd;
  }
  return empWorkMap;

  /**
   * @param {import("@/model/dn-task.js").Task} t
   */
  function isAtDt(t) {
    return t.overlaps(dt, dtEnd);
  }
}

/**
 * @param {EmployeeSchedule[]} schedule
 * @param {Date} dtStart
 * @param {number} numberOfDays
 * @param {number} length
 * @param {import("@/model/dn-employee-cc").EmployeeCallcenters} employeeCallcenters
 * @param {number} ccId
 */
export function getEmpBreakMap(schedule, dtStart, numberOfDays, length, employeeCallcenters, ccId) {
  const emps = schedule.map(function (x) { return x.emp });
  /** @type {Map<number, Map<string, number[]>>} */
  const empBreakMap = new Map
  for (let e = 0; e < emps.length; e++) {
    empBreakMap.set(emps[e].id, new Map)
  }

  let dt = dtStart;
  let dtEnd = dtStart
  for (let d = 0; d < numberOfDays; d++) {
    dtEnd = addDays(dt, 1);
    const dtKey = getShortDate(dt);
    const daySchedule = schedule.map(function (x) { return { emp: x.emp, tasks: x.tasks.filter(isAtDt), breaks: x.breaks.filter(isAtDt), absences: x.absences.filter(isAtDt) } });
    for (const dayEmpSchedule of daySchedule) {
      const breakArray = getEmpBreakArray(dayEmpSchedule, dt, length, employeeCallcenters, ccId);
      empBreakMap.get(dayEmpSchedule.emp.id).set(dtKey, breakArray);
    }

    dt = dtEnd;
  }
  return empBreakMap;

  /**
   * @param {import("@/model/dn-task.js").Task} t
   */
  function isAtDt(t) {
    return t.overlaps(dt, dtEnd);
  }
}

/**
 * @param {Date} dt
 * @param {{tasks: DeletableDateInterval[]; absences: DeletableDateInterval[];}} schedule
 */
export function getMinShiftStart(dt, schedule) {
  const minTaskStart = getPossibleShiftStart(dt, schedule.tasks, true, true);
  const minAbsenceStart = getPossibleShiftStart(dt, schedule.absences, false, true);
  if (minTaskStart > dt)
    dt = minTaskStart;
  if (minAbsenceStart > dt) {
    dt = minAbsenceStart;
  }

  return new Date(dt);
}

/**
 * @param {Date} startDt
 * @param {DeletableDateInterval[]} tasks
 * @param {boolean} connectEnd
 * @param {boolean} addExtraTime
 */
function getPossibleShiftStart(startDt, tasks, connectEnd = true, addExtraTime = false) {
  let hasTask = false;
  for (const task of tasks) {
    if (!task.isToDelete && task.st < startDt && startDt <= task.fi) {
      startDt = task.fi;
      hasTask = true;
    }
  }

  if (hasTask) {
    if (connectEnd)
      startDt = getConnectedEnd(startDt, tasks);
    if (addExtraTime)
      return new Date(startDt.getTime() + 1000 * 60 * 15);
    return startDt;
  }
  return startDt;
}

/**
 * @param {Date} dt
 * @param {DeletableDateInterval[]} tasks tasks that connect over midnight
 * @param {DeletableDateInterval[]} allTasks
 */
export function getShiftBounds(dt, tasks, allTasks = undefined) {
  const shift = { st: new Date(8640000000000000), fi: new Date(-8640000000000000) }
  if (allTasks === undefined) {
    allTasks = tasks;
  }

  const startDt = getPossibleShiftStart(dt, tasks);
  const dtEnd = addDays(dt, 1);

  setShiftBounds(shift, startDt, dtEnd, allTasks);
  shift.fi = getConnectedEnd(shift.fi, tasks);
  return shift;
}

/**
 * @param {number} numberOfDays
 * @param {Date} stDate
 * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
 * @param {import("@/model/dn-employee.js").Employee[]} employees
 * @param {boolean} includeInactive
 */
function getTasksByEmpId(numberOfDays, stDate, scheduleTasks, employees, includeInactive) {
  const fiDate = addDays(stDate, numberOfDays + 1);

  /** @type {Map<number, {index:number, employee:import("@/model/dn-employee.js").Employee, tasks:import("@/model/dn-task.js").Task[]}>} */
  const byEmpId = new Map();
  for (let e = 0; e < employees.length; e++) {
    if (employees[e].active || includeInactive) {
      byEmpId.set(employees[e].id, { index: e, employee: employees[e], tasks: [] });
    }
  }
  for (const task of scheduleTasks) {
    const employeeSchedule = byEmpId.get(task.empid);
    if (employeeSchedule !== undefined) {
      if (!task.isToDelete && task.overlaps(stDate, fiDate)) {
        employeeSchedule.tasks.push(task);
      }
    }
  }

  return byEmpId;
}

/**
 * @param {import("@/model/dn-break-settings.js").BreakSettings[]} breakSettings
 * @param {number} workMinutes
 */
function getMaxDurForWork(breakSettings, workMinutes) {
  if (workMinutes === 0)
    return 0;
  let max = workMinutes;
  for (const bs of breakSettings) {
    const dur = bs.unpaidDurationMinutes + workMinutes;
    if (dur > max && bs.minShiftDurationMinutes <= dur && dur <= bs.maxShiftDurationMinutes) {
      max = dur;
    }
  }

  return max;
}

/**
 * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
 * @param {import("@/model/dn-task.js").Task[]} tasks
 * @param {import("@/model/dn-task.js").Task[]} toRemove
 * @param {boolean} usePaid
 */
export function getWork(taskTypeMap, tasks, toRemove, usePaid = false) {
  const filteredTasks = tasks.filter(x => !x.isToDelete);
  if (filteredTasks.length === 0)
    return 0;

  const filteredToRemove = toRemove.filter(x => !x.isToDelete);
  return getWorkImpl(taskTypeMap, tasks, filteredToRemove, usePaid);
}

/**
 * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
 * @param {{st:Date; fi:Date; tasktypeId:number}[]} tasks
 * @param {{st:Date; fi:Date; tasktypeId:number}[]} toRemove
 * @param {boolean} usePaid
 */
function getWorkImpl(taskTypeMap, tasks, toRemove, usePaid = false) {
  if (tasks.length === 0)
    return 0;

  const filteredToRemove = toRemove.filter(x => !taskTypeMap.get(x.tasktypeId).paidBreak);
  const remains = subtractTasks(tasks, filteredToRemove);
  if (usePaid) {
    const msPerHour = 1000 * 60 * 60;
    return remains.reduce((sum, t) => sum + (t.fi.getTime() - t.st.getTime()) / msPerHour * taskTypeMap.get(t.tasktypeId).paid / 100, 0);
  }
  return sumTasks(remains);
}

/**
 * @param {{st:Date; fi:Date; tasktypeId:number}[]} tasks
 */
function sumTasks(tasks) {
  const msPerHour = 1000 * 60 * 60;
  return tasks.reduce((sum, t) => sum + (t.fi.getTime() - t.st.getTime()) / msPerHour, 0);
}

/**
 * @param {{ fi: Date; st: Date; }} shift
 * @param {Date} dt
 * @param {Date} dtEnd
 * @param {{st:Date; fi:Date; isToDelete?:boolean}[]} tasks
 */
function setShiftBounds(shift, dt, dtEnd, tasks) {
  for (const task of tasks) {
    if (!task.isToDelete && task.st >= dt && task.st < dtEnd) {
      if (task.st < shift.st) {
        shift.st = task.st;
      }
      if (task.fi > shift.fi) {
        shift.fi = task.fi;
      }
    }
  }
}

/**
 * @param {{st:Date; fi:Date; tasktypeId:number}[]} toCount
 * @param {{st:Date; fi:Date}[]} toRemove
 */
export function subtractTasks(toCount, toRemove) {
  const remains = toCount.map(t => ({ st: t.st, fi: t.fi, tasktypeId: t.tasktypeId }));
  for (const s of toRemove) {
    let i = 0;
    while (i < remains.length) {
      const t = remains[i];
      if (s.st < t.fi && s.fi > t.st) {
        if (t.st < s.st) {
          if (s.fi < t.fi) {
            const newFi = t.fi;
            t.fi = s.st;
            remains.push({ st: s.fi, fi: newFi, tasktypeId: t.tasktypeId })
          } else {
            t.fi = s.st;
          }
        } else if (s.fi < t.fi) {
          t.st = s.fi;
        } else {
          remains.splice(i, 1);
          continue;
        }
      }
      i++;
    }

    if (remains.length === 0)
      break;
  }

  return remains;
}
