import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/**
* @typedef {{id:number; name:string;}} AffinityDto
*/

/**
 * @param {{ id: number; }} affinity
 */
async function deleteAffinity(affinity) {
  return await fetchAndCheckJson(`affinity/${affinity.id}`, 'DELETE');
}

export async function getAffinities() {
  /** @type {AffinityDto[]} */
  const rows = await fetchAndCheckJson(`affinity`, 'GET');
  return rows.map(x => new Affinity(x));
}

/**
 * @param {Affinity} affinity
 */
async function patchAffinity(affinity) {
  return await fetchAndCheckJson(`affinity/${affinity.id}`, 'PATCH', affinity.toDto());
}

/**
 * @param {Affinity} affinity
 */
async function postAffinity(affinity) {
  return await fetchAndCheckJson(`affinity`, 'POST', affinity.toDto());
}

/**
 * @param {Affinity[]} affinities
 */
export async function saveAffinityChanges(affinities) {
  for (const affinity of affinities) {
    if (affinity.isToDelete) {
      if (affinity.id > 0)
        await deleteAffinity(affinity);
    } else if (affinity.hasChanges) {
      if (affinity.id > 0) {
        await patchAffinity(affinity);
      } else {
        await postAffinity(affinity);
      }
    }
  }
}

export class Affinity {
  /**
   * @param {AffinityDto} dto
   */
  constructor(dto) {
    /** @type {number} */
    this.id = dto.id;
    /** @private @type {string} */
    this._name = dto.name;
    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @private @type {boolean} */
    this._isToDelete = false;
  }

  get name() {
    return this._name;
  }

  set name(newValue) {
    if (this._name !== newValue) {
      this._name = newValue;
      this._hasChanges = true;
    }
  }

  get hasChanges() {
    return this._hasChanges;
  }

  get isToDelete() {
    return this._isToDelete;
  }

  copy() {
    return new Affinity(this.toDto());
  }

  toDelete() {
    this._hasChanges = true;
    this._isToDelete = true;
  }

  /** @returns {AffinityDto} */
  toDto() {
    return { id: this.id, name: this.name };
  }
}
