export const CALLING_CODES = supportedCountries();

export class Phone {
  /**
   * @param {number} employeeId
   * @param {boolean?} notification
   * @param {string?} countryCode
   * @param {string?} mobileNumber
   */
  constructor(employeeId, notification = null, countryCode = null, mobileNumber = null) {
    /** @readonly @type {number} */
    this.employeeId = employeeId;
    /** @type {boolean|null} */
    this.notification = notification;
    /** @private @type {{callingCode:string, country:string, countryCode: string, flag:string}|null} */
    this._country = null;
    /** @private @type {string|null} */
    this._mobileNumber = mobileNumber;
    for (const c of CALLING_CODES) {
      if (c.countryCode === countryCode) {
        this._country = c;
        break;
      }
    }
  }

  get mobileNumber() {
    return this._mobileNumber;
  }

  set mobileNumber(newValue) {
    if (newValue === '')
      newValue = null;

    if (newValue !== null) {
      if (newValue[0] !== '+') {
        newValue = '+' + newValue;
      }
    }

    if (newValue !== this._mobileNumber) {
      this._mobileNumber = newValue;
      this.setCountryFromMobile();
    }
  }

  setCountryFromMobile() {
    if (this.mobileNumber) {
      for (const c of CALLING_CODES) {
        if (this.mobileNumber.startsWith(c.callingCode)) {
          this._country = c;
          return;
        }
      }
    }

    this._country = null;
  }

  get country() {
    if (this._country === undefined) {
      this.setCountryFromMobile();
    }

    return this._country;
  }

  set country(newValue) {
    if (newValue === this._country)
      return;
    if (!newValue)
      return;

    if (this.mobileNumber === null) {
      this._mobileNumber = newValue.callingCode;
    } else if (this._country != null) {
      this._mobileNumber = newValue.callingCode + this.mobileNumber.slice(this._country.callingCode.length);
    } else {
      this._mobileNumber = newValue.callingCode + this.mobileNumber.slice(1);
    }

    this._country = newValue;
  }

  isMobileValid() {
    if (!this.mobileNumber)
      return true;

    const regExp = new RegExp(/^\+?[1-9]\d{1,14}$/);
    return regExp.test(this.mobileNumber);
  }

  /** @return {{ notification: boolean; }} */
  toApiNotification() {
    if (this.notification === null) {
      this.notification = false;
    }

    return {
      notification: this.notification,
    };
  }

  /** @return {{ notification: boolean; countryCode: string|null; mobileNumber: string|null; }} */
  toApi() {
    const countryCode = this.country !== null ? this.country.countryCode : null;
    if (this.notification === null) {
      this.notification = false;
    }
    return {
      notification: this.notification,
      countryCode: countryCode,
      mobileNumber: this.mobileNumber
    };
  }
}

function supportedCountries() {
  const data = [
    { countryCode: 'SE', callingCode: '+46', country: 'Sweden', flag: '🇸🇪' },
    { countryCode: 'US', callingCode: '+1', country: 'United States', flag: '🇺🇸' }
  ];

  return Object.freeze(data);
}