/** @readonly @enum {number} */
const CALL_STATE = createCallStates();
/** @readonly @enum {number} */
const CALL_TRACKER_REPORT_TYPE = createCallTrackerReportTypes();
/** @readonly @enum {number} */
const CALL_TYPE = createCallTypes();

/**
 * A call segment.
 * @typedef {Object} CallTrackerEvent
 * @property {string} stamp
 * @property {number} callType - A {@link CALL_TYPE} value.
 * @property {number} callState - A {@link CALL_STATE} value.
 * @property {string} initials
 * @property {number} stationNumber
 * @property {number} type - A {@link CALL_TRACKER_TYPE} value. Can vary during call
 * @property {string} value
 */

/**
 * @typedef {Object} GenesisCallDto
 * @property {number} callId
 * @property {CallTrackerEvent[]} events
 * @property {number} clientNumber
 */

/**
 * @typedef {Object} GenesisCallTrackerReport
 * @property {string} start
 * @property {number} [switchId]
 * @property {GenesisCallDto[]} calls
 */

/**
 * @param {string[]} lines
 * @returns {GenesisCallTrackerReport}
 */
export function parseGenesisCallTrackerTsv(lines) {
  /** @type {GenesisCallDto[]} */
  const calls = [];
  let index = 0;
  let start = '';

  while (index < lines.length) {
    const r = splitTabLine(lines[index]);
    index += 1;
    if (r[0] === 'Start Date:') {
      start = r[1];
    } else if (r[0] === 'Stamp') {
      break;
    }
  }

  while (true) {
    const r = parseCall(lines, index);
    if (r) {
      calls.push(r.call)
      index = r.endIndex + 1;
    } else {
      break;
    }
  }

  return { start, calls };
}

/**
 * @param {string[]} lines
 * @param {number} startIndex
 * @returns {{call:GenesisCallDto; endIndex:number}}
 */
function parseCall(lines, startIndex) {
  const client = splitTabLine(lines[startIndex]);
  if (client.length !== 3) { return undefined; }
  let clientNumber = Number(client[0]);
  if (Number.isNaN(clientNumber)) {
    clientNumber = null;
  }
  const callId = Number(splitTabLine(lines[startIndex + 1])[1]);
  /** @type {CallTrackerEvent[]} */
  const events = [];
  let i = startIndex + 4;

  while (lines[i].length > 0) {
    const segment = splitTabLine(lines[i]);
    const stamp = segment[0];
    const type = parseEnum(CALL_TRACKER_REPORT_TYPE, segment[1]);
    const value = segment[2];
    const callType = parseEnum(CALL_TYPE, segment[3]);
    const initials = segment[5];
    const callState = parseEnum(CALL_STATE, segment[6]);
    const stationNumber = Number(segment[8]);

    events.push({ stamp, type, callType, initials, callState, stationNumber, value });
    i += 1;
  }

  return { call: { callId, clientNumber, events }, endIndex: i };
}

function createCallTypes() {
  const enumObject = {
    Undefined: 0,
    Secretarial: 1, // regular inbound
    Checkin: 2,
    Fetch: 3,
    Scheduled: 4,
    IVR: 5,
    WebScript: 6,
    VoiceMail: 7,
    AutoCall: 8,
    Announcement: 9,
    MergeComm: 10,
    PageConfirmation: 11,
    SmartPaging: 12,
    ChangeClient: 13,
    AgentAudio: 14,
    Rauland: 15,
    AutoAttendant: 16,
    ListingLookup: 17,
    Dispatch: 18, //include
    ParkOrbit: 19,
    VMCallBack: 20,
    Cue: 21, //include
    Repeat: 22, //include
    ConfBridge: 23,
    Orbit: 24,
    Elevator: 25,
    Pers: 26,
  };

  return Object.freeze(enumObject);
}

function createCallStates() {
  const enumObject = {
    Unknown: 0,
    Disposed: 1,
    Disc: 2,
    Ring: 3,
    Talk: 4,
    Talk1: 5,
    Talk2: 6,
    Conference: 7,
    Hold: 8,
    InProgress: 9,
    VoiceMail: 10,
    OutboundQueue: 11,
    Auto: 12,
    AutoHold: 13,
    Patch: 14,
    Bridge: 15,
    MaxStates: 16,
  };

  return Object.freeze(enumObject);
}

function createCallTrackerReportTypes() {
  const enumObject = {
    None: 0,
    'New Call': 1, // *
    'End Call': 2, // *
    Distribute: 3, // *
    Answered: 4, // *
    Hold: 5, // *
    'Auto Answer': 6, // *
    'Dial Start': 7, // *
    'Dial End': 8, // *
    Overdial: 9, //
    'Agent Hang Up': 10, // *
    'System Hang Up': 10, // * kanske
    'Hung Up': 11, // *
    Change: 12, // *
    Reassign: 13,
    Park: 14, // *
    Conference: 15, // *
    Patch: 16, // *
    Transfer: 17,
    'Digit Menu Entry': 18, // *
    'Speech Rec': 19,
    'Send To Op': 20,
    'Screen Start & Stop': 21, // kanske 'var SmartPage'
    'Send To vm': 22, // *
    'Script Action': 23,
    Abandon: 24, // *
    'Switch Error': 25,
    Orbit: 26,
    'Bridge Add': 27,
    'Bridge Del': 28,
    Voci: 29,
    'AI Insight': 30,
    Limited: 31,
    'Disc Alert & override': 32, // *
    'Logger Start & Stop': 33, // *
    'Video Recording': 34,
    'Invalid Source': 35,
    'Hold Alert': 36,
  };

  return Object.freeze(enumObject);
}

/**
 * @param {Readonly<any>} enumObj
 * @param {string} name
 */
function parseEnum(enumObj, name) {
  /** @type {number} */
  let value = enumObj[name];
  if (value === undefined) {
    let indexOfLeft = name.indexOf('[');
    let indexOfRight = name.indexOf(']');
    if (indexOfLeft >= 0 && indexOfRight > indexOfLeft) {
      value = Number(name.substring(indexOfLeft + 1, indexOfRight));
      if (Number.isNaN(value)) {
        value = null;
      }
    } else {
      value = null;
    }
  }
  return value;
}

/**
 * @param {string} line
 */
function splitTabLine(line) {
  const r = line.split('\t');
  for (let i = 0; i < r.length; i++) {
    if (r[i].length > 1 && r[i][0] === '"') {
      r[i] = r[i].substring(1, r[i].length - 1);
    }

  }
  return r;
}