// @ts-check
import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { isSubset } from '@/js/dn-helper';
import { Shift } from '@/model/dn-shift.js';

/**
* @typedef {Object} OptimizableShiftDtoType
* @property {number[]} dayList
* @typedef {import("@/model/dn-shift.js").ShiftDto & OptimizableShiftDtoType} OptimizableShiftDto
*/

/**
* @typedef {{id:number|null, shifts:OptimizableShiftDto[], employeeIdList:number[]}} OptimizableShiftGroupDto
*/

/**
 * @param {number} ccId
 */
export async function getShiftBags(ccId) {
  /** @type {OptimizableShiftGroupDto[]} */
  const dto = await fetchAndCheckJson('optimizable-shift?ccId=' + ccId, 'GET');
  return new ShiftBags(dto);
}

/**
 * @param {OptimizableShiftGroupDto[]} rows
 */
function createShiftBagByEmp(rows) {
  /** @type {Map<number, OptimizableShiftGroup>} */
  const byEmpId = new Map();
  for (const r of rows) {
    const optimizableShiftGroup = new OptimizableShiftGroup(r);
    for (const employeeId of optimizableShiftGroup.employeeIdList) {
      byEmpId.set(employeeId, optimizableShiftGroup);
    }
  }
  return byEmpId;
}

export class ShiftBags {
  /**
   * @param {OptimizableShiftGroupDto[]} dto
   */
  constructor(dto) {
    /** @readonly @type {OptimizableShiftGroupDto[]} */
    this.dto = dto;
  }

  get byEmp() {
    if (this._byEmp === undefined) {
      /** @private @type {Map<number, OptimizableShiftGroup>} */
      this._byEmp = createShiftBagByEmp(this.dto);
    }

    return this._byEmp;
  }
}

export class OptimizableShiftGroup {
  /**
   * @param {OptimizableShiftGroupDto} dto
   */
  constructor(dto) {
    /** @private @type {number|null} */
    this._id = dto.id;
    /** @type {OptimizableShift[]} */
    this.shifts = dto.shifts.map(s => new OptimizableShift(s));
    /** @readonly @type {number[]}  */
    this.employeeIdList = dto.employeeIdList.slice();
  }

  get id() {
    return this._id;
  }

  clone() {
    return new OptimizableShiftGroup(this.toDto());
  }

  /**
   * @param {Date} dt
   * @param {import("@/model/dn-shift.js").DayBounds} dayBounds
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
   */
  hasAllowedShifts(dt, dayBounds, taskTypeMap) {
    return this.shifts.some(x => x.isAllowed(dt, dayBounds, taskTypeMap));
  }

  /**
   * @param {Date} dt
   * @param {import("@/model/dn-shift.js").DayBounds} dayBounds
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
   */
  getBounds(dt, dayBounds, taskTypeMap) {
    const bounds = { stMin: 96, fiMax: 0 };
    for (const shift of this.shifts) {
      if (shift.isAllowed(dt, dayBounds, taskTypeMap)) {
        shift.setMaxBounds(bounds, taskTypeMap);
      }
    }
    return bounds;
  }

  isValid() {
    return this.shifts.every(x => x.isValid());
  }

  /**
   * @param {number[]} checkedEmps
   */
  async save(checkedEmps) {
    const dto = this.toDto();
    checkedEmps.sort();
    this.employeeIdList.sort();
    if (!isSubset(checkedEmps, this.employeeIdList)) {
      dto.id = null;
    }

    if (dto.id === null) {
      for (const shift of dto.shifts) {
        shift.id = null;
      }
    }

    dto.employeeIdList = checkedEmps;
    await fetchAndCheckJson('optimizable-shift', 'POST', dto);
  }

  /** @returns {OptimizableShiftGroupDto} */
  toDto() {
    return {
      id: this.id,
      shifts: this.shifts.map(x => x.toDto()),
      employeeIdList: this.employeeIdList
    };
  }
}

export class OptimizableShift extends Shift {
  /**
   * All units in quarter hours
   * @param {OptimizableShiftDto} data
   */
  constructor(data) {
    super(data)
    /** @type {number[]} If empty list is valid for all days. Values are Sunday = 0, Monday = 1, ... */
    this._dayList = (data.dayList === null || data.dayList.length === 0) ? [0, 1, 2, 3, 4, 5, 6] : data.dayList;
  }

  get dayList() {
    return this._dayList;
  }

  set dayList(value) {
    this.setValue('_dayList', value);
  }

  /**
   * @param {Date} dt
   * @param {import("@/model/dn-shift.js").DayBounds} dayBounds
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
   */
  isAllowed(dt, dayBounds, taskTypeMap) {
    return this.isAllowedDate(dt) && this.isDayBoundsOk(dayBounds, taskTypeMap) && (this.isFix || this.stMin < this.fiMax);
  }

  /**
   * @private
   * @param {Date} dt
   */
  isAllowedDate(dt) {
    if (this.dayList.length === 0)
      return true;
    return this.dayList.includes(dt.getDay());
  }

  toDto() {
    /** @type {OptimizableShiftDto} */
    // @ts-ignore
    const dto = super.toDto();
    if (this._dayList.length === 7) {
      dto.dayList = [];
    } else {
      dto.dayList = this.dayList;
    }
    return dto;
  }
}

/**
 * @param {number} ttId
 */
export function createNewOptimizableShift(ttId) {
  return new OptimizableShift({ id: null, dayList: [], fixTasks: [], flexTasks: [{ st: 24, stSpan: 0, fi: 72, fiSpan: 0, dur: 48, durSpan: 0, step: 1, taskTypeId: ttId, kind: 0 }] });
}

/**
 * @param {number[]} checkedEmps
 */
export async function deleteShiftBag(checkedEmps) {
  /** @type {{id:number|null, shifts:OptimizableShiftDto[], employeeIdList:number[]}} */
  const data = {
    id: null,
    shifts: [],
    employeeIdList: checkedEmps
  };
  await fetchAndCheckJson('optimizable-shift', 'POST', data);
}