import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/**
 * @param {number} ccId
 */
export async function getCustomShifts(ccId) {
  /** @type {{id:number, name:string, tasks: any[], cmd:number}[]} */
  const rows = await fetchAndCheckJson(`customshifts?ccId=${ccId}`, 'GET');
  return rows.map(x => new CustomShift(null, x.id, x.name, x.tasks, ccId, x.cmd));
}

export async function createNewCustomShift(newCustomShift) {
  await fetchAndCheckJson(`customshifts`, 'POST', { name: newCustomShift.name, ccid: newCustomShift.ccid, tasks: newCustomShift.tasks, cmd: newCustomShift.cmd }).then(id =>
    newCustomShift.id = id)
}

export async function deleteCustomShift2(customShift) {
  await fetchAndCheckJson(`customshifts/${customShift.id}`, 'DELETE');
}

export class CustomShift {
  constructor(usershift, id, name, tasks, contactcenterIds, cmd) {
    if (usershift) {
      this._id = usershift.id
      this._name = usershift.name
      this._tasks = []
      this._contactcenterIds = usershift.contactcenterIds
      this._cmd = usershift.cmd
    } else {
      this._id = id
      this._name = name
      this._tasks = tasks
      this._contactcenterIds = contactcenterIds
      this._cmd = cmd
    }
    this._hasChanges = false
    this._isToDelete = false
  }

  get id() {
    return this._id
  }

  get name() {
    return this._name
  }

  set name(newValue) {
    this._name = newValue
    this._hasChanges = true
  }

  get tasks() {
    return this._tasks
  }

  set tasks(newValue) {
    this._tasks = newValue
    this._hasChanges = true
  }

  get contactcenterIds() {
    return this._contactcenterIds
  }

  set contactcenterIds(newValue) {
    this._contactcenterIds = newValue
    this._hasChanges = true
  }

  get cmd() {
    return this._cmd
  }

  set cmd(newValue) {
    this._cmd = newValue
    this._hasChanges = true
  }

  get hasChanges() {
    return this._hasChanges
  }
  confirmChanges() { this._hasChanges = false }

  get isToDelete() {
    return this._isToDelete
  }
  toDelete() { this._isToDelete = true }
}