// @ts-check
import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/**
 * @param {number} numberOfDays
 * @param {Date} startDate
 */
export async function getCallStatistics(numberOfDays, startDate,percentOfAbandonsToInclude) {
  /** @type {{data:{cgId:number, noc:number[], ht:number[]}[], isCalculating: boolean}} */
  const result = await fetchAndCheckJson(`call-statistics`, 'POST', { startTimeUTC: startDate, quarters: 96 * numberOfDays, ipoa: percentOfAbandonsToInclude });
  return result;
}

export async function recalculateCallGroups() {
  await fetchAndCheckJson(`call-statistics?recalc=1`, 'POST');
}
