import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { fromISODate, getDayDiff, isSubset, toISODate } from '@/js/dn-helper';
import { Shift } from '@/model/dn-shift.js';
import {getShiftWorkTime} from '@/model/dn-shift.js';

/**
* @typedef {{id?:number, st:string, weeks:number, employeeIdList:number[], days:{dayIndex:number, shiftId:number}[], shifts:import("@/model/dn-shift.js").ShiftDto[]}} RotationDto
*/

/**
* @typedef {Object} SaveRotationDtoType
* @property {number[]} days
* @typedef {import("@/model/dn-shift.js").ShiftDto & SaveRotationDtoType} SaveRotationShiftDto
*/

function createNewRotation() {
  return new Rotation({ st: '2020-01-06', weeks: 0, shifts: [], employeeIdList: [], days: [] });
}

/**
 * @param {number} ccId
 * @returns {Promise<Rotations>}
 */
export async function getRotations(ccId) {
  const dto = await fetchAndCheckJson('rotation?ccId=' + ccId, 'GET');
  return new Rotations(dto);
}

/**
 * @param {RotationDto[]} rows
 */
function createRotationByEmp(rows) {
  /** @type {Map<number, Rotation>} */
  const byEmpId = new Map();
  for (const r of rows) {
    const rotation = new Rotation(r);
    for (const employeeId of rotation.employeeIdList) {
      byEmpId.set(employeeId, rotation);
    }
  }

  return byEmpId;
}

export class Rotations {
  /**
   * @param {RotationDto[]} dto
   */
  constructor(dto) {
    /** @readonly @type {RotationDto[]} */
    this.dto = dto;
  }

  get byEmp() {
    if (this._byEmp === undefined) {
      /** @private @type {Map<number, Rotation>} */
      this._byEmp = createRotationByEmp(this.dto);
    }

    return this._byEmp;
  }

  /**
   * @param {number} employeeId
   */
  create(employeeId) {
    const rotation = this.byEmp.get(employeeId);
    if (rotation) {
      for (const r of this.dto) {
        if (r.id === rotation.id) {
          return new Rotation(r);
        }
      }
    } else {
      return createNewRotation();
    }
  }
}

export class Rotation {
  /**
 * @param {RotationDto} dto
 */
  constructor(dto) {
    /** @type {Map<number, import("@/model/dn-shift.js").Shift>} */
    const byShiftId = new Map();
    for (const s of dto.shifts) {
      byShiftId.set(s.id, new Shift(s));
    }

    /** @type {Shift[]} */
    const shifts = new Array(dto.weeks * 7).fill(null);
    for (const d of dto.days) {
      shifts[d.dayIndex] = byShiftId.get(d.shiftId);
    }

    /** @private @type {number|null} */
    this._id = dto.id ? dto.id : null;
    /** @type {number} */
    this.weeks = dto.weeks;
    /** @type {Date} */
    this.st = fromISODate(dto.st);
    /** @type {Shift[]} */
    this.shifts = shifts;
    /** @readonly @type {number[]}  */
    this.employeeIdList = dto.employeeIdList;
  }

  get id() {
    return this._id;
  }

  /**
   * @param {Date} dt
   */
  getShift(dt) {
    if (dt.getTime() < this.st.getTime())
      return null;
    const days = getDayDiff(dt, this.st);
    const index = days % (7 * this.weeks);
    return this.shifts[index];
  }

  /**
   * @param {Shift[]} shiftByDay
   * @param {number[]} checkedEmps
   */
  async save(shiftByDay, checkedEmps) {
    let id = this.id;
    checkedEmps.sort();
    if (!isSubset(checkedEmps, this.employeeIdList)) {
      id = null;
    }

    const shifts = [];
    /** @type {SaveRotationShiftDto[]} */
    const shiftDtos = [];
    for (let dayIndex = 0; dayIndex < shiftByDay.length; dayIndex++) {
      const s = shiftByDay[dayIndex];
      if (s && !s.isEmpty) {
        let found = false;
        for (let i = 0; i < shifts.length; i++) {
          const s2 = shifts[i];
          if (s.isEqual(s2)) {
            found = true;
            shiftDtos[i].days.push(dayIndex);
            break;
          }
        }

        if (!found) {
          shifts.push(s);
          /** @type {SaveRotationShiftDto} */
          // @ts-ignore
          const dto = s.toDto();
          dto.days = [dayIndex];
          shiftDtos.push(dto);
        }
      }
    }

    if (id === null) {
      for (const s of shiftDtos) {
        s.id = null;
      }
    } else {
      const idList = [];
      for (const s of shiftDtos) {
        if (s.id !== null) {
          if (idList.includes(s.id)) {
            s.id = null;
          } else {
            idList.push(s.id);
          }
        }
      }
    }

    const data = {
      id: id,
      st: toISODate(this.st),
      weeks: shiftByDay.length / 7,
      shifts: shiftDtos,
      employeeIdList: checkedEmps
    };
    await fetchAndCheckJson('rotation', 'POST', data);
  }

    /**
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
   * @param {import("@/model/dn-break-settings.js").BreakSettings[]} breakSettings
   */
  getWeeklyWorkTime(breakSettings,taskTypeMap){
    if(breakSettings&&taskTypeMap){
      for(const bs of breakSettings){
        bs.calculateUnpaidDuration(taskTypeMap)
      }
    }
    let minWork =0 
    let maxWork =0
    let includeFlexShift=false
    for (const sh of this.shifts) {
      if(sh){
        let work = getShiftWorkTime(sh,taskTypeMap,breakSettings)
        minWork+=work.minWork
        maxWork+=work.maxWork
        if(work.includeFlexShift){includeFlexShift=true}
      }
    }
    if(this.weeks>1){
      minWork=minWork/this.weeks
      maxWork=maxWork/this.weeks
    }
   return {'minWork':minWork,'maxWork':maxWork,'includeFlexShift':includeFlexShift}
  }
}

/**
 * @param {number[]} checkedEmps
 */
export async function deleteRotation(checkedEmps) {
  /** @type {RotationDto} */
  const data = {
    id: null,
    st: toISODate(new Date()),
    days: [],
    weeks: 0,
    shifts: [],
    employeeIdList: checkedEmps
  };
  await fetchAndCheckJson('rotation', 'POST', data);
}