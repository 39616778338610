
var moment = require("moment-timezone");
import * as dnLocalStorage from './dn-localStorage.js';
import { addDays, getNumberOfDaysFromIntervalMinusOne, getStartOfWeek } from './dn-helper.js';
import * as dn_uiHelper from "@/js/dn-uihelper.js";
import * as dnModel from '@/model/dn-model.js';
import { useDataStore } from "@/stores/dataStore.js";
import { createSchedule, getEmpWorkMap,getEmpBreakMap, getShiftBounds,getEmpWorkArray } from '@/model/dn-employee-schedule.js';
import { useScheduleStore } from '@/stores/scheduleStore.js';
import { Task } from '@/model/dn-task.js';
import { TASK_KIND } from '@/model/dn-tasktype.js'
import { getElapsedTimeString } from "@/js/dn-helper";



export function getTimeNow(){
  let loginInfo = dnLocalStorage.getLoginInfo();
  if(loginInfo && loginInfo.customerSchema == 'demo'){return moment([2022,4,3]).toDate()}
  if(loginInfo && loginInfo.customerSchema.substring(0,5)=='demo2'){return moment([2023,8,10]).toDate()}
  return moment(new Date()).startOf('day').toDate()
}

export function getInitialDateInterval(){
  const scheduleStore = useScheduleStore();
  //Whole days in local timezone
   return [getTimeNow(),dn_uiHelper.addDays(getTimeNow(),scheduleStore.scheduleOptions.numberOfDaysInSchedule-1)]
}

export function getSpecialTaskType(subtype) {
  let x = {}
  const dataStore = useDataStore();
  dataStore.taskTypeMap.forEach(element => { if (element.subtype == subtype) { x = element } })
  return x;
}

/**
 * Calculate bounderies of a workShiftFiltered, presented as a Task with tasktype null
 * @param {Date} dt
 * @param {dnModel.Task[]} possibleTasks tasks that connect over midnight
 * @param {dnModel.Task[]} allTasks
 */
export function getShift(dt, possibleTasks, allTasks = undefined) {
  const shift = getShiftBounds(dt, possibleTasks, allTasks);
  return new Task({empid:null, st:shift.st, fi:shift.fi, tasktypeId:null});
}

/**
 * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule[]} schedule
 * @param {Map<number, dnModel.TaskType>} taskTypeMap
 * @param {Date} dt
 * @param {import("@/model/dn-employee-cc.js").EmployeeCallcenters} employeeCallcenters
 * @param {number} ccId
 * @param {number[]} [taskTypeIds]
 */
export function getEmpWorkSum(schedule, taskTypeMap, dt, employeeCallcenters, ccId, taskTypeIds=null) {
  const length = 96;
  const empWorkMap = getEmpWorkMap(schedule, taskTypeMap, dt, 1, length, employeeCallcenters, ccId, taskTypeIds);
  const dtKey = dn_uiHelper.getShortDate(dt);
  /** @type {number[]} */
  const empSumArray = Array(length).fill(0)
  for (const pair of empWorkMap) {
    const empWork = pair[1].get(dtKey)
    for (let i = 0; i < length; i++) {
      empSumArray[i] += empWork[i]
    }
  }

  return empSumArray;
}

/**
 * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule[]} schedule
 * @param {Date} dt
 * @param {import("@/model/dn-employee-cc.js").EmployeeCallcenters} employeeCallcenters
 * @param {number} ccId
 * @param {number[]} [taskTypeIds]
 */
export function getEmpBreakSum(schedule, dt, employeeCallcenters, ccId, taskTypeIds=null) {
  const length = 96;
  const empBreakMap = getEmpBreakMap(schedule, dt, 1, length, employeeCallcenters, ccId);
  const dtKey = dn_uiHelper.getShortDate(dt);
  /** @type {number[]} */
  const empSumBreakArray = Array(length).fill(0)
  for (const pair of empBreakMap) {
    const empBreak = pair[1].get(dtKey)
    for (let i = 0; i < length; i++) {
      empSumBreakArray[i] += empBreak[i]
    }
  }

  return empSumBreakArray;
}

/**
 * @param {string | Date} st
 * @param {string | Date} fi
 * @param {number} ttid
 * @param {number} empid
 */
export function getTask(st, fi, ttid, empid=null) {
  const newTask = new Task({empid, st, fi, tasktypeId:ttid});
  if(empid){
    useScheduleStore().scheduleTasks.add(newTask);
  }
  return newTask
}

  //
  export function get24hourShift(dt) {
    return {
      st: new Date(moment(dt)),
      fi: new Date(moment(dt).add(24, "hours")),
      dur:24
    };
  }

  /**
 * @param {dnModel.Task} t
 * @param {{st:Date;fi:Date}[]} workShiftFiltered
 */
  export function moveEntireShift(t,workShiftFiltered){
        let change =  moment.duration( moment(t.st).diff(t.lastSt )).asHours();
        if (t.tasktypeId==null){

            for(let i=1;i<workShiftFiltered.length;i++){
                let t =workShiftFiltered[i];
                t.st = moment(t.st).add(change,"hours").toDate();
                t.fi = moment(t.fi).add(change,"hours").toDate();
            }
        }
   }

/**
 * @param {{ tasks: dnModel.Task[]; breaks: dnModel.Task[]; payments: dnModel.Task[]; absences: dnModel.Task[]; }} empTasks
 * @param {{st:Date, fi:Date}} focusedShift
 * @param {string} type
 */
function getTaskList(empTasks,focusedShift,type){
  let taskList =[]
  switch(type){
    case 'task':
      taskList=empTasks.tasks.filter(isInShift);//updated taskList
      break;
    case 'break':
      taskList=empTasks.breaks.filter(isInShift);//updated taskList
      break;
    case 'payment':
      taskList=empTasks.payments.filter(isInShift);//updated taskList
      break;
    case 'absence':
      taskList=empTasks.absences.filter(isInShift);//updated taskList
      break;
    case 'log':
      taskList=empTasks.log.filter(isInShift);//updated taskList
      break;
    default:
      break;
  }
  return taskList

  /**
   * @param {dnModel.Task} t
   */
  function isInShift(t) {
    return (t.st >= focusedShift.st && t.fi <= focusedShift.fi)&&(!t.isToDelete);
  }
}

export function validateAndCorrectWorkShift(activeTask,empTasks,dt){
  const dataStore = useDataStore();
  //active task
  let tt = dataStore.taskTypeMap.get(activeTask.tasktypeId)
  let st=moment(activeTask.st);//all operations using moment.js
  let fi= moment(activeTask.fi);
  let lastSt=moment(activeTask.lastSt);
  let lastFi= moment(activeTask.lastFi);
  let focusedShift = getShift(dt, empTasks.tasks);

  

  if(tt){
    let taskList=getTaskList(empTasks,focusedShift,tt.type);//updated taskList
    for (let i = 0; i < taskList.length; i++) {
       if(taskList[i]!=activeTask){
       let tti = dataStore.taskTypeMap.get(taskList[i].tasktypeId)
       if(tti){
        let sti =moment(taskList[i].st)
        let fii =moment(taskList[i].fi)
        if(st.isSameOrBefore(sti)&&fi.isSameOrAfter(fii)){
         removeTask(taskList[i])
        }
        if(st.isBefore(fii)&&fi.isSameOrAfter(fii)&&st.isAfter(sti)){
          taskList[i].fi=st.toDate()
        }

        if(st.isAfter(sti)&&fi.isBefore(fii)&&tt!=tti){
            taskList[i].fi=st.toDate();
            empTasks.tasks.push(getTask(fi.toDate(),fii.toDate(),tti.id,empTasks.emp._id));
            break;
        }

        
        if(st.isAfter(sti)&&fi.isBefore(fii)){
          removeTask(activeTask)
        }


        if(st.isSameOrBefore(sti)&&fi.isAfter(sti)&&fi.isBefore(fii)){
          taskList[i].st=fi.toDate()
        }
        //Conected tasks
        if(fii.isSame(lastSt)&&st.isAfter(fii)){
          if(tt!=tti){taskList[i].fi=st.toDate();}
        }
        if(sti.isSame(lastFi)){
          if(fi<fii){
            if(tt!=tti){taskList[i].st=fi.toDate();}
          }
        }
      }
      }
    }



    //Join tasks of same tt that are connecting
    //Removes overlapping tasks

    taskList=getTaskList(empTasks,focusedShift,tt.type);//updated taskList
      for (let i = 0; i < taskList.length; i++) {
        let sti =moment(taskList[i].st)
        let fii =moment(taskList[i].fi)

          for (let j = 0; j < taskList.length; j++) {
              if(j>i){
                let stj =moment(taskList[j].st)
                let fij =moment(taskList[j].fi)
                if(stj.isBefore(sti)&&fij.isAfter(sti)&&fij.isBefore(fii)){
                  taskList[j].fi=sti.toDate()
                                  }
                if(stj.isBefore(sti)&&fij.isSame(sti)&&taskList[j].tasktypeId==taskList[i].tasktypeId){
                  taskList[i].st=stj.toDate()
                  removeTask(taskList[j])
                }
                if(stj.isSame(sti)&&stj.isAfter(sti)&&taskList[j].tasktypeId==taskList[i].tasktypeId){
                  removeTask(taskList[j])
                }
                if(stj.isBefore(fii)&&fij.isAfter(fii)&&stj.isAfter(sti)){
                  taskList[j].st=fii.toDate()
                }
                if(stj.isSame(fii)&&fij.isAfter(fii)&&taskList[j].tasktypeId==taskList[i].tasktypeId){
                  taskList[i].fi=fij.toDate()
                  removeTask(taskList[j])
                }
                if(fij.isSame(fii)&&stj.isBefore(fii)&&taskList[j].tasktypeId==taskList[i].tasktypeId){
                  removeTask(taskList[j])
                }
                if(stj.isAfter(sti)&&fij.isBefore(fii)){
                  removeTask(taskList[j])
                }
              }
           }
      }
    //}
}

function removeTask(x){
  x.toDelete()
}
}

export function getShiftText(shift, refDate){
  let shiftText = "";
  if(shift.tasks.length>0){
      const timeInterval = Math.abs((shift.tasks[shift.tasks.length-1].fi-shift.tasks[0].st))
      if(shift.cmd){
      shiftText=moment(refDate).add(shift.tasks[0].st,'minutes').format('HH:mm')+ " "+
        shift.name + " ("+
      Math.round(timeInterval/60,2)+ " h)" + " cmd: ctrl " + shift.cmd
      } else {
      shiftText=moment(refDate).add(shift.tasks[0].st,'minutes').format('HH:mm')+ " "+
        shift.name + " ("+
      Math.round(timeInterval/60,2)+ " h)"
      }

  }
  return shiftText
}

export function getSelectionKey(dt,empid){
  return moment(dt).startOf('day')+'_'+empid
}
export function getEmpDt(dt,emp){
  return {emp:emp,dt: moment(dt).startOf('day').date}
}
export function getDateKey(dt){
  return moment(dt).date.yyyymmdd()
}

/**
 * @param {string} selectionKey
 */
export function getRequestForSchedule(selectionKey) {
  const keyParts = selectionKey.split('_');
  const employeeId = Number(keyParts[1]);
  const ts = Number(keyParts[0]);
  const scheduleStore = useScheduleStore();
  const filter = scheduleStore.scheduleRequestSettings;
  const scheduleOptions = scheduleStore.scheduleOptions;
  const future = scheduleOptions.dateInterval[1] ? addDays(scheduleOptions.dateInterval[1], 1) : new Date();
  const selectedEmpId = scheduleOptions.selectedEmpId;
  let scheduleRequest = scheduleStore.scheduleRequestData.bySelectionKey.get(selectionKey);
  let isPoster = false;
  let countOrOrder = 0;
  if (scheduleRequest && scheduleRequest.kind === dnModel.REQUEST_KIND.post) {
    isPoster = scheduleRequest.employees[0].employeeId === employeeId;
    if (isPoster) {
      countOrOrder = scheduleRequest.employees.length - 2;
    } else {
      countOrOrder = scheduleRequest.employees.findIndex(x => x.employeeId === employeeId) - 1;
    }
    if (!isPoster) {
      if (scheduleRequest.approved === false) {
        scheduleRequest = undefined;
      } else if (scheduleRequest.approved === true && scheduleRequest.employees[1].employeeId !== employeeId) { 
        scheduleRequest = undefined;
      }
    }
  }

  const isAgentLogin = useDataStore().currentUser.isAgent;
  if (scheduleRequest) {
    if (!(scheduleRequest.kind === dnModel.REQUEST_KIND.post || scheduleRequest.isFilterOk(filter, future, selectedEmpId) || isAgentLogin)) {
      scheduleRequest = undefined;
    }
  }
  
  const availEmpRequests = scheduleStore.availabilityRequests.getByEmp(employeeId);
  /** @type {import("@/model/dn-request-item.js").RequestItem} */
  let requestItem = scheduleRequest; 
  if (availEmpRequests) {
    const now = getTimeNow();
    const requestOnDay = availEmpRequests.byDate.get(ts);
    if (requestOnDay) {
      for (const r of requestOnDay) {
        if (!isAgentLogin) {
          if (!r.isFilterOk(filter, future, selectedEmpId, scheduleStore.employees.list, now)) {
            continue;
          }
        }
        let best = requestItem === undefined;
        if (!best) {
          if (requestItem.isOpen === r.isOpen) {
            best = r.created < requestItem.created;
          } else {
            best = r.isOpen;
          }
        }
        if (best) {
          requestItem = r;
          scheduleRequest = undefined;
        } 
      }
    }
  }

  if (!requestItem) { return undefined; }

  let kind = dnModel.REQUEST_KIND.availability;
  if (scheduleRequest) {
    kind = scheduleRequest.kind;
  }

  return { isPoster, countOrOrder, kind, requestItem};
}

/**
 * @param {number} numberOfDays
 * @param {Date} stDate
 * @param {dnModel.Task[]} scheduleTasks
 * @param {dnModel.Employee[]} employees
 * @param {boolean} includeInactive
 */
export function getSchedule(numberOfDays, stDate, scheduleTasks, employees, includeInactive) {
  const dataStore = useDataStore();
  const taskTypes = dataStore.taskTypes;
  return createSchedule(numberOfDays, stDate, scheduleTasks, taskTypes, employees, includeInactive)
}

/**
 * @param {number} empId
 * @returns {import("@/model/dn-employee-schedule.js").EmployeeSchedule}
 */
export function getScheduleFromStore(empId) {
  const schedules = useScheduleStore().schedule.list;
  for (let i = 0; i < schedules.length; i++) {
    if (schedules[i].emp.id == empId) {
      return schedules[i]
    }
  }
  return undefined;
}

export function getScheduleRowIndex(empid) {
  let index = 0;
  const currentSortMap = useScheduleStore().scheduleOptions.currentSortMap;
  for (let keySortMap of currentSortMap.keys()) {
    let value = currentSortMap.get(keySortMap)
    if (empid == value.id) { index = keySortMap }
  }
  return index
}

/**
 * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} schedule
 */
export function workShiftDetails(schedule){
  const dtFormat = new Intl.DateTimeFormat(useDataStore().language, { hour: 'numeric', minute: '2-digit' });
  const dt = schedule.focusedDate;
  const nextDt = dn_uiHelper.addDays(dt, 1);
  const shiftBounds = getShiftBounds(schedule.focusedDate, schedule.tasks);

  let details =[]
  for (const t of schedule.absences) {
    if(t.st.getTime() >= dt.getTime() && t.st.getTime() < nextDt.getTime()){ details.push(getTaskText(t))}
  }
  for (const t of schedule.tasks) {
    if(t.st.getTime() >= shiftBounds.st.getTime() && t.st.getTime() < shiftBounds.fi.getTime()){ details.push(getTaskText(t))}
  }
  for (const t of schedule.breaks) {
    if(t.st.getTime() >= shiftBounds.st.getTime() && t.st.getTime() < shiftBounds.fi.getTime()){ details.push(getTaskText(t))}
  }
  for (const t of schedule.payments) {
    if(t.st.getTime() >= dt.getTime() && t.st.getTime() < nextDt.getTime()){ details.push(getTaskText(t))}
  }

   return(details)

  /**
   * @param {dnModel.Task} t
   */
  function getTaskText(t){
    const dataStore = useDataStore();
    let tt = dataStore.taskTypeMap.get(t.tasktypeId)
    return tt.name+`: ${dtFormat.format(t.st)} - ${dtFormat.format(t.fi)}`;
  }
}

export function arrangeReportWeekDayStart(type,list,date){
  const weekDayNum = date.getDay();
  let steadyCols = list.slice(0,3);
  let weekDaysCols = list.slice(3);
  let completeWeek;
  if(type == 'table'){
      completeWeek = [{name:'sun','width':400}].concat(weekDaysCols.concat(weekDaysCols));
      return steadyCols.concat(completeWeek.slice(weekDayNum,weekDayNum+7))
  }
  if(type == 'weekDayData'){
      completeWeek = steadyCols.concat(weekDaysCols.concat(list));
      return completeWeek.slice(weekDayNum,weekDayNum+7)
  }
  else if(type == 'pdfFormating' || type == 'csvFormating'){
      completeWeek = [{name:'sun',width:300}].concat(weekDaysCols.concat(weekDaysCols));
      return steadyCols.concat(completeWeek.slice(weekDayNum,weekDayNum+7));
  }
}

export function getAgentAppRowHeight() {
 return parseInt((window.innerHeight-350)/7)
}

/**
 * @param {Date} dt
 */
export function scheduleIsPublished(dt) {
  return dt <= getLatestPublishedDate();
}
export function erliestRequestDate(){
  const dataStore = useDataStore();
  return dn_uiHelper.addDays(getTimeNow(),dataStore.generalEmpAppSettings.requestClosedNumberOfDays)
}

export function getLatestPublishedDate() {
  const nowDt = getTimeNow();
  const dataStore = useDataStore();
  const generalEmpAppSettings = dataStore.generalEmpAppSettings;
  const weekDay = generalEmpAppSettings.publishingWeekDay
  const numberOfDays = generalEmpAppSettings.publishingNumberOfDays
  let latestDate= new Date()
  if(generalEmpAppSettings.publishingFixedDate){
    latestDate=generalEmpAppSettings.publishingFixedDate
  }else{
    let daysToAdd
    if (nowDt.getDay() <= weekDay) {
      daysToAdd = weekDay - 8;
    } else {
      daysToAdd = weekDay - 1;
    }
    latestDate = dn_uiHelper.addDays(getStartOfWeek(nowDt), numberOfDays + daysToAdd);
  }
  
  return latestDate
}

/**
 * @param {Date[]} dateInterval
 */
export function scheduleisAllowedToOptimize(dateInterval){
  const currentUser = useDataStore().currentUser;
  return !currentUser.isSupervisor||(currentUser.isSupervisor) && dateInterval[1] <=dn_uiHelper.addDays( getLatestPublishedDate(),0)
}


/**
 * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} r
 */
export function scheduleRowIsIncluded(r){
  const scheduleStore = useScheduleStore();
  if(!scheduleStore.scheduleOptions.scheduleFilter.show){return true}
  let filter=scheduleStore.scheduleOptions.scheduleFilter
  let fi=dn_uiHelper.addDays(scheduleStore.scheduleOptions.dateInterval[1],1)
  let isIncludedByName=false
  let isIncludedBySkill=false
  let isIncludedByTag = false
  let isIncludedByTask = false

  if(filter.searchText.length>0){
    if( r.emp.name.toLowerCase().includes(filter.searchText.toLowerCase())){isIncludedByName=true}
  }else{isIncludedByName=true}


  if(filter.callGroups.length>0){
    for(let c=0;c<filter.callGroups.length;c++){
      if( r.emp.skills.includes(filter.callGroups[c].id)){isIncludedBySkill=true}
    }
  }else{isIncludedBySkill=true}

  if(filter.taskTypes.length>0){
    for(let t=0;t<filter.taskTypes.length;t++){
      if( r.tasks.concat(r.absences).filter(x => x.tasktypeId === filter.taskTypes[t].id&& x.st<fi&&!x.isToDelete).length>0 ){isIncludedByTask=true}
    }
  }else{isIncludedByTask=true}

  if(filter.tagIds.length>0){
    for(let t=0;t<filter.tagIds.length;t++){
      if( r.emp.tagIds.includes(filter.tagIds[t])){isIncludedByTag=true}
    }
  }else{isIncludedByTag=true}




  return isIncludedByName&&isIncludedBySkill&&isIncludedByTask&&isIncludedByTag
}

export function singleSelectionIsVisible(scheduleOptions,schedule,dt){
  let returnValue=true
  if(scheduleOptions.scheduleFilter.show){
    let filterdSched=schedule.filter(scheduleRowIsIncluded)
    let filterdSched2=filterdSched.filter(r => r.emp.id==scheduleOptions.selectedEmpId)
    returnValue=(filterdSched2.length==1)
  }
  return returnValue&&(dt>=scheduleOptions.dateInterval[0]&&dt<=scheduleOptions.dateInterval[1])
}

export function getSelectionKeysVisible(){
  const dataStore = useDataStore();
  const currentCC = dataStore.currentCC;
  const scheduleStore = useScheduleStore();
  const schedule=scheduleStore.schedule.list;
  const scheduleOptions = scheduleStore.scheduleOptions
  const filterdSched=schedule.filter(scheduleRowIsIncluded)
  const visibleKeys=[]
  for (let key of scheduleOptions.selection.keys()) {
    let v=scheduleOptions.selection.get(key)
    if(v.dt>=scheduleOptions.dateInterval[0]&&v.dt<=scheduleOptions.dateInterval[1]){
      if(filterdSched.filter(r => r.emp.id==v.emp.id).length==1){
        const ccId = dataStore.employeeCallcenters.getCCByDate(v.emp, v.dt);
        if (ccId === currentCC.id) {
          visibleKeys.push(key);
        }
      }
    }
  }
  return visibleKeys
}

export function selectionIsOk(){
  const currentUser = useDataStore().currentUser;
  if(!currentUser.isSupervisor){
    return true
  }else{
    const scheduleStore = useScheduleStore();
    const selection = scheduleStore.scheduleOptions.selection
    for (let key of selection.keys()) {
      let v=selection.get(key)
      if(v.dt>getLatestPublishedDate()){
        return false
      }
      }
    }
  return true
  }


/**
 * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule[]} schedule
 * @param {{dateInterval:Date[], selection:Map<string, {emp:import("@/model/dn-employee.js").Employee, dt:Date}>}} scheduleOptions
 */
export function getSelection(schedule, scheduleOptions) {
  const dataStore = useDataStore();
  const currentCC = dataStore.currentCC;
  const filteredSchedule = schedule.filter(scheduleRowIsIncluded)
  /** @type {Map<number, {empSchedule:import("@/model/dn-employee-schedule.js").EmployeeSchedule, dates:Date[]}>} */
  const byEmpId = new Map();

  for (let key of scheduleOptions.selection.keys()) {
    let v = scheduleOptions.selection.get(key)
    if (v.dt >= scheduleOptions.dateInterval[0] && v.dt <= scheduleOptions.dateInterval[1]) {
      const empSchedule = filteredSchedule.find(r => r.emp.id === v.emp.id);
      if (empSchedule !== undefined) {
        const ccId = dataStore.employeeCallcenters.getCCByDate(v.emp, v.dt);
        if (ccId === currentCC.id) {
          let empSelection = byEmpId.get(v.emp.id);
          if (empSelection === undefined) {
            empSelection = {empSchedule: empSchedule, dates:[]};
            byEmpId.set(v.emp.id, empSelection);
          }
          empSelection.dates.push(v.dt);
        }
      }
    }
  }

  const result = [];
  for (const empSelection of byEmpId.values()) {
    empSelection.dates.sort((a, b) => a.getTime() - b.getTime());
    result.push(empSelection)
  }

  return result;
}


/**
 * @param {Date} dt
 * @param {string[]} [changedDates]
 */
export function addToChangedDatedInclNext(dt, changedDates = []) {
  let dtKey = dn_uiHelper.getShortDate(dt);
  if (!changedDates.includes(dtKey)) { changedDates.push(dtKey); }
  dtKey = dn_uiHelper.getShortDate(dn_uiHelper.addDays(dt, 1));
  if (!changedDates.includes(dtKey)) { changedDates.push(dtKey); }
  return changedDates;
}

export function saveLocalScheduleSettings(){
  const scheduleStore = useScheduleStore();
  const opt = scheduleStore.scheduleOptions;
  const insp = scheduleStore.inspectorPanel;
  if (opt.dateInterval.length === 0)
    return;
  let localScheduleOptions ={
    //dateInterval:this.scheduleOptions.dateInterval,
    showServiceLevel:opt.showServiceLevel,
    showOccupancyChart:opt.showOccupancyChart,
    showWorkChart:opt.showWorkChart,
    showRequestTile:opt.showRequestTile,
    showEmployeeOccupancy:opt.showEmployeeOccupancy,
    autoSimulate:opt.autoSimulate,
    autoKeyFigures:opt.autoKeyFigures,
    numberOfSimIterations:opt.numberOfSimIterations,
    numberOfDaysInSchedule:getNumberOfDaysFromIntervalMinusOne(opt.dateInterval),
    workChartHeight:opt.workChartHeight,
    chartDisplayTarget:opt.chartDisplay.target,
    chartDisplayErlangC:opt.chartDisplay.erlangC,
    chartDisplaySmooth:opt.chartDisplay.smooth,
    chartDisplaychartInspector:opt.chartDisplay.chartInspector,
    chartDisplayFixedY:opt.chartDisplay.fixedY,
    chartDisplayFixedYvalue:opt.chartDisplay.fixedYvalue,
    chartDisplayServicelevelPercentForWarning:opt.chartDisplay.servicelevelPercentForWarning,
    showLog:opt.adherence.showLog,
    showWorkWarnings:opt.adherence.showWorkWarnings,
    showBreakWarnings:opt.adherence.showBreakWarnings,
    showAdherenceWarnings:opt.adherence.showAdherenceWarnings,

    meetingLastTasktypeId:opt.meetingSettings.meetingLastTasktypeId,
    meetingLastStartMinute:opt.meetingSettings.lastStartMinute,
    meetingLastStartMinuteMax:opt.meetingSettings.lastStartMinuteMax,
    meetingLastOnlyOnShift:opt.meetingSettings.onlyOnShift,
    meetingLastFlexStart:opt.meetingSettings.flexStart,
    meetingLastDurationMinute:opt.meetingSettings.lastDurationMinute,

    notifyOnScheduleChange:opt.notifications.onScheduleChange,

    showInRotation:opt.adherence.showInRotation,
    inspectorPanelTimespan:insp.timespan,
    inspectorHideTimeSpan:insp.hideTimeSpan,
    inspectorServiceLevel:insp.serviceLevel,
    inspectorTargetDev:insp.targetDev   
  }
  console.log('saved settings')
  dnLocalStorage.setLastScheduleOptions(localScheduleOptions)
  saveLocalRequestFilterOptions()
 }

 export function saveLocalEmployeeDisplaySettings(){
  const scheduleStore = useScheduleStore();
  const opt = scheduleStore.employeeDisplaySettings;

  let localEmployeeDisplaySettings ={
    workTimeWarning:opt.workTimeWarning,
    skillsWarning:opt.skillsWarning,
    shiftWarning:opt.shiftWarning,
    switchIdWarning:opt.switchIdWarning,
    hasMobileApp:opt.hasMobileApp,
    breakWarnings:opt.breakWarnings,
  }
  dnLocalStorage.setEmployeeDisplaySettings(localEmployeeDisplaySettings)
 }


 function saveLocalRequestFilterOptions(){
  const scheduleStore = useScheduleStore();
  const opt = scheduleStore.scheduleRequestSettings;
  if (scheduleStore.scheduleOptions.dateInterval.length === 0)
    return;
  let localRequestFilterOptions ={
    //dateInterval:this.scheduleOptions.dateInterval,
    showSick:opt.showSick,
    showVacation:opt.showVacation,
    showFree:opt.showFree,
    showWork:opt.showWork,
    showPending:opt.showPending,
    showHistoric:opt.showHistoric,
    showFuture:opt.showFuture,
    showBasedOnSelection:opt.showBasedOnSelection,
    showApproved:opt.showApproved,
    showRejected:opt.showRejected,
    tagId:opt.tagId
  }
  dnLocalStorage.setRequestFilterOptions(localRequestFilterOptions)
 }

 export const INFOTYPE = createInfoTypeEnum();
 export const DRAWKIND = createDrawTaskKindEnum();
 export const TEAMSCHEDULEKIND = createTeamScheduleKindEnum();



function createInfoTypeEnum() {
  const enumObject = {
    shift: 0,
    warningFromTaskBar: 1,
    warningFromRow: 2,
    publishedDayIndicator:3,
    erliestRequestIndicator: 4,
    skillsInfo:5,
    shiftFromTaskBar:6,
    unAvailabilityOtherCC:7
  };
  return Object.freeze(enumObject);
};

  function createDrawTaskKindEnum() {
    const enumObject = {
      unavailable: 0,
      otherCC: 1,
      none:2
    };

  return Object.freeze(enumObject);
};

function createTeamScheduleKindEnum() {
  const enumObject = {
    none: 0,
    barSchedule: 1,
    textSchedule:2
  };

return Object.freeze(enumObject);
};
export function taskChangeInfo(userId,ts){

    let userInitials =""
    let userName =""
    let colorId=1
    let timeText =""
    let users = useDataStore().users
    if(users){
    let user =users.find(item => item.id ===  userId)
    if(user){
      if(user.colorId&&user.colorId>0){colorId=user.colorId}
      userInitials=dn_uiHelper.suggestUserInitials(user)
      if(user.name&&user.name.length>0){userName=user.name}
      else{userName=user.email}
    }else{
      userInitials="SA"
      colorId=9
      userName="super admin"
    }
    timeText = getElapsedTimeString(ts)
  }

  return {colorId:colorId,timeText:timeText,userInitials:userInitials,userName:userName}

}


export function getWorkShiftWithDeleted(empTasks){
  let focusedDate=empTasks.focusedDate
  let workShiftWithDeleted = []
let focusedShift=null
if(empTasks&&empTasks.tasks){ 
  focusedShift=getShift(
    focusedDate,
     empTasks.tasks,
     empTasks.tasks.concat(empTasks.absences).concat(empTasks.payments).concat(empTasks.breaks),
     )
     workShiftWithDeleted =empTasks.absences.filter(isInShiftWithDeleted)
     .concat(empTasks.tasks.filter(isInShiftWithDeleted))
     .concat(empTasks.breaks.filter(isInShiftWithDeleted))
     .concat(empTasks.payments.filter(isInShiftWithDeleted))
}

  return workShiftWithDeleted

  function isInShiftWithDeleted(t) {
      let st_day=focusedDate
      let fi_day=dn_uiHelper.addDays(st_day,1)
      let st_shift=focusedShift.st
      let fi_shift=focusedShift.fi
      return (((t.st >= st_shift && t.fi <= fi_shift)||(t.st >= st_day && t.fi <= fi_day))&&t.tasktypeId);
    }
}


export function changeInfo(empTasks){
  let workShiftWithDeleted =getWorkShiftWithDeleted(empTasks)
  return changeInfoFromTaskList(workShiftWithDeleted)
}

  export function changeInfoFromTaskList(taskList){
    let maxDt = new Date(1970,1,1)
    let latestTask = null
    const dataStore=useDataStore()
    
  
    for(let i=0;i<taskList.length;i++){
      const task= taskList[i]
      let tt = task.tasktypeId == undefined ? undefined : dataStore.taskTypeMap.get(task.tasktypeId);
        if(tt&&task.updated&&task.updated>maxDt){
          maxDt=task.updated
          latestTask = task   
        } 
    }
  
    if(latestTask){
        return taskChangeInfo(latestTask.userId,latestTask.updated)
    }else{
      return {colorId:1,timeText:'',userInitials:'?',userName:''}
    }
    }

    export function   getPublishedScheduleTasksWithChanges(){
      const dataStore = useDataStore();
      const scheduleStore= useScheduleStore();
      const scheduleTasksInStore = scheduleStore.scheduleTasks.list;
      
      const returnList=[]
      for (let i = 0; i < scheduleTasksInStore.length; i++) {
        const t = scheduleTasksInStore[i]
        
        if (t.st <= addDays(getLatestPublishedDate(), 1)) {
          if ((t.isToDelete||t.hasChanges)) {
            let ttKind =  dataStore.taskTypeMap.get(t.tasktypeId).kind;
            let excludeTask = false
            if(ttKind==TASK_KIND.inRotation||ttKind==TASK_KIND.log){excludeTask=true}
            if(!excludeTask){returnList.push(t)}}
        }
      }
      return returnList
    }
  

 



