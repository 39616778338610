import { getStartOfDay, getShortDate as getShortDate2, getStartOfWeek } from '@/js/dn-helper.js';
import moment from "moment";
import { useDataStore } from "@/stores/dataStore.js";

/**
 * @param {string | number | Date} dt
 * @param {number} days
 */
export function addDays(dt, days) {
  return moment(dt).add(days,'days').toDate()
}

/**
 * @param {Date} st
 * @param {Date} fi
 */
export function getDurationInMinutes(st, fi) {
  return Math.round((fi.getTime() - st.getTime()) / 60000);
}

/**
* @param {Date} dt
*/
export function formatMMDDYYY(dt) {
return moment(dt).format('MM/DD/YYYY')
}

Date.prototype.yyyymmdd = function() {
  return getShortDate(this);
};

Date.prototype.mmdd = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();
  return [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
};

/**
 * @param {Date} dt
 */
export function getShortDate(dt) {
  return getShortDate2(dt);
}

/**
 * @param {string | number | Date} dt
 */
export function getStartOfWeekUI(dt) {
  const dataStore = useDataStore();
  return getStartOfWeek(dt, dataStore.generalEmpAppSettings.weekStartDay);
}

/**
 * @param {Date} dt
 */
export function getWeekdayNarrow(dt) {
  return dt.toLocaleDateString(useDataStore().language, { weekday: 'narrow' });
}

/**
 * @param {Date} dt
 */
 export function getWeekdayLong(dt) {
  return dt.toLocaleDateString(useDataStore().language, { weekday: 'long' });
}

export function getWeekDays(dt){
  let returnValue=[]
  for(let d=0;d<7;d++){
    returnValue.push(getWeekdayLong(addDays(dt, d)))
  }
  return returnValue
}

/**
 * @param {string | number | Date} startDay
 */
export function getWeekDaysWithInfo(startDay) {
  const days = [];
  for (let d = 0; d < 7; d++) {
    const dt = addDays(startDay, d);
    const day = dt.getDay();
    days.push({ day, name: getWeekdayLong(dt), isWeekend: day === 0 || day === 6 });
  }
  return days
}

export function GetToday() {
  var d = new Date();
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

export function ShortTime(dt,military=true, displaySeconds = false) {
return dt.toLocaleTimeString(useDataStore().language, { hour: 'numeric', minute: '2-digit' })
}

export function getDateFormat(){
  if(useDataStore().language=='en-US'){return 'dd/mm/yy'}else{return 'yy-mm-dd'}
}

// This script is released to the public domain and may be used, modified and
// distributed without restrictions. Attribution not necessary but appreciated.
// Source: https://weeknumber.net/how-to/javascript

// Returns the ISO week of the date.
Date.prototype.getWeekNumber = function() {
  let date = getStartOfDay(this);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
}

// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype.getWeekYear = function() {
  var date = new Date(this.getTime());
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  return date.getFullYear();
}

export  function isMobile() {
  if (
/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
 navigator.userAgent
)
) {
return true;
} else {
return false;
}
}

export function suggestUserInitials(user){
  let userInitials = ""
  if(user.initials&&user.initials!=undefined&&user.initials.length>0){userInitials=user.initials}
  else if(user.name&&user.name.length>0){userInitials=user.name.substring(0,2)}
  else{userInitials=user.email.substring(0,2)}
  return userInitials
}
