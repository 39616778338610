// @ts-check
export const COLORS = createColorEnum();

function createColorEnum() {
  /** @type {string[]} */
  const colorArray = [];
  const colors = {
    stroke1: 'stroke1',
    stroke2: 'stroke2',
    stroke3: 'stroke3',
    stroke4: 'stroke4',
    stroke5: 'stroke5',
    stroke6: 'stroke6',
    stroke7: 'stroke7',
    stroke8: 'stroke8',
    stroke9: 'stroke9',
    primary: 'primary',
    lightdanger: 'lightdanger',
    lightinfo: 'lightinfo',
    dark: 'dark',
    byId: byId,
    idBySchemecolor: idBySchemecolor
  };

  colorArray[1] = colors.stroke1;
  colorArray[2] = colors.stroke2;
  colorArray[3] = colors.stroke3;
  colorArray[4] = colors.stroke4;
  colorArray[5] = colors.stroke5;
  colorArray[6] = colors.stroke6;
  colorArray[7] = colors.primary;
  colorArray[8] = colors.stroke8;
  colorArray[9] = colors.dark;
  colorArray[10] = colors.lightdanger;
  colorArray[11] = colors.lightinfo;
  return Object.freeze(colors);
  function byId(id) {
    return colorArray[id];
  }
  /**
 * @param {string} name
 */
  function idBySchemecolor(name) {
    return colorArray.findIndex(x => x === name)
  }
}

export function getSelectableColors() {
  return [COLORS.stroke1, COLORS.stroke2, COLORS.stroke3, COLORS.stroke4, COLORS.stroke5, COLORS.stroke6, COLORS.stroke7, COLORS.stroke8, COLORS.lightinfo, COLORS.lightdanger, COLORS.primary, COLORS.dark];
}
