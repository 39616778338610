<template>
    <span>
      <div :class="[{ 'has-history':hasHistory },'avatar']" v-bind:style="{backgroundColor:getColor(colorId)}"> 
        
        <div class="avatar-text" >{{ initials}} </div>
    </div>
    </span>
  </template>

  
  <script>
  import { COLORS } from '@/js/dn-colors.js';
  import { getColorFromName } from "@/js/dn-variables";
  export default {
      props: {
        colorId: { type: Number, required: true },
        initials: { type: String, required: true },
        hasHistory: { type: Boolean, required: false },
    },

  data() {
      return {

      };
    },
    methods: {
   
    getColor(id){
        let col=getColorFromName(COLORS.byId(this.colorId))
       return col
    },
    
    },
    computed: {
     
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/css/variables.module.scss";
  .avatar {
    display: inline-block;
    border-radius: 25%;
    text-align:center;
    border: 2px solid $dark;
    min-height:10px;
    min-width:10px;

  }
  .avatar-text{
    font-size: 14px;
    font-weight: 600; // Semibold
    padding-left: 3px;
    padding-bottom: -1px;
    padding-top: 1px;
    padding-right: 3px;
    color:white;
    min-width: 24px;
   
  }
  .has-history{
    border: 3px solid rgb(2, 2, 2);

  }

  
  </style>