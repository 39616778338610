import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { Skill } from '@/model/dn-skill.js';

/**
* @typedef {{ switchId:number; id: number; tag1max:string|null; tag1min:string|null, tag2max:string|null; tag2min:string|null; tag3max:number|null; tag3min:number|null}} CallGroupConditionDto
*/

/**
* @typedef {import("@/model/dn-skill.js").SkillDto & { slpercent:number; slseconds:number; addWrap:number|null; prioSecondsInQueue?:number|null; conditions?:CallGroupConditionDto[]}} CallGroupDto
*/

/**
 * @param {number} id
 * @param {number} defaultSwitchId
 * @param {number|null} ccId
 */
export function createCallGroup(id, defaultSwitchId, ccId) {
  const callGroup = new CallGroup({ id, name: '', slpercent: 0, slseconds: 0, ccid: ccId, addWrap: null, conditions: [] });
  callGroup.setDefaultSwitchId(defaultSwitchId);
  return callGroup;
}

/**
 * @param {CallGroup[]} callgroups
 */
export async function mergeCallGroups(callgroups) {
  const itemsToDelete = []
  const promises = []
  for (let i = 0; i < callgroups.length; i++) {
    const c = callgroups[i];
    //New or changed callgroups
    if (c.isToDelete) {
      if (c.id >= 0)
        promises.push(fetchAndCheckJson(`callgroups/${c.id}`, 'DELETE'))
      itemsToDelete.push(c)
    } else if (c.hasChanges) {
      if (c.id < 0) {
        promises.push(fetchAndCheckJson(`callgroups`, 'POST', c.toApi()).then(result => c.confirmChanges(result)))
      }
      else {
        promises.push(fetchAndCheckJson(`callgroups/${c.id}`, 'PATCH', c.toApi()).then(result => c.confirmChanges(result)))
      }
    }
  }

  await Promise.all(promises);

  //Remove deleted callgrpoups from array
  for (let i = 0; i < itemsToDelete.length; i++) {
    const index = callgroups.indexOf(itemsToDelete[i]);
    callgroups.splice(index, 1);
  }
}

/**
* @param {boolean} [includeConditions]
* @param {number} [defaultSwitchId]
*/
export async function getCallGroups(includeConditions = false, defaultSwitchId = undefined) {
  const cond = includeConditions ? '?includeConditions=1' : '';

  /** @type {CallGroupDto[]} */
  const result = await fetchAndCheckJson(`callgroups${cond}`, 'GET');
  const callGroups = result.map(x => new CallGroup(x));
  if (defaultSwitchId) {
    for (const cg of callGroups) {
      cg.setDefaultSwitchId(defaultSwitchId);
    }
  }
  return callGroups;
}

export class CallGroup extends Skill {
  /**
   * @param {CallGroupDto} dto
   */
  constructor(dto) {
    super(dto);
    /** @private @type {number} */
    this._slPercent = dto.slpercent;
    /** @private @type {number} */
    this._slWithin = dto.slseconds;
    /** @private @type {number} */
    this._prioSecondsInQueue = dto.prioSecondsInQueue ? dto.prioSecondsInQueue : null;
    /** @private @type {number} */
    this._addWrap = dto.addWrap;

    /** @type {CallGroupCondition[]} */
    let conditions;
    if (dto.conditions)
      conditions = dto.conditions.map(c => new CallGroupCondition(c));
    else
      conditions = [];

    /** @private @type {CallGroupCondition[]} */
    this._conditions = conditions.sort((a, b) => (a.tag1min > b.tag1min) ? 1 : ((b.tag1min > a.tag1min) ? -1 : 0));
  }

  get addWrapS() {
    if (this._addWrap)
      return this._addWrap / 1000;
    return null;
  }

  set addWrapS(newValue) {
    if (newValue)
      this._addWrap = Math.round(newValue * 1000);
    else
      this._addWrap = null;
    this.markAsHasChanges();
  }

  get slPercent() {
    return this._slPercent
  }

  set slPercent(newValue) {
    this._slPercent = newValue
    this.markAsHasChanges();
  }

  get prioSecondsInQueue() {
    return this._prioSecondsInQueue ? this._prioSecondsInQueue : 0;
  }

  set prioSecondsInQueue(newValue) {
    const v = Number(newValue);
    if (Number.isNaN(v)) { return; }
    this._prioSecondsInQueue = Math.round(v);
    this.markAsHasChanges();
  }

  get slWithin() {
    return this._slWithin
  }

  set slWithin(newValue) {
    this._slWithin = newValue
    this.markAsHasChanges();
  }

  get conditions() {
    return this._conditions
  }

  set conditions(newValue) {
    this._conditions = newValue
    this.markAsHasChanges();
  }

  get hasChanges() {
    return super.hasChanges || this._conditions.some(c => c.hasChanges || c.id == -1 || c.isToDelete);
  }

  /**
   * @param {number} switchId
   */
  setDefaultSwitchId(switchId) {
    /** @private @type {number} */
    this._defaultSwitchId = switchId;
  }

  addCondition() {
    let switchId = this._defaultSwitchId;
    if (this.conditions.length > 0) {
      switchId = this.conditions[this.conditions.length - 1].switchId;
    }

    this.conditions.push(new CallGroupCondition({ id: -1, switchId, tag1min: null, tag1max: null, tag2min: null, tag2max: null, tag3min: null, tag3max: null }));
  }

  /**
   * @param {{ newConditionIds?: any; id: number; }} result
   */
  confirmChanges(result) {
    let i = 0;
    for (const cond of this.conditions) {
      if (!cond.isToDelete && cond.hasChanges && cond.id == -1) {
        cond.id = result.newConditionIds[i];
        i++;
      }
    }

    super.confirmChanges(result);

    this._conditions = this._conditions.filter(x => !x.isToDelete);
    for (const cond of this._conditions) {
      cond.confirmChanges();
    }
  }

  /**
   * @param {CallGroupCondition} condition
   */
  deleteCondition(condition) {
    condition.toDelete();
    if (condition.id === -1) {
      const index = this.conditions.findIndex(x => x.isToDelete && x.id === -1);
      this.conditions.splice(index, 1);
    }
  }

  isValid() {
    return this.conditions.every(x => x.isValid());
  }

  toDelete() {
    super.toDelete();
    this.conditions.length = 0;
  }
  toApi() {
    let conditionsToDelete = [];
    let conditionsToInsert = [];
    let conditionsToUpdate = [];
    for (const cond of this.conditions) {
      if (cond.isToDelete) {
        if (cond.id !== -1) conditionsToDelete.push(cond.id);
      } else if (cond.hasChanges) {
        const toSave = { switchId: cond.switchId, tag1min: cond.tag1min, tag1max: cond.tag1max, tag2min: cond.tag2min, tag2max: cond.tag2max, tag3min: cond.tag3min, tag3max: cond.tag3max };
        if (cond.id == -1) {
          conditionsToInsert.push(toSave);
        }
        else {
          toSave.id = cond.id;
          conditionsToUpdate.push(toSave);
        }
      }
    }

    /** @type {import("@/model/dn-skill.js").SkillDto & { slpercent:number; slseconds:number; addWrap:number|null; prioSecondsInQueue?:number|null; conditionsToInsert:any[]; conditionsToDelete?:number[]; conditionsToUpdate:any[]}} */
    // @ts-ignore
    const dto = super.toDto();
    dto.addWrap = this._addWrap;
    dto.prioSecondsInQueue = this._prioSecondsInQueue;
    dto.slpercent = this.slPercent;
    dto.slseconds = this.slWithin;
    dto.conditionsToInsert = conditionsToInsert;
    dto.conditionsToDelete = conditionsToDelete;
    dto.conditionsToUpdate = conditionsToUpdate;

    return dto;
  }

  /** @returns {CallGroupDto} */
  toDto() {
    /** @type {CallGroupDto} */ // @ts-ignore
    const dto = super.toDto();
    dto.addWrap = this._addWrap;
    dto.prioSecondsInQueue = this._prioSecondsInQueue;
    dto.slpercent = this.slPercent;
    dto.slseconds = this.slWithin;
    return dto;
  }
}

class CallGroupCondition {
  /**
   * @param {CallGroupConditionDto} dto
   */
  constructor(dto) {
    /** @private @type {number} */
    this._id = dto.id
    /** @private @type {number} */
    this._switchId = dto.switchId;
    /** @private @type {null|number|string} */
    this._tag1min = dto.tag1min
    /** @private @type {null|number|string} */
    this._tag1max = dto.tag1max
    /** @private @type {null|number|string} */
    this._tag2min = dto.tag2min
    /** @private @type {null|number|string} */
    this._tag2max = dto.tag2max
    /** @private @type {null|number|string} */
    this._tag3min = dto.tag3min
    /** @private @type {null|number|string} */
    this._tag3max = dto.tag3max
    /** @private @type {boolean} */
    this._hasChanges = false
    /** @private @type {boolean} */
    this._isToDelete = false
  }

  get id() {
    return this._id
  }
  set id(value) {
    this._id = value
  }

  get switchId() {
    return this._switchId
  }

  set switchId(newValue) {
    this.setValue('_switchId', newValue);
  }

  get tag1min() {
    return this._tag1min
  }

  set tag1min(newValue) {
    this.setValue('_tag1min', newValue);
  }

  get tag1max() {
    return this._tag1max
  }

  set tag1max(newValue) {
    this.setValue('_tag1max', newValue);
  }

  get tag2min() {
    return this._tag2min
  }

  set tag2min(newValue) {
    this.setValue('_tag2min', newValue);
  }

  get tag2max() {
    return this._tag2max
  }

  set tag2max(newValue) {
    this.setValue('_tag2max', newValue);
  }

  get tag3min() {
    return this._tag3min
  }

  set tag3min(newValue) {
    this.setValue('_tag3min', newValue);
  }

  get tag3max() {
    return this._tag3max
  }

  set tag3max(newValue) {
    this.setValue('_tag3max', newValue);
  }

  /**
   * @private
   * @param {string} prop
   * @param {null|number|string} value
   */
  setValue(prop, value) {
    if (value === '')
      value = null;

    if (this[prop] !== value) {
      this[prop] = value;
      this._hasChanges = true;
    }
  }

  get hasChanges() {
    return this._hasChanges
  }
  confirmChanges() {
    this._hasChanges = false
  }

  get isToDelete() {
    return this._isToDelete
  }
  toDelete() { this._isToDelete = true }

  getTagMax(prop) {
    if (prop === 'tag3min' || prop === 'tag3max')
      return 2147483647;
    return 9007199254740990;
  }

  isValid() {
    return this.hasValidValue('tag1min') && this.hasValidValue('tag1max') &&
      this.hasValidValue('tag2min') && this.hasValidValue('tag2max') &&
      this.hasValidValue('tag3min') && this.hasValidValue('tag3max');
  }

  /**
   * @private
   * @param {string} prop
   */
  hasValidValue(prop) {
    let value = this[prop];
    if (value === null)
      return true;
    if (typeof (value) === 'string') {
      value = parseInt(value);
    }
    if (value >= 0 && value <= this.getTagMax(prop)) {
      return true;
    }

    return false;
  }
}
